const MelbourneChoose = {
    heading: "Why Choose The University of Melbourne",
    items: [
        {
            title: "IN AUSTRALIA",
            count: "#1",
        },
        {
            title: "STUDENT CITY IN AUSTRALIA",
            count: "#1",
        },
        {
            title: "IN THE WORLD",
            count: "#14",
        },
        {
            title: "IN GRADUATE EMPLOYABILITY WORLDWIDE.",
            count: "#8",
        },
    ]
};
const CaliforniaChoose = {
    heading: "Why Choose The University of California",
    items: [
        {
            title: "Public university worldwide: U.S. News & World Report",
            count: "#1",
        },
        {
            title: "All-time top producer of Peace Corps volunteers",
            count: "#1",
        },
        {
            title: "Nobel Prizes held by alumni",
            count: "35",
        },
        {
            title: "Number of community organizations with Berkeley student involvement",
            count: "300+",
        },
    ]
};
const WisconsinChoose = {
    heading: "Why Choose The University of Wisconsin-Madison",
    items: [
        {
            title: "Best public universitY",
            count: "#12",
        },
        {
            title: "Peace corps volunteers",
            count: "#1",
        },
        {
            title: "Noble prize winners",
            count: "20",
        },

    ]
};
const YeshivaChoose = {
    heading: "Why Choose The Yeshiva University",
    items: [
        {
            title: "YU's medical school applicants were accepted in the last 3 years",
            count: "92%",
        },
        {
            title: "YEARS of educating industry leaders",
            count: "135",
        },
        {
            title: "MILLION in financial aid awarded annually",
            count: "$45",
        },
        {
            title: "NCAA athletic teams",
            count: "15",
        },
    ]
};
const UtahChoose = {
    heading: "Why Choose The Sothern Utah University",
    items: [
        {
            title: "REGIONAL UNIVERSITY IN UTAH",
            count: "#2",
        },
        {
            title: "2023-2024 COLLEGE OF DISTINCTION",
            count: "#1",
        },
        {
            title: "CAREER FOCUSED LEARNING",
            count: "#1",
        },
        {
            title: "BEST IN WEST",
            count: "#1",
        },
    ]
};
const ColoradoChoose = {
    heading: "Why Choose The Colorado State University",
    items: [
        {
            title: "FOUR-YEAR COLLEGE IN COLORADO",
            count: "#1",
        },
        {
            title: "Graduate Programmes",
            count: "100+",
        },
        {
            title: "Undergraduate Programmes",
            count: "300+",
        },
        {
            title: "TOP COLLEGES WITH THE BEST ACADEMICS",
            count: "21%",
        },
    ]
};

export { MelbourneChoose, CaliforniaChoose, WisconsinChoose, YeshivaChoose, UtahChoose, ColoradoChoose }