import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import Header from "src/Component/content-component/Header";

import MainHeader from "src/Component/content-component/MainHeader";
import img from "../../asset/TestPrepDashboard/profile.png";

const TestPrepHome = () => {
  const username = sessionStorage.getItem("username");
  return (
    <Box sx={{ margin: "0px 20px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <Box
          sx={{
            right: "0px",
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "500px",

            bgcolor: "transparent",
            borderRadius: "0px 0px 200px 200px",
            background: "linear-gradient(to bottom, #D9FEFF, #00959A)"
          }}
        >
          <Box sx={{ padding: "50px 0", textAlign: "center" }}>
            <Header content={"Welcome Back"} color={"#000"} fontSize={"30px"} />
            <MainHeader content={username} color={"#000"} fontSize={"30px"} />
            <Typography
              variant="body2"
              sx={{ textAlign: "center", padding: "40px 0" }}
            >
              Let’s start your preparation with <br />
              Upcoming Classes of IELTS
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "auto",
              mb: "30px",
              width: "240px",
              height: {
                xs: "300px"
              },
              borderRadius: "0px 0px 120px 120px",
              overflow: "hidden"
            }}
          >
            <img
              src={img}
              alt="profile_pic"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
            />
          </Box>
        </Box>
        <Box sx={{ padding: "10px", width: "100%" }}>
          <Typography
            variant="body1"
            sx={{ padding: "10px 10px", color: "#00999E" }}
          >
            Score & Certification
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 20px"
              }}
            >
              <Box
                sx={{
                  border: "2px solid #00999E",
                  borderRadius: "20px",
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #00999E",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ padding: "10px 10px", color: "#00999E" }}
                  >
                    Attendance Rate
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#00999E",
                      height: "30px",
                      width: "60px",
                      mr: "10px",
                      borderRadius: "20px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    20%
                  </Box>
                </Box>
                <Box sx={{ padding: "20px 10px" }}>
                  <ClassCard label="Online Class" marks={"0/6"} />
                  <ClassCard label="Offline Class" marks={"0/6"} />
                  <ClassCard label="Mentor Connects" marks={"0/6"} />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 20px"
              }}
            >
              <Box
                sx={{
                  border: "2px solid #00999E",
                  borderRadius: "20px",
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #00999E",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ padding: "10px 10px", color: "#00999E" }}
                  >
                    Submission Rate
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#00999E",
                      height: "30px",
                      width: "60px",
                      mr: "10px",
                      borderRadius: "20px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    20%
                  </Box>
                </Box>
                <Box sx={{ padding: "20px 10px" }}>
                  <ClassCard label="Assignment" marks={"0/6"} />
                  <ClassCard label="Practice" marks={"0/6"} />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 20px"
              }}
            >
              <Box
                sx={{
                  border: "2px solid #00999E",
                  borderRadius: "20px",
                  // height: "250px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #00999E",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ padding: "10px 10px", color: "#00999E" }}
                  >
                    Mock Test
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#00999E",
                      height: "30px",
                      width: "60px",
                      mr: "10px",
                      borderRadius: "20px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    20%
                  </Box>
                </Box>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Box
                      sx={{
                        border: "2px solid #00999E",
                        width: "160px",
                        height: "125px",
                        mb: "20px"
                      }}
                    >
                      <Box
                        sx={{
                          height: "40px",
                          background:
                            "linear-gradient(to bottom, #00C5CB, #007579)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white"
                        }}
                      >
                        Last Score
                      </Box>
                      <Box
                        sx={{
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "40px",
                            border: "2px solid #9C5050",
                            padding: "10px",
                            bgcolor: "white",
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#9C5050"
                          }}
                        >
                          384
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "2px solid #00999E",
                        width: "160px",
                        height: "125px"
                      }}
                    >
                      <Box
                        sx={{
                          height: "40px",
                          background:
                            "linear-gradient(to bottom, #00C5CB, #007579)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white"
                        }}
                      >
                        Target Score
                      </Box>
                      <Box
                        sx={{
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "40px",
                            border: "2px solid #000",
                            padding: "10px",
                            bgcolor: "#CCFDFF",
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#000"
                          }}
                        >
                          700
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box sx={{ padding: "20px 10px" }}>
                      <ClassCard
                        label="Reading"
                        color={"#CCFDFF"}
                        textcolor={"#000"}
                        marks={"70%"}
                      />
                      <ClassCard
                        label="Speaking"
                        color={"#CCFDFF"}
                        textcolor={"#000"}
                        marks={"60%"}
                      />
                      <ClassCard
                        label="writing"
                        color={"#CCFDFF"}
                        textcolor={"#000"}
                        marks={"70%"}
                      />
                      <ClassCard
                        label="Grammar"
                        color={"#CCFDFF"}
                        textcolor={"#000"}
                        marks={"70%"}
                      />
                      <ClassCard
                        label="Over All"
                        color={"#CCFDFF"}
                        textcolor={"#000"}
                        marks={"70%"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TestPrepHome;

const ClassCard = ({ label, color, textcolor, marks }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        background: color ?? "linear-gradient(to right, #0C7D81, #46F8FE)", // Adjust the gradient colors as needed
        borderRadius: "50px",
        padding: "",
        // width: "280px",
        height: "40px",
        marginBottom: "16px"
      }}
    >
      <Box
        sx={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          border: "2px solid #00999E",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "16px",
          color: "#00999E",
          backgroundColor: "#fff"
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "550" }}>
          {marks}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ color: textcolor ?? "#fff", pl: "30px", fontWeight: "500" }}
      >
        {label}
      </Typography>
    </Box>
  );
};
