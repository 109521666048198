import React from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import AcademicBackground from './AcademicBackground';
import WorkExperience from './WorkExperience';
import PermanentAndMailingAddress from './PermanentAndMailingAddress';


const DashboardEnrollmentForm = () => {
  return (
    <Grid item md={12} lg={12} xs={12} sx={{ paddingTop: "20px" }}>
      <Box sx={{ margin: "0 20px" }}>
        <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: "visible" }}>
          <CardContent>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px" },
                paddingBottom: "10px",
              }}
            >
              INFORMATION
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="First Name"
                  name="firstname"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="Last Name"
                  name="lastname"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="DOB"
                  name="dob"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <FormControl sx={{ paddingLeft: "10px" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <AcademicBackground />
            <WorkExperience />

            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px" },
                paddingBottom: "10px",
                paddingTop: "20px",
              }}
            >
              STUDENT DETAILS
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="Student EmailID"
                  name="studentemail"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="Mobile Number"
                  name="mobilenumber"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="Guardian Name"
                  name="guardianname"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6} sm={3} md={3}>
                <TextField
                  label="Guardian Mobile Number"
                  name="guardianmobile"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            <PermanentAndMailingAddress />
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default DashboardEnrollmentForm;
