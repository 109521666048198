
const MelbourneScholarships = [
    {
        "title": "International House Scholarships",
        "topColor": "#F59E0B",
        "listitem": [
            "A range of scholarships available to new and existing students",
            "Approx. 23",
            "Up to ₹6,900,000",
            "UG/PG"
        ]
    },
    {
        "title": "Medley Hall Scholarships",
        "topColor": "#10B981",
        "listitem": [
            "A range of bursaries available to new and existing students",
            "Approx. 20",
            "Up to ₹1,100,000",
            "UG/PG"
        ]
    },
    {
        "title": "Housing Bursary",
        "topColor": "#9B1DFF",
        "listitem": [
            "Enrolled in a course at the University of Melbourne, paying rent or board",
            "Approx. 400",
            "Up to ₹250,000",
            "UG/PG"
        ]
    }
];
const WisconsinScholarships = [
    {
        "title": "International Student Scholarship",
        "topColor": "#F59E0B",
        "listitem": [
            "Outstanding academic achievements, financial need",
            "Varies",
            "Up to ₹15,00,000 per year",
            "Undergraduate",
        ]
    },
    {
        "title": "King-Morgridge Scholars Program",
        "topColor": "#10B981",
        "listitem": [
            "High-achieving international students with leadership potential and financial need",
            "6-8",
            "Full cost of attendance",
            "Undergraduate",
        ]
    },
    {
        "title": "Graduate School Fellowships",
        "topColor": "#9B1DFF",
        "listitem": [
            "Merit-based for graduate students, including international students",
            "Varies",
            "Varies; typically includes tuition and stipend",
            "Postgraduate",
        ]
    },
    {
        "title": "Foreign Language and Area Studies (FLAS) Fellowships",
        "topColor": "#F59E0B",
        "listitem": [
            "Graduate students studying specific foreign languages and area studies",
            "Varies",
            "Tuition support and a stipend",
            "Postgraduate",
        ]
    },
    {
        "title": "Departmental Scholarships",
        "topColor": "#10B981",
        "listitem": [
            "Specific to academic departments, merit-based for international students",
            "Varies",
            "Varies; typically partial tuition coverage",
            "Both",
        ]
    },
];
const CaliforniaScholarships = [
    {
        "title": "Berkeley International Office Scholarships",
        "topColor": "#F59E0B",
        "listitem": [
            "International students with outstanding academic achievements and financial need",
            "partial tuition coverage",

        ]
    },
    {
        "title": "The Mastercard Foundation Scholars Program",
        "topColor": "#10B981",
        "listitem": [
            "Students from Sub-Saharan Africa demonstrating academic talent, leadership potential, and financial need",
            "Full cost of attendance, including tuition, housing, and living expenses",

        ]
    },
    {
        "title": "Regents' and Chancellor's Scholarship",
        "topColor": "#9B1DFF",
        "listitem": [
            "High-achieving students, including international students, demonstrating exceptional academic promise",
            "$2,500 per year and additional benefits such as priority registration, research funding, and faculty mentorship",

        ]
    },
    {
        "title": "Graduate Fellowships and Assistantships",
        "topColor": "#F59E0B",
        "listitem": [
            "Graduate students, including international students, based on academic merit and departmental needs",
            "Varies; can include tuition remission, stipends, and research or teaching assistantships",
        ]
    },
    {
        "title": "FLAS Fellowships (Foreign Language and Area Studies)",
        "topColor": "#10B981",
        "listitem": [
            "Graduate students studying specific foreign languages and area studies",
            "Tuition support and a stipend",
        ]
    },

];
const YeshivaScholarships = [
    {
        "title": "International Student Scholarship",
        "topColor": "#F59E0B",
        "listitem": [
            "Outstanding academic achievements, financial need",
            "Varies",
            "Up to ₹15,00,000 per year",
            "Undergraduate",
        ]
    },
    {
        "title": "King-Morgridge Scholars Program",
        "topColor": "#10B981",
        "listitem": [
            "High-achieving international students with leadership potential and financial need",
            "6-8",
            "Full cost of attendance",
            "Undergraduate",
        ]
    },
    {
        "title": "Graduate School Fellowships",
        "topColor": "#9B1DFF",
        "listitem": [
            "Merit-based for graduate students, including international students",
            "Varies",
            "Varies; typically includes tuition and stipend",
            "Postgraduate",
        ]
    },
    {
        "title": "Foreign Language and Area Studies (FLAS) Fellowships",
        "topColor": "#F59E0B",
        "listitem": [
            "Graduate students studying specific foreign languages and area studies",
            "Varies",
            "Tuition support and a stipend",
            "Postgraduate",
        ]
    },
    {
        "title": "Departmental Scholarships",
        "topColor": "#10B981",
        "listitem": [
            "Specific to academic departments, merit-based for international students",
            "Varies",
            "Varies; typically partial tuition coverage",
            "Both",
        ]
    },
];
const UtahScholarships = [
    {
        "title": "Transfer Four Semester Renewable Scholarship",
        "topColor": "#F59E0B",
        "listitem": [
            "$2,250",
            "Transfer GPA between 3.0 and 3.69",

        ]
    },
    {
        "title": "Western Undergraduate Exchange (WUE)",
        "topColor": "#10B981",
        "listitem": [
            "$5,568.50",
            "Transfer GPA between 2.5 and 3.69",

        ]
    },
    {
        "title": "Snow Transfer Resident Fellow's Scholarship",
        "topColor": "#9B1DFF",
        "listitem": [
            "$2,500",
            "Transfer GPA between 3.75 and 4.0",

        ]
    },
    {
        "title": "Snow Transfer Resident Founder's Scholarship",
        "topColor": "#F59E0B",
        "listitem": [
            "$2,000",
            "Transfer GPA between 3.5 and 3.74",

        ]
    },
    {
        "title": "Snow Transfer Resident Dean's Scholarship",
        "topColor": "#10B981",
        "listitem": [
            "$1,250",
            "Transfer GPA between 3.25 and 3.49",

        ]
    },
    {
        "title": "Snow Transfer Resident Centurium Scholarship",
        "topColor": "#9B1DFF",
        "listitem": [
            "$750",
            "Transfer GPA between 3.0 and 3.24",

        ]
    },
    {
        "title": "Snow Transfer Non-Resident Founder's Scholarship",
        "topColor": "#F59E0B",
        "listitem": [
            "$6,908",
            "Transfer GPA between 3.65 and 4.0",

        ]
    },
    {
        "title": "Snow Transfer Non-Resident Achievement (non-WUE) Scholarship",
        "topColor": "#10B981",
        "listitem": [
            "$2,250",
            "Transfer GPA between 3.0 and 3.64",

        ]
    },
    {
        "title": "Snow Transfer Non-Resident WUE Scholarship",
        "topColor": "#9B1DFF",
        "listitem": [
            "$5,568.50",
            "Transfer GPA between 2.0 and 3.64",

        ]
    },

];
const ColoradoScholarships = [
    {
        "title": "Green and Gold Scholarships",
        "topColor": "#F59E0B",
        "listitem": [
            "Colorado residents",
            "$2,500 - $4,000 per year",

        ]
    },
    {
        "title": "Alliance High School Partnership Award",
        "topColor": "#10B981",
        "listitem": [
            "Graduates of Alliance high schools, enrolled in a Colorado community/junior college",
            "$4,000 per year (up to 5 years)",

        ]
    },
    {
        "title": "Student Support Services Partnership Award",
        "topColor": "#9B1DFF",
        "listitem": [
            "Colorado residents transferring from a Colorado two-year/community college",
            "$2,500 per year (up to 5 years)",

        ]
    },
    {
        "title": "High School Summer Forum Participants",
        "topColor": "#F59E0B",
        "listitem": [
            "Participants in CSU Partnership Programs (BIF, NEF, LDZ)",
            "$5,000 per year (up to 2 years)",

        ]
    },
    {
        "title": "Western Undergraduate Exchange (WUE) Award",
        "topColor": "#10B981",
        "listitem": [
            "Residents of western U.S. states and select Pacific islands",
            "Significant tuition reduction",

        ]
    },

];

export { MelbourneScholarships, CaliforniaScholarships, WisconsinScholarships, YeshivaScholarships, UtahScholarships, ColoradoScholarships }