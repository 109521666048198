import { Box, Card } from '@mui/material'
import React, { useState } from 'react'


import { useNavigate } from 'react-router'
import { Icon } from '@iconify/react'

const TestCard = ({ data }) => {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };



    const [hoveredStep, setHoveredStep] = useState(null);




    return (
        <>
            <Box >

                <Card onClick={() =>
                    handleNavigation(data?.path)
                }
                    sx={{
                        width: "280px",
                        height: "200px",
                        borderTopWidth: "50%",
                        "&:hover": {
                            backgroundColor: "#00999E",
                            color: "white",
                            zIndex: 1,
                            "& .css-1rq26sm-MuiTypography-root": {
                                color: "black",
                            },
                        },
                        position: "relative",
                        zIndex: 1,
                        overflow: "visible",
                        cursor: "pointer",
                        display: "flex", justifyContent: "center", alignItems: "center"
                        // padding: 2
                    }}
                    onMouseEnter={() => setHoveredStep(1)}
                    onMouseLeave={() => setHoveredStep(null)}
                    className="pseudo-partial-border"
                >
                    <Box padding={2}
                        sx={{ width: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img
                            alt=""
                            src={data?.image}
                            width={"80%"}
                        />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0px 20px",
                        alignItems: "center"
                    }}>
                        {/* <Typography sx={{ fontWeight: 400, padding: 1, fontFamily: "Poppins" }}>
                            {data?.title}
                        </Typography> */}
                        <Icon icon="fluent:arrow-right-24-regular" fontSize={"32px"} /></Box>

                </Card>
            </Box>
        </>
    )
}

export default TestCard