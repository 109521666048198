import React from 'react'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, CardActionArea, Stack } from '@mui/material';

const ValueCard = ({ id, icon, title, about }) => {
  return (
    <div>
      <Stack
        sx={{
          margin: "auto",
        }}
      >

        <Box sx={{
          margin: "auto",
        }}>
          <CardActionArea
            sx={{
              width: { xs: "100%", sm: 320, md: 270, lg: 270 },
              height: { xs: "100%", sm: 320, md: 270, lg: 350 },
              transition: '#5cd2d6 0.3s ease',
              '&:hover': {
                backgroundColor: '#5cd2d6',
                borderRadius: '5px',
                '& .MuiIconButton-root': {
                  backgroundColor: '#fff',
                },
                '& .MuiTypography-root': {
                  color: 'white',
                },
              },
            }}
          >
            <IconButton sx={{
              borderRadius: '50%',
              height: '98px',
              width: '98px',
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              // margin: "auto",
              boxShadow: '0px 0px 10px rgba(139, 210, 160, 0.40)',
              margin: "20px auto auto auto",

            }}>
              <img
                src={icon}
                alt="heroImg1"
                loading="lazy"
                style={{
                  padding: '2px'
                }}
              />

            </IconButton>

            <CardContent>
              <Typography gutterBottom variant="h5" component="div"
                sx={{
                  color: "#00999E",
                  textAlign: "center",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "125.259%"
                }}>
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary"
                sx={{
                  textAlign: "center",
                  padding: "10px 0",
                  color: 'black',
                  fontSize: '16px',
                }}
              >
                {about}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Box>
      </Stack>
    </div>
  )
}

export default ValueCard