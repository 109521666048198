
import usa from "../../../asset/country/UsaInfo.svg";
import uk from "../../../asset/CountryBanner/uk.png"
import Canada from "../../../asset/CountryBanner/canada.png"
import Ireland from "../../../asset/CountryBanner/ireland.png"
import france from "../../../asset/CountryBanner/france.png"
import italy from "../../../asset/CountryBanner/italy.png"
import Singapore from "../../../asset/CountryBanner/singapore.png"
import dubai from "../../../asset/CountryBanner/dubai.png"
import australia from "../../../asset/CountryBanner/australia.png"
import germany from "../../../asset/CountryBanner/germany.png"



const USAInfo =
{
    title: "Study In USA",
    content: "As of the latest available data, Indian students constitute a significant portion of the international student population in the United States. According to the US Consulate, the total number of students from other countries enrolled in higher education institutions across the USA in 2024 was 1,057,188, and were on Optional Practical Training (OPT) in the academic year 2022/23.",
    image: usa,
};
const UKInfo =
{
    title: "Study In United Kingdom",
    content: "In the United Kingdom, Indian students contribute significantly to the diverse international student community. According to recent data from the Higher Education Statistics Agency (HESA), Indian students form a substantial portion of the student body in UK universities. The number of Indians granted study visas grew by 307% between 2019 and 2022, increasing from 34,261 to 139,539. India also displaced China as the most common nationality granted UK study visas, with the East Asian country recording 102,842 such nationals in 2022.",
    image: uk,
};
const IrelandInfo =
{
    title: "Study In Ireland",
    content: "Ireland has emerged as an attractive destination for Indian students seeking quality education abroad. According to the Irish Higher Education Authority (HEA), the number of Indian students enrolling in Irish universities and colleges has been steadily increasing in recent years.As of 2023 there were approximately 10,000 Indian students studying diverse subjects in institutions throughout Ireland.",
    image: Ireland,
};
const CanadaInfo =
{
    title: "Study In Canada",
    content: "Canada's esteemed education system and inclusive atmosphere attract a large number of international students, including a significant portion from India. In the academic year 2022-2023, Immigration, Refugees and Citizenship Canada (IRCC) reported over 183,310 Indian students enrolled in Canadian institutions",
    image: Canada,
};
const GermanyInfo =
{
    title: "Study In Germany",
    content: "Germany is a top choice for Indian students seeking higher education abroad due to its prestigious universities, affordable tuition, and vibrant academic scene. Recent data from the German Academic Exchange Service (DAAD) shows around 34,864 Indian students studying in Germany.",
    image: germany,
};
const AustraliaInfo =
{
    title: "Study In Australia",
    content: "Australia is a preferred destination for Indian students pursuing their academic aspirations abroad. One can discover  academic excellence, with top-ranked universities and a*renowned education system, Australia is a prime choice for Indian students. Over 100,000 Indians have already embarked on their educational journey in Australia.",
    image: australia,
};
const SingaporeInfo =
{
    title: "Study In Singapore",
    content: "Singapore shines as an education hub in Asia, drawing students globally, including India, with its diverse academic options and multicultural atmosphere. Latest data from Singapore's Ministry of Education indicates around 10,000 Indian students are enrolled in institutions across the nation.",
    image: Singapore,
};
const ItalyInfo =
{
    title: "Study In Italy",
    content: "With its rich cultural heritage and esteemed academic institutions, Italy is an attractive destination for Indian students pursuing higher education abroad. MIUR data shows a growing trend, with over 5,897 Indian students enrolled in Italian universities as of 2023.",
    image: italy,
};
const FranceInfo =
{
    title: "Study In France",
    content: "France, with its prestigious universities and vibrant culture, has long been a favored destination for Indian students pursuing higher education abroad. According to recent statistics from Campus France, the national agency for promoting higher education and international student mobility, there were approximately 10,003 Indian students enrolled  in French universities and institutions during the most recent academic year.",
    image: france,
};
const DubaiInfo =
{
    title: "Study In Dubai",
    content: "Dubai has become a vibrant educational center in the Middle East, drawing students worldwide, including India. With its modern infrastructure and diverse academic offerings, Dubai provides Indian students with exceptional opportunities for growth. According to the Knowledge and Human Development Authority (KHDA), over 21,900 Indian students were enrolled in Dubai's educational institutions in the latest reporting period.",
    image: dubai,
};





export { USAInfo, UKInfo, IrelandInfo, CanadaInfo, GermanyInfo, AustraliaInfo, SingaporeInfo, ItalyInfo, FranceInfo, DubaiInfo };