import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const DashboardOrder = () => {
  const [orderList, setOrderList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}order/list-order-student?page=1&limit=10`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setOrderList(response.data.data.listOrder);
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    fetchData();
  }, []);


  const handleGetbyID = (id) => {
    navigate(`/dashboard/order/order-details/${id}`);
  };

  return (
    <Box sx={{ padding: '40px' }}>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          mb: '20px',
          borderRadius: '10px',
          ml: '30px',
          mr: '30px',
        }}
      >
        <Typography sx={{ fontSize: '24px', fontWeight: '550', color: '#000000' }}>
          Order History
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {orderList.map((item) => (
          <Grid
            item
            key={item._id}
            md={6}
            lg={4}
            sm={6}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  width: { xs: '300px', md: '330px' },
                  border: '1px solid #00999E',
                  borderRadius: '10px',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 20px 0px',
                }}
              >
                <Grid container sx={{ padding: '30px 10px' }}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: 'grey', fontSize: '14px', lineHeight: 2 }}>
                      Order Id
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '14px', lineHeight: 2, fontWeight: '500' }}>
                      {item.orderId}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: 'grey', fontSize: '14px', lineHeight: 2 }}>
                      Date of Purchase
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '14px', lineHeight: 2, fontWeight: '500' }}>
                      {moment(item.dateOfPurchase).format('DD MMM YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: 'grey', fontSize: '14px', lineHeight: 2 }}>
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '14px', lineHeight: 2, fontWeight: '500' }}>
                      ₹ {item.amount}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: 'grey', fontSize: '14px', lineHeight: 2 }}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        padding: '5px 10px',
                        backgroundColor:
                          item.paymentStatus === 'pending'
                            ? '#fff1d6'
                            : item.paymentStatus === 'paid'
                              ? '#dff3ea'
                              : '#f9d6d6',
                        borderRadius: '8px',
                        width:
                          item.paymentStatus === 'pending'
                            ? '80px'
                            : item.paymentStatus === 'paid'
                              ? '60px'
                              : '70px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: '500',
                          textTransform: "capitalize",
                          color:
                            item.paymentStatus === 'pending'
                              ? '#dbb06c'
                              : item.paymentStatus === 'paid'
                                ? '#499a87'
                                : '#f97777',
                        }}
                      >
                        {item.paymentStatus}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ pb: '20px', pl: '10px', pr: '10px' }}>
                  <LoadingButton
                    // onClick={() => {
                    //   navigate('/dashboard/order/order-details');
                    // }}
                    onClick={() => handleGetbyID(item._id)}
                    sx={{
                      color: '#ffffff',
                      backgroundColor: '#00999E',
                      fontSize: '18px',
                      padding: '10px 30px',
                      fontWeight: '550',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                      width: '100%',
                      height: '40px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#00999E',
                        color: '#ffffff',
                      },
                      '& .MuiLoadingButton-loadingIndicator': {
                        color: '#ffffff',
                      },
                    }}
                  >
                    View Details
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardOrder;
