import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import WorldMap from 'src/Component/CountryComponent/WorldMap'
import Header from 'src/Component/content-component/Header'

const StudyAbroadCountry = () => {
    return (
        <> <Box sx={{ backgroundColor: "#ffffff", padding: "0px 30px", }}>
            <Container>

                <Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
                    <Header
                        color="#00999e"
                        textAlign="center"
                        content="Pick Your Destination"
                    />
                    {/* <Typography
                        variant="h5"
                        sx={{
                            color: "#00999e",
                            fontSize: {
                                xs: "22.54px",
                                sm: "30.54px",
                                md: "36px",
                                lg: "36px",
                            },
                            fontWeight: "600",
                            wordWrap: "break-word",
                        }}
                        mt={3}
                    >
                        ~Pick Your Destination~
                    </Typography> */}
                </Box>
                <Box sx={{ backgroundColor: "#ffffff", padding: "10px", margin: "10px", border: "2px solid #00999E", borderRadius: "10px", boxShadow: "2px 2px 2px rgba(0,0,0,0.4)" }}>

                    <WorldMap />


                </Box>
            </Container>
        </Box>
        </>
    )
}

export default StudyAbroadCountry
