import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router';
import test from "../../asset/countryIcon.svg"


const CountryRouteContainer = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        window.scrollTo(0, 0);
        navigate(-1);
    };
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    // display: "flex",
                    // justifyContent: "center",
                    cursor: "pointer",
                    // alignItems: "center",
                    // pl: { md: "00px", xs: "20px", sm: "30px" },
                    pt: { md: "20px", xs: "10px", sm: "20px" },
                    pb: { md: "20px", xs: "10px", sm: "20px" },
                }}
            >
                <Container >
                    <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} onClick={handleNavigation}>
                        <img
                            src={test}
                            alt=""
                            onClick={handleNavigation}
                            height={"45px"} width={"45px"}
                        />
                        <Typography sx={{
                            varient: "h4",
                            fontSize: "16px",
                            fontWeight: "400",
                            pl: "20px",
                            '&:hover': {
                                color: "#00999E",
                            }

                        }}>
                            Country Page
                        </Typography>
                    </Box>

                </Container>
            </Box>
        </>
    );
}

export default CountryRouteContainer
