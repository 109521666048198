import React from "react";

// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";

// import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";

import Slider from "react-slick";
import FlipCard from "src/Component/card-components/FlipCard";

export default function Popular_card({ data }) {
  var settings = {
    dots: false,
    infinite: true,
    arrow: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 3000,
          autoplaySpeed: 2000
        }
      }
    ]
  };

  return (
    <Container
      sx={{
        // padding: {
        //   xs: "0px 20px",
        //   md: "10px 40px",
        //   xl: "0px 80px"
        // }
      }}
    >
      <Slider {...settings}>
        {data?.map((item, index) => {
          return (
            <Box sx={{ margin: "5px 0" }}>
              <FlipCard data={item} fontSize={"14px"} titlefontsize={"17px"} liststyle={"disc"} listalign={"start"} />

              {/* <Card
                sx={{
                  maxWidth: 300,
                  height: 380,
                  padding: { xl: "10px", md: "5px", xs: "2px" },
                  boxShadow: "0 2px 2px rgba(0,0,0,0.6)"
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <img src={item?.image} alt="logo" />
                  </Box>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        color: "#00999E",
                        fontFamily: "poppins",
                        fontSize: "18px",
                        fontWeight: "600",
                        textAlign: "center"
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.listitem.map((Item) => (
                        <ul>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 20px"
                            }}
                          >
                            <li
                              style={{
                                listStyleType: "none",
                                fontFamily: "poppins",
                                fontSize: "12px",
                                color: "black",
                                fontWeight: "400",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <CheckIcon
                                style={{
                                  color: "#00999E",
                                  fontSize: "22px",
                                  paddingRight: "5px"
                                }}
                              />
                              {Item}
                            </li>
                          </Box>
                        </ul>
                      ))}
                    </Typography>
                  </CardContent>
                </Box>
              </Card> */}
            </Box>
          );
        })}
      </Slider>
    </Container>
  );
}
