import aus1 from "../asset/mbbsCollege/australia/aus1.png";
import aus2 from "../asset/mbbsCollege/australia/aus2.png";
import aus3 from "../asset/mbbsCollege/australia/aus3.png";
import aus4 from "../asset/mbbsCollege/australia/aus4.png";
import aus5 from "../asset/mbbsCollege/australia/aus5.png";
import aus6 from "../asset/mbbsCollege/australia/aus6.png";
import aus7 from "../asset/mbbsCollege/australia/aus7.png";
import aus8 from "../asset/mbbsCollege/australia/aus8.png";
import aus9 from "../asset/mbbsCollege/australia/aus9.png";
import aus10 from "../asset/mbbsCollege/australia/aus10.png";
import rus1 from "../asset/mbbsCollege/russia/rus1.png";
import rus2 from "../asset/mbbsCollege/russia/rus2.png";
import rus3 from "../asset/mbbsCollege/russia/rus3.png";
import rus4 from "../asset/mbbsCollege/russia/rus4.png";
import rus5 from "../asset/mbbsCollege/russia/rus5.png";
import rus6 from "../asset/mbbsCollege/russia/rus6.png";
import rus7 from "../asset/mbbsCollege/russia/rus7.png";
import rus8 from "../asset/mbbsCollege/russia/rus8.png";
import rus9 from "../asset/mbbsCollege/russia/rus9.png";
import rus10 from "../asset/mbbsCollege/russia/rus10.png";
import rus11 from "../asset/mbbsCollege/russia/rus11.png";
import n1 from "../asset/mbbsCollege/nepal/n1.png";
import n2 from "../asset/mbbsCollege/nepal/n2.png";
import n3 from "../asset/mbbsCollege/nepal/n3.png";
import n4 from "../asset/mbbsCollege/nepal/n4.png";
import n5 from "../asset/mbbsCollege/nepal/n5.png";
import n6 from "../asset/mbbsCollege/nepal/n6.png";
import n7 from "../asset/mbbsCollege/nepal/n7.png";
import n8 from "../asset/mbbsCollege/nepal/n8.png";
import n9 from "../asset/mbbsCollege/nepal/n9.png";
import n10 from "../asset/mbbsCollege/nepal/n10.png";
import b1 from "../asset/mbbsCollege/bangladesh/b1.png";
import b2 from "../asset/mbbsCollege/bangladesh/b2.png";
import b3 from "../asset/mbbsCollege/bangladesh/b3.png";
import b4 from "../asset/mbbsCollege/bangladesh/b4.png";
import b5 from "../asset/mbbsCollege/bangladesh/b5.png";
import b6 from "../asset/mbbsCollege/bangladesh/b6.png";
import b7 from "../asset/mbbsCollege/bangladesh/b7.png";
import b8 from "../asset/mbbsCollege/bangladesh/b8.png";
import b9 from "../asset/mbbsCollege/bangladesh/b9.png";
import b10 from "../asset/mbbsCollege/bangladesh/b10.png";
import can1 from "../asset/mbbsCollege/canada/can1.png";
import can2 from "../asset/mbbsCollege/canada/can2.png";
import can3 from "../asset/mbbsCollege/canada/can3.png";
import can4 from "../asset/mbbsCollege/canada/can4.png";
import can5 from "../asset/mbbsCollege/canada/can5.png";
import can6 from "../asset/mbbsCollege/canada/can6.png";
import can7 from "../asset/mbbsCollege/canada/can7.png";
import can8 from "../asset/mbbsCollege/canada/can8.png";
import can9 from "../asset/mbbsCollege/canada/can9.png";
import can10 from "../asset/mbbsCollege/canada/can10.png";
import ger1 from "../asset/mbbsCollege/germany/ger1.png";
import ger2 from "../asset/mbbsCollege/germany/ger2.png";
import ger3 from "../asset/mbbsCollege/germany/ger3.png";
import ger4 from "../asset/mbbsCollege/germany/ger4.png";
import ger5 from "../asset/mbbsCollege/germany/ger5.png";
import ger6 from "../asset/mbbsCollege/germany/ger6.png";
import ger7 from "../asset/mbbsCollege/germany/ger7.png";
import ger8 from "../asset/mbbsCollege/germany/ger8.png";
import ger9 from "../asset/mbbsCollege/germany/ger9.png";
import ger10 from "../asset/mbbsCollege/germany/ger10.png";
import phi1 from "../asset/mbbsCollege/philippines/phi1.png";
import phi2 from "../asset/mbbsCollege/philippines/phi2.png";
import phi3 from "../asset/mbbsCollege/philippines/phi3.png";
import phi4 from "../asset/mbbsCollege/philippines/phi4.png";
import phi5 from "../asset/mbbsCollege/philippines/phi5.png";
import phi6 from "../asset/mbbsCollege/philippines/phi6.png";
import phi7 from "../asset/mbbsCollege/philippines/phi7.png";
import phi8 from "../asset/mbbsCollege/philippines/phi8.png";
import phi9 from "../asset/mbbsCollege/philippines/phi9.png";
import phi10 from "../asset/mbbsCollege/philippines/phi10.png";
import us1 from "../asset/mbbsCollege/usa/us1.png";
import us2 from "../asset/mbbsCollege/usa/us2.png";
import us3 from "../asset/mbbsCollege/usa/us3.png";
import us4 from "../asset/mbbsCollege/usa/us4.png";
import us5 from "../asset/mbbsCollege/usa/us5.png";
import us6 from "../asset/mbbsCollege/usa/us6.png";
import us7 from "../asset/mbbsCollege/usa/us7.png";
import us8 from "../asset/mbbsCollege/usa/us8.png";
import us9 from "../asset/mbbsCollege/usa/us9.png";
import us10 from "../asset/mbbsCollege/usa/us10.png";
import uk1 from "../asset/mbbsCollege/uk/uk1.png";
import uk2 from "../asset/mbbsCollege/uk/uk2.png";
import uk3 from "../asset/mbbsCollege/uk/uk3.png";
import uk4 from "../asset/mbbsCollege/uk/uk4.png";
import uk5 from "../asset/mbbsCollege/uk/uk5.png";
import uk6 from "../asset/mbbsCollege/uk/uk6.png";
import uk7 from "../asset/mbbsCollege/uk/uk7.png";
import uk8 from "../asset/mbbsCollege/uk/uk8.png";
import uk9 from "../asset/mbbsCollege/uk/uk9.png";
import uk10 from "../asset/mbbsCollege/uk/uk10.png";
import kz1 from "../asset/mbbsCollege/kazakhstan/kz1.png";
import kz2 from "../asset/mbbsCollege/kazakhstan/kz2.png";
import kz3 from "../asset/mbbsCollege/kazakhstan/kz3.png";
import kz4 from "../asset/mbbsCollege/kazakhstan/kz4.png";
import kz5 from "../asset/mbbsCollege/kazakhstan/kz5.png";
import kz6 from "../asset/mbbsCollege/kazakhstan/kz6.png";
import kz7 from "../asset/mbbsCollege/kazakhstan/kz7.png";
import kz8 from "../asset/mbbsCollege/kazakhstan/kz8.png";
import kz9 from "../asset/mbbsCollege/kazakhstan/kz9.png";
import kz10 from "../asset/mbbsCollege/kazakhstan/kz10.png";
import ky1 from "../asset/mbbsCollege/kyrgyzstan/ky1.png";
import ky2 from "../asset/mbbsCollege/kyrgyzstan/ky2.png";
import ky3 from "../asset/mbbsCollege/kyrgyzstan/ky3.png";
import ky4 from "../asset/mbbsCollege/kyrgyzstan/ky4.png";
import ky5 from "../asset/mbbsCollege/kyrgyzstan/ky5.png";
import ky6 from "../asset/mbbsCollege/kyrgyzstan/ky6.png";
import ky7 from "../asset/mbbsCollege/kyrgyzstan/ky7.png";
import ky8 from "../asset/mbbsCollege/kyrgyzstan/ky8.png";
import ky9 from "../asset/mbbsCollege/kyrgyzstan/ky9.png";
import ky10 from "../asset/mbbsCollege/kyrgyzstan/ky10.png";
import uz1 from "../asset/mbbsCollege/uzbekistan/uz1.png";
import uz2 from "../asset/mbbsCollege/uzbekistan/uz2.png";
import uz3 from "../asset/mbbsCollege/uzbekistan/uz3.png";
import uz4 from "../asset/mbbsCollege/uzbekistan/uz4.png";
import uz5 from "../asset/mbbsCollege/uzbekistan/uz5.png";
import uz6 from "../asset/mbbsCollege/uzbekistan/uz6.png";
import uz7 from "../asset/mbbsCollege/uzbekistan/uz7.png";
import uz8 from "../asset/mbbsCollege/uzbekistan/uz8.png";
import uz9 from "../asset/mbbsCollege/uzbekistan/uz9.png";
import uz10 from "../asset/mbbsCollege/uzbekistan/uz10.png";

const RussiaMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Russia",
  content: [
    {
      title: "KSMU",

      items: [
        "Kazan State Medical University",
        "Location:- Kazan",
        "Annual Fee:- ₹3,00,000 - ₹5,00,000",
      ],
      Image: rus1,
    },
    {
      title: "RUDN University",

      items: [
        "Peoples' Friendship University of Russia (RUDN University) ",
        "Location:- Moscow",
        "Annual Fee:- ₹3,50,000 - ₹6,00,000",
      ],
      Image: rus2,
    },
    {
      title: "RNRMU",

      items: [
        "Russian National Research Medical University (RNRMU or RSMU)",
        "Location:- Moscow",
        "Annual Fee:- ₹4,00,000 - ₹6,50,000",
      ],
      Image: rus3,
    },
    {
      title: "MSMU",

      items: [
        "Moscow State Medical University",
        "Location:- Moscow",
        "Annual Fee:- ₹3,50,000 - ₹6,00,000",
      ],
      Image: rus4,
    },
    {
      title: "FMSMU",

      items: [
        "First Moscow State Medical University (Sechenov University)",
        "Location:- Moscow",
        "Annual Fee:- ₹3,50,000 - ₹6,00,000",
      ],
      Image: rus5,
    },
    {
      title: "NSMU",

      items: [
        "Northern State Medical University",
        "Location:- Arkhangelsk",
        "Annual Fee:- ₹3,00,000 - ₹5,00,000",
      ],
      Image: rus6,
    },
    {
      title: "TSMU",

      items: [
        "Tver State Medical University, Tver",
        "Location:- Tver",
        "Annual Fee:- ₹2,50,000 - ₹4,50,000",
      ],
      Image: rus7,
    },
    {
      title: "SPSPMU",

      items: [
        "Saint Petersburg State Pediatric Medical University (SPSPMU)",
        "Location:- St. Petersburg",
        "Annual Fee:- ₹3,00,000 - ₹5,00,000",
      ],
      Image: rus8,
    },
    {
      title: "BSMU",

      items: [
        "Bashkir State Medical University",
        "Location:- Ufa",
        "Annual Fee:- ₹2,50,000 - ₹4,50,000",
      ],
      Image: rus9,
    },
    {
      title: "ISMU",

      items: [
        "Irkutsk State Medical University",
        "Location:- Irkutsk",
        "Annual Fee:- ₹2,50,000 - ₹4,50,000",
      ],
      Image: rus10,
    },
    // {
    //     title: "IKBFU",

    //     items: [

    //         "IMMANUEL KANT BALTIC FEDERAL UNIVERSITY, KALININGRAD",
    //         "258532-350000"
    //     ],
    //     Image: rus11,
    // },
  ],
};
const BangladeshMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Bangladesh",
  content: [
    {
      title: "DMC",

      items: [
        "Dhaka Medical College",
        "Location:- Dhaka",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b1,
    },
    {
      title: "SSMC",

      items: [
        "Sir Salimullah Medical College",
        "Location:- Dhaka",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b2,
    },
    {
      title: "SSMC",

      items: [
        "Shaheed Suhrawardy Medical College",
        "Location:- Dhaka",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b3,
    },
    {
      title: "CMC",

      items: [
        "Chittagong Medical College",
        "Location:- Chittagong",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b4,
    },
    {
      title: "MMC",

      items: [
        "Mymensingh Medical College",
        "Location:- Mymensingh",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b5,
    },
    {
      title: "RMC",

      items: [
        "Rajshahi Medical College",
        "Location:- Rajshahi",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b6,
    },
    {
      title: "CMC",

      items: [
        "Comilla Medical College",
        "Location:- Comilla",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b7,
    },
    {
      title: "KMC",

      items: [
        "Khulna Medical College",
        "Location:- Khulna",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b8,
    },
    {
      title: "RMC",

      items: [
        "Rangpur Medical College",
        "Location:- Rangpur",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b9,
    },
    {
      title: "SMOMC",

      items: [
        "Sylhet MAG Osmani Medical College",
        "Location:- Sylhet",
        "Annual Fee:- ₹5,52,000",
      ],
      Image: b10,
    },
  ],
};
const CanadaMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Canada",
  content: [
    {
      title: "UOT",

      items: [
        "University of Toronto,Toronto",
        "Location:- Ontario",
        "Annual Fee:- ₹27,50,000 - ₹44,00,000",
      ],
      Image: can1,
    },
    {
      title: "UBC",

      items: [
        "University of British Columbia (UBC),Vancouver",
        "Location:- British Columbia",
        "Annual Fee:- ₹16,50,000 - ₹33,00,00",
      ],
      Image: can2,
    },
    {
      title: "MUM",

      items: [
        "McGill University,Montreal",
        "Location:- Quebec",
        "Annual Fee:- ₹16,50,000 - ₹33,00,000",
      ],
      Image: can3,
    },
    {
      title: "UOA",

      items: [
        "University of Alberta",
        "Location:- Edmonton",
        "Annual Fee:- ₹11,00,000 - ₹22,00,000",
      ],
      Image: can4,
    },
    {
      title: "MU",

      items: [
        "McMaster University,Hamilton",
        "Location:- Ontario",
        "Annual Fee:- ₹16,50,000 - ₹27,50,000",
      ],
      Image: can5,
    },
    {
      title: "UOO",

      items: [
        "University of Ottawa,Ottawa",
        "Location:- Ontario",
        "Annual Fee:- ₹11,00,000 - ₹22,00,000",
      ],
      Image: can6,
    },
    {
      title: "UOC",

      items: [
        "University of Calgary,Calgary",
        "Location:- Alberta",
        "Annual Fee:- ₹11,00,000 - ₹22,00,000",
      ],
      Image: can7,
    },
    {
      title: "WU",

      items: [
        "Western University,London",
        "Location:- Ontario",
        "Annual Fee:- ₹11,00,000 - ₹22,00,000",
      ],
      Image: can8,
    },
    {
      title: "QU",

      items: [
        "Queen's University,Kingston",
        "Location:- Ontario",
        "Annual Fee:- ₹16,50,000 - ₹27,50,000",
      ],
      Image: can9,
    },
    {
      title: "DU",

      items: [
        "Dalhousie University,Halifax",
        "Location:- Nova Scotia",
        "Annual Fee:- ₹11,00,000 - ₹22,00,000",
      ],
      Image: can10,
    },
  ],
};
const GermanyMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Germany",
  content: [
    {
      title: "CUB",

      items: [
        "Charité - Universitätsmedizin Berlin",
        "Location:- Berlin",
        "Annual Fee:- ₹17,40,000 - ₹26,10,000",
      ],
      Image: ger1,
    },
    {
      title: "RKUH",

      items: [
        "Ruprecht-Karls-Universität Heidelberg (Heidelberg University)",
        "Location:- Heidelberg",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger2,
    },
    {
      title: "LMUM",

      items: [
        "Ludwig-Maximilians-Universität München (LMU Munich)",
        "Location:- Munich",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger3,
    },
    {
      title: "EKUT",

      items: [
        "Eberhard Karls Universität Tübingen (University of Tübingen),",
        "Location:- Tübingen",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger4,
    },
    {
      title: "FUOB",

      items: [
        "Freie Universität Berlin (Free University of Berlin)",
        "Location:- Berlin",
        "Annual Fee:- ₹17,40,000 - ₹26,10,000",
      ],
      Image: ger5,
    },
    {
      title: "UOB",

      items: [
        "Universität Bonn (University of Bonn)",
        "Location:- Bonn",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger6,
    },
    {
      title: "TUOB",

      items: [
        "Technische Universität München (Technical University of Munich)",
        "Location:- Munich",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger7,
    },
    {
      title: "UOF",

      items: [
        "Universität Freiburg (University of Freiburg)",
        "Location:- Freiburg",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger8,
    },
    {
      title: "GUF",

      items: [
        "Universität Frankfurt am Main (Goethe University Frankfurt)",
        "Location:- Frankfurt",
        "Annual Fee:- ₹17,40,000 - ₹26,10,000",
      ],
      Image: ger9,
    },
    {
      title: "UOL",

      items: [
        "Universität Leipzig (University of Leipzig)",
        "Location:- Leipzig",
        "Annual Fee:- ₹8,70,000 - ₹17,40,000",
      ],
      Image: ger10,
    },
  ],
};
const PhilippinesMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Philippines",
  content: [
    {
      title: "UOST",

      items: ["University of Santo Tomas,Manila", "₹2,94,000 - ₹4,41,000"],
      Image: phi1,
    },
    {
      title: "UOPM",

      items: [
        "University of the Philippines Manila (UP Manila)",
        "Location:- Manila",
        "Annual Fee:- ₹2,21,000 - ₹3,68,500",
      ],
      Image: phi2,
    },
    {
      title: "CIOM",

      items: [
        "Cebu Institute of Medicine",
        "Location:- Cebu City",
        "Annual Fee:- ₹2,21,000 - ₹2,94,000",
      ],
      Image: phi3,
    },
    {
      title: "PLM",

      items: [
        "Pamantasan ng Lungsod ng Maynila (PLM)",
        "Location:- Manila",
        "Annual Fee:- ₹1,17,600 - ₹1,76,400",
      ],
      Image: phi4,
    },
    {
      title: "MCUCOM",

      items: [
        "Manila Central University College of Medicine",
        "Location:- Manila",
        "Annual Fee:- ₹1,76,400 - ₹2,64,600",
      ],
      Image: phi5,
    },
    {
      title: "DSMHSI",

      items: [
        "De La Salle Medical and Health Sciences Institute",
        "Location:- Dasmarinas",
        "Annual Fee:- ₹2,21,000 - ₹2,94,000",
      ],
      Image: phi6,
    },
    {
      title: "ASMPH",

      items: [
        "Ateneo School of Medicine and Public Health",
        "Location:- Pasig City",
        "Annual Fee:- ₹2,94,000 - ₹4,41,000",
      ],
      Image: phi7,
    },
    {
      title: "FEU",

      items: [
        "Far Eastern University (FEU) Institute of Medicine",
        "Location:- Manila",
        "Annual Fee:- ₹2,94,000 - ₹4,41,000",
      ],
      Image: phi8,
    },
    {
      title: "SLUCOM",

      items: [
        "Saint Louis University College of Medicine",
        "Location:- Baguio City",
        "Annual Fee:- ₹1,76,400 - ₹2,64,600",
      ],
      Image: phi9,
    },
    {
      title: "UPHRJFSM",

      items: [
        "University of Perpetual Help Rizal Jonelta Foundation School of Medicine",
        "Location:- Calamba City",
        "Annual Fee:- ₹1,76,400 - ₹2,64,600",
      ],
      Image: phi10,
    },
  ],
};
const AustraliaMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Australia",
  content: [
    {
      title: "UOM",

      items: [
        "University of Melbourne",
        "Location:- Melbourne",
        "Annual Fee:- ₹3,773,520",
      ],
      Image: aus1,
    },

    {
      title: "UOS",

      items: [
        "University of Sydney",
        "Location:- Sydney",
        "Annual Fee:- ₹3,825,920",
      ],
      Image: aus2,
    },
    {
      title: "UOQ",

      items: [
        "University of Queensland",
        "Location:- Brisbane",
        "Annual Fee:- ₹3,620,000",
      ],
      Image: aus3,
    },
    {
      title: "UNSW",

      items: [
        "University of New South Wales (UNSW Sydney)",
        "Location:- Sydney",
        "Annual Fee:- ₹3,624,960",
      ],
      Image: aus4,
    },
    {
      title: "UWA",

      items: [
        "University of Western Australia (UWA)",
        "Location:- Brisbane",
        "Annual Fee:- ₹3,620,000",
      ],
      Image: aus5,
    },
    {
      title: "MU",

      items: [
        "Monash University",
        "Location:- Melbourne",
        "Annual Fee:- ₹3,745,000",
      ],
      Image: aus6,
    },
    {
      title: "UOA",

      items: [
        "University of Adelaide",
        "Location:- Adelaide",
        "Annual Fee:- ₹3,696,800",
      ],
      Image: aus7,
    },
    {
      title: "UOS",

      items: [
        "University of Sydney - Westmead",
        "Location:- Sydney",
        "Annual Fee:- ₹3,825,920",
      ],
      Image: aus8,
    },
    {
      title: "GUMS",

      items: [
        "Griffith University,Brisbane",
        "Location:- Brisbane",
        "Annual Fee:- ₹3,776,800",
      ],
      Image: aus9,
    },
    {
      title: "ANU",

      items: [
        "Australian National University (ANU)",
        "Location:- Canberra",
        "Annual Fee:- ₹3,620,000",
      ],
      Image: aus10,
    },
  ],
};
const NepalMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN Nepal",
  content: [
    {
      title: "IOM",

      items: [
        "Institute of Medicine (IOM), Tribhuvan University",
        "Location:- Kathmandu",
        "Annual Fee:- ₹18,48,000",
      ],
      Image: n1,
    },
    {
      title: "KUSMS",

      items: [
        "Kathmandu University School of Medical Sciences (KUSMS)",
        "Location:- Dhulikhel",
        "Annual Fee:- ₹15,40,000",
      ],
      Image: n2,
    },
    {
      title: "BPKIHS",

      items: [
        "BP Koirala Institute of Health Sciences (BPKIHS)",
        "Location:- Dharan",
        "Annual Fee:- ₹12,32,000",
      ],
      Image: n3,
    },
    {
      title: "NMC",

      items: [
        "Nepal Medical College (NMC)",
        "Location:- Kathmandu",
        "Annual Fee:- ₹11,08,800",
      ],
      Image: n4,
    },
    {
      title: "PAHS",

      items: [
        "Patan Academy of Health Sciences (PAHS)",
        "Location:- Lalitpur",
        "Annual Fee:- ₹10,48,400",
      ],
      Image: n5,
    },
    {
      title: "NMC",

      items: [
        "Nobel Medical College",
        "Location:- Biratnagar",
        "Annual Fee:- ₹9,24,000",
      ],
      Image: n6,
    },
    {
      title: "UCMS",

      items: [
        "Universal College of Medical Sciences (UCMS)",
        "Location:- Bhairahawa",
        "Annual Fee:- ₹9,24,000",
      ],
      Image: n7,
    },
    {
      title: "MCOMS",

      items: [
        "Manipal College of Medical Sciences (MCOMS)",
        "Location:- Pokhara",
        "Annual Fee:- ₹8,01,600",
      ],
      Image: n8,
    },
    {
      title: "CMC",

      items: [
        "Chitwan Medical College (CMC)",
        "Location:- Bharatpur",
        "Annual Fee:- ₹7,38,000",
      ],
      Image: n9,
    },
    {
      title: "NGMC",

      items: [
        "Nepalgunj Medical College (NGMC)",
        "Location:- Nepalgunj",
        "Annual Fee:- ₹7,38,000",
      ],
      Image: n10,
    },
  ],
};
const USAMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN USA",
  content: [
    {
      title: "HMS",

      items: [
        "Harvard Medical School",
        "Location:- Boston",
        "Annual Fee:- INR 47,55,000",
      ],
      Image: us1,
    },
    {
      title: "JHUSM",

      items: [
        "Johns Hopkins University School of Medicine",
        "Location:- Baltimore, MD",
        "Annual Fee:- INR 45,67,500",
      ],
      Image: us2,
    },
    {
      title: "SUSM",

      items: [
        "Stanford University School of Medicine",
        "Location:- Stanford, CA",
        "Annual Fee:- INR 46,40,625",
      ],
      Image: us3,
    },
    {
      title: "UCSF",

      items: [
        "University of California, San Francisco (UCSF)",
        "Location:- San Francisco, CA",
        "Annual Fee:- INR 43,35,600",
      ],
      Image: us4,
    },
    {
      title: "CUVCPS",

      items: [
        "Columbia University Vagelos College of Physicians and Surgeons",
        "Location:- New York, NY",
        "Annual Fee:- INR 51,65,950",
      ],
      Image: us5,
    },
    {
      title: "PSMUP",

      items: [
        "Perelman School of Medicine University, Pennsylvania",
        "Location:- Philadelphia, PA",
        "Annual Fee:- INR 50,86,050",
      ],
      Image: us6,
    },
    {
      title: "WUSMSL",

      items: [
        "Washington University School of Medicine in St. Louis",
        "Location:- St. louis, MO",
        "Annual Fee:- INR 48,61,800",
      ],
      Image: us7,
    },
    {
      title: "MCASM",

      items: [
        "Mayo Clinic Alix School of Medicine",
        "Location:- Rochester, MN",
        "Annual Fee:- INR 46,03,850",
      ],
      Image: us8,
    },
    {
      title: "YSM",

      items: [
        "Yale School of Medicine",
        "Location:- New Haven, CT",
        "Annual Fee:- INR 47,23,050",
      ],
      Image: us9,
    },
    {
      title: "NYUG",

      items: [
        "New York University Grossman School of Medicine",
        "Location:- New York, NY",
        "Annual Fee:- INR 47,42,875",
      ],
      Image: us10,
    },
  ],
};
const UKMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN UK",
  content: [
    {
      title: "UOMS",

      items: [
        "University of Oxford Medical School",
        "Location:- Oxford, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk1,
    },
    {
      title: "UCSCM",

      items: [
        "University of Cambridge School of Clinical Medicine",
        "Location:- Cambridge, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk2,
    },
    {
      title: "UCL",

      items: [
        "University College London (UCL) Medical School",
        "Location:- London, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk3,
    },
    {
      title: "ICLFM",

      items: [
        "Imperial College London Faculty of Medicine",
        "Location:- London, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk4,
    },
    {
      title: "UEMS",

      items: [
        "University of Edinburgh Medical School",
        "Location:- Edinburgh, Scotland",
        "Annual Fee:- ₹25,00,000 - ₹30,00,000",
      ],
      Image: uk5,
    },
    {
      title: "KCLGSME",

      items: [
        "King's College London GKT School of Medical Education",
        "Location:- London, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk6,
    },
    {
      title: "UMMS",

      items: [
        "University of Manchester Medical School",
        "Location:- Manchester, England",
        "Annual Fee:- ₹25,00,000 - ₹30,00,000",
      ],
      Image: uk7,
    },
    {
      title: "UGSM",

      items: [
        "University of Glasgow School of Medicine",
        "Location:- Glasgow, Scotland",
        "Annual Fee:- ₹25,00,000 - ₹30,00,000",
      ],
      Image: uk8,
    },
    {
      title: "UBMS",

      items: [
        "University of Bristol Medical School",
        "Location:- Bristol, England",
        "Annual Fee:- ₹30,00,000 - ₹35,00,000",
      ],
      Image: uk9,
    },
    {
      title: "UBMS",

      items: [
        "University of Birmingham Medical School, Birmingham, England",
        "Location:- Birmingham, England",
        "Annual Fee:- ₹25,00,000 - ₹30,00,000",
      ],
      Image: uk10,
    },
  ],
};
const KazakhstanMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN KAZAKHSTAN",
  content: [
    {
      title: "KSMU",

      items: [
        "Karaganda State Medical University",
        "Location:- Karaganda",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz1,
    },
    {
      title: "AMU",

      items: [
        "Astana Medical University",
        "Location:- Nur-Sultan",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz2,
    },
    {
      title: "SSMU",

      items: [
        "Semey State Medical University",
        "Location:- Semey",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz3,
    },
    {
      title: "WKMOSMU",

      items: [
        "West Kazakhstan Marat Ospanov State Medical University",
        "Location:- AKtobe",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz4,
    },
    {
      title: "SKMA",

      items: [
        "South Kazakhstan Medical Academy, Shymkent",
        "Location:- Shymkent",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz5,
    },
    {
      title: "KNMU",

      items: [
        "Kazakh National Medical University",
        "Location:- Almaty",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz6,
    },
    {
      title: "KSU",

      items: [
        "Kokshetau State University named after Shokan Ualikhanov",
        "Location:- Kokshetau",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz7,
    },
    {
      title: "KMU",

      items: [
        "Karaganda Medical University",
        "Location:- Karaganda",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz8,
    },
    {
      title: "KRMU",

      items: [
        "Kazakh-Russian Medical University",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz9,
    },
    {
      title: "NKSU",

      items: [
        "North Kazakhstan State University named after Manash Kozybayev",
        "Location:- Petropavl",
        "Annual Fee:- ₹2,25,000 - ₹5,25,000",
      ],
      Image: kz10,
    },
  ],
};
const KyrgyzstanMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN KYRGYZSTAN",
  content: [
    {
      title: "ISM",

      items: [
        "International School of Medicine",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky1,
    },
    {
      title: "KSMA",

      items: [
        "Kyrgyz State Medical Academy",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky2,
    },
    {
      title: "JSU",

      items: [
        "Jalal-Abad State University",
        "Location:- Jalal-Abad",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky3,
    },
    {
      title: "OSU",

      items: [
        "Osh State University, Osh",
        "Location:- Osh",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky4,
    },
    {
      title: "AMI",

      items: [
        "Asian Medical Institute",
        "Location:- Kant",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky5,
    },
    {
      title: "IMU",

      items: [
        "International Medical University",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky6,
    },
    {
      title: "KRSU",

      items: [
        "Kyrgyz Russian Slavic University",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky7,
    },
    {
      title: "KSMI",

      items: [
        "Kyrgyz State Medical Institute",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky8,
    },
    {
      title: "KNU",

      items: [
        "Kyrgyz National University",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky9,
    },
    {
      title: "KSAPCS",

      items: [
        "Kyrgyz State Academy of Physical Culture and Sports",
        "Location:- Bishkek",
        "Annual Fee:- ₹1,87,500 - ₹3,75,000",
      ],
      Image: ky10,
    },
  ],
};
const UzbekistanMbbsCollege = {
  heading: "TOP 10 COLLEGES FOR MBBS IN UZBEKISTAN",
  content: [
    {
      title: "TMA",

      items: [
        "Tashkent Medical Academy",
        "Location:- Tashkent",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz1,
    },
    {
      title: "SSMI",

      items: [
        "Samarkand State Medical Institute",
        "Location:- Samarkand",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz2,
    },
    {
      title: "ASMI",

      items: [
        "Andijan State Medical Institute",
        "Location:- Andijan",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz3,
    },
    {
      title: "BSMI",

      items: [
        "Bukhara State Medical Institute",
        "Location:- Bukhara",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz4,
    },
    {
      title: "KSMI",

      items: [
        "Karakalpak State Medical Institute",
        "Location:- Nukus",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz5,
    },
    {
      title: "FSUMF",

      items: [
        "Fergana State University Medical Faculty",
        "Location:- Fergana",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz6,
    },
    {
      title: "NSMI",

      items: [
        "Namangan State Medical Institute",
        "Location:- Namangan",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz7,
    },
    {
      title: "TSUMF",

      items: [
        "Termez State University Medical Faculty",
        "Location:- Termez",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz8,
    },
    {
      title: "USUMF",

      items: [
        "Urgench State University Medical Faculty",
        "Location:- Urgench",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz9,
    },
    {
      title: "TPMI",

      items: [
        "Tashkent Pediatric Medical Institute",
        "Location:- Tashkent",
        "Annual Fee:- ₹1,50,000 - ₹3,75,000",
      ],
      Image: uz10,
    },
  ],
};

export {
  UzbekistanMbbsCollege,
  KyrgyzstanMbbsCollege,
  KazakhstanMbbsCollege,
  RussiaMbbsCollege,
  UKMbbsCollege,
  BangladeshMbbsCollege,
  CanadaMbbsCollege,
  GermanyMbbsCollege,
  PhilippinesMbbsCollege,
  NepalMbbsCollege,
  AustraliaMbbsCollege,
  USAMbbsCollege,
};
