import img1 from "../asset/academicTraining.svg";
import img2 from "../asset/generalTraining.svg";
import testexam1 from "../asset/testexam1.svg";
import testexam2 from "../asset/testexam2.svg";
import testexam3 from "../asset/tesrexam3.svg";
import testexam4 from "../asset/testexam4.svg";


// const IletExamSchedule = 
//     {
//         heading : "Here are the general rules by the National Medical Commission (NMC)",
//         rules : [
//             {
//                 title: "Academic Training",
//                 content:
//                     "The IELTS exam, which lasts approximately 2 hours and 45 minutes, assesses your proficiency across four essential areas: Listening, Reading, Writing, and Speaking.",
//                 img: img1,
//                 items: [
//                     {
//                         title: "SPEAKING",
//                         content: "Topics are academic or education-related",

//                         img: testexam1,
//                     }, {
//                         title: "READING",
//                         content: "Texts are academic, often from textbooks or journals",

//                         img: testexam2,
//                     }, {
//                         title: "LISTENING",
//                         content: "Includes lectures, academic discussions",

//                         img: testexam3,
//                     }, {
//                         title: "WRITING",
//                         content: "Describe visual information, e.g., graphs, charts.Write an essay in response to a point of view",

//                         img: testexam4,
//                     },

//                 ]
//             },
//             {
//                 title: "General Training",
//                 content:
//                     "The General Training module is ideal for candidates planning to migrate to English-speaking countries for secondary education, work experience, or training programs.",
//                 img: img2,
//                 items: [
//                     {
//                         title: "SPEAKING",
//                         content: "Topics are academic or education-related",
//                         img: testexam1,
//                     }, {
//                         title: "READING",
//                         content: "Texts are academic, often from textbooks or journals",

//                         img: testexam2,
//                     }, {
//                         title: "LISTENING",
//                         content: "Includes lectures, academic discussions",

//                         img: testexam3,
//                     }, {
//                         title: "WRITING",
//                         content: "Describe visual information, e.g., graphs, charts.Write an essay in response to a point of view",

//                         img: testexam4,
//                     },

//                 ]
//             },
//         ]
//     }

// ;
const IletExamSchedule =
{
    heading: "The IELTS exam, which lasts approximately 2 hours and 45 minutes, assesses your proficiency across four essential areas: Listening, Reading, Writing, and Speaking.Two versions are accessible: Academic and General Training.",

    items: [
        {
            title: "Listening",

            items: ["(30 mins)", "Measures ability to understand spoken English in various contexts."],

            Image: testexam1,
        }, {
            title: "READING",
            items: ["(60 mins)", "Tests comprehension and interpretation of written English passages."],

            Image: testexam2,
        }, {
            title: "Writing",
            items: ["(60 mins)", "Assesses written communication skills, including coherence and accuracy."],

            Image: testexam3,
        }, {
            title: "Speaking",
            items: ["(15-20 mins)", "Evaluates oral communication proficiency and ability to convey ideas fluently."],

            Image: testexam4,
        },

    ]

}

    ;

const GmatExamSchedule =
{
    heading: "Experience the streamlined GMAT™ Focus Edition: quicker testing, flexible sections, completed in just 2 hours and 15 minutes. Scores range from 205 to 805, assessing essential skills in Quantitative Reasoning, Verbal Reasoning, and Data Insights.",

    items: [
        {
            title: "Quantitative Reasoning",

            items: ["(45 mins)", "Tests algebraic and arithmetic skills applied to problem-solving."],

            Image: testexam1,
        }, {
            title: "Verbal Reasoning",
            items: ["(45 mins)", "Assesses reading comprehension, critical reasoning, and argument evaluation."],

            Image: testexam2,
        }, {
            title: "Data Insights",
            items: ["(45 mins)", "Evaluates data analysis and real-world application, crucial for modern business."],

            Image: testexam3,
        },

    ]

};
const PteExamSchedule =
{
    heading: "PTE features four sections: speaking, writing, reading, and listening. The exam lasts around 3 to 3.5 hours, with scores valid for two years.PTE offers two versions: PTE Academic and PTE Core.",

    items: [
        {
            title: "Speaking and Writing",

            items: ["(54-67 mins)", "Assesses students on their two key communication skills: verbal and written"],

            Image: testexam1,
        }, {
            title: "Reading",
            items: ["(29-30 mins)", "Examines applicants' ability to understand written instructions in the language."],

            Image: testexam2,
        }, {
            title: "Listening",
            items: ["(30-43 mins)", "Evaluate your ability to understand spoken English"],

            Image: testexam3,
        },

    ]

};

const GreExamSchedule =
{
    heading: "The GRE features Analytical Writing, Verbal Reasoning, and Quantitative Reasoning sections, spanning approximately 1 hour and 58 minutes. Scores retain validity for 5 years, optimizing opportunities for graduate program pursuits.",

    items: [
        {
            title: "Analytical Writing",

            items: ["(30 mins)", "Assesses complex idea expression and language proficiency."],

            Image: testexam1,
        }, {
            title: "Verbal Reasoning",
            items: ["(41 mins)", "evaluates candidates’ proficiency level with English text and grammar."],

            Image: testexam2,
        }, {
            title: "Quantitative Reasoning",
            items: ["(47 mins)", "examines mathematical analysis and application skills"],

            Image: testexam3,
        },

    ]

};
const ToeflExamSchedule =
{
    heading: "The TOEFL exam, which lasts approximately 2 hours , assesses your proficiency across four essential areas: Listening, Reading, Writing, and Speaking.Two versions are accessible: TOEFL IBT and TOEFL ESSENTIAL.",

    items: [
        {
            title: "Reading",

            items: ["(35 mins)", "Evaluates comprehension and analysis of written English passages."],

            Image: testexam1,
        }, {
            title: "Writing",
            items: ["(29 mins)", "Assesses ability to express ideas clearly and effectively in written English."],

            Image: testexam2,
        }, {
            title: "Speaking",
            items: ["(16 mins)", "Measures proficiency in verbal communication and expression of ideas in English."],

            Image: testexam3,
        }, {
            title: "Listening",
            items: ["(36 mins)", "Tests comprehension of spoken English in various contexts and accents."],

            Image: testexam4,
        },

    ]

};
const SatExamSchedule = {
    heading: "The new Digital SAT: a streamlined computer-based format with 2 hours and 14 minutes of testing time. Featuring combined Reading and Writing sections and a separate Math section, each with adaptive & timed modules.",

    items: [
        {
            title: "Reading and Writing",

            items: ["(64 mins)", "Two 32-minute modules assesses comprehension, vocabulary, analysis across varied text types and rhetorical contexts"],

            Image: testexam1,
        }, {
            title: "Maths",
            items: ["(60 mins)", "Two 35-minute modules cover algebra, advanced math, problem-solving, data analysis, and geometry concepts"],

            Image: testexam2,
        },

    ]

};

export { IletExamSchedule, GmatExamSchedule, PteExamSchedule, GreExamSchedule, ToeflExamSchedule, SatExamSchedule };
