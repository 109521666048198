const CanadaFaq =
    [
        {
            "title": "What are the main immigration pathways to Canada?",
            "Desc": "Common pathways include Express Entry, Provincial Nominee Programs (PNPs), and Family Sponsorship."
        },
        {
            "title": "How do I qualify for Express Entry to Canada?",
            "Desc": "Qualification is based on factors like age, education, work experience, language proficiency, and adaptability."
        },
        {
            "title": "What is the Express Entry system in Canada?",
            "Desc": "It's an online system for managing applications for permanent residence under economic immigration programs."
        },
        {
            "title": "Can I apply for Canadian citizenship through Express Entry?",
            "Desc": "Yes, after meeting residency requirements and other criteria."
        },
        {
            "title": "How long does it take to process a Spousal Sponsorship application in Canada?",
            "Desc": "Processing times vary but can take around 12 to 18 months."
        },
        {
            "title": "What is the Settlement Funds requirement for immigrating to Canada?",
            "Desc": "It depends on the number of family members immigrating with you"
        },
        {
            "title": "How can I sponsor my parents to come to Canada?",
            "Desc": "You can apply for the Parents and Grandparents Program (PGP) when it opens for new applications."
        },
        {
            "title": "Can I apply for permanent residency in Canada while studying there?",
            "Desc": "Yes, through programs like the Canadian Experience Class (CEC) or Provincial Nominee Programs (PNPs)."
        },
        {
            "title": "What is the difference between permanent residency and citizenship in Canada?",
            "Desc": "Permanent residency allows you to live, work, and study anywhere in Canada, while citizenship grants additional rights, such as voting."
        },
        {
            "title": "Can I apply for a visa to Canada if I don't speak English or French?",
            "Desc": "Yes, but proficiency in English or French may be required for certain visa categories."
        },
        {
            "title": "Can I work in Canada while studying there as an international student?",
            "Desc": "Yes, international students with a valid study permit can work part-time on or off-campus during their studies."
        },
        {
            "title": "What is the process for obtaining permanent residency in Canada through the Provincial Nominee Program (PNP)?",
            "Desc": "It varies by province, but generally involves obtaining a nomination from a province and then applying for permanent residency to Immigration, Refugees and Citizenship Canada (IRCC)."
        },


    ];
const UkFaq =
    [
        {
            "title": "What is the Skilled Worker category in the UK?",
            "Desc": "It's a visa category for individuals with job offers or in-demand skills needed in the UK."
        },
        {
            "title": "Can I bring my family to the UK on a Skilled Worker visa?",
            "Desc": "Yes, you can bring your spouse/partner and dependent children to the UK."
        }, {
            "title": "Do I need a job offer to apply for a Skilled Worker visa in the UK?",
            "Desc": "In some cases, yes, but not always. It depends on the visa category and eligibility criteria."
        }, {
            "title": "What is the English language requirement for immigrating to the UK?",
            "Desc": "Applicants may need to pass an approved English language test, depending on the visa category."
        }, {
            "title": "Can I apply for a visa to the UK without a job offer?",
            "Desc": "Yes, there are visa categories like the Skilled Worker visa and Global Talent visa that don't require a job offer."
        }, {
            "title": "Are there age restrictions for bringing parents to the UK?",
            "Desc": "Yes, sponsors must be at least 18 years old to bring their parents to the UK."
        }, {
            "title": "Can I apply for a visa to the UK if I have a criminal record?",
            "Desc": "It depends on the nature and severity of the offense. Some convictions may make you inadmissible."
        },
    ];
const AustraliaFaq =
    [{
        "title": "What are the major visa categories for immigration to Australia?",
        "Desc": "Skilled Migration, Family Visas, and Employer-Sponsored Visas are common categories."
    }, {
        "title": "How can I immigrate to Australia as a skilled worker?",
        "Desc": "Apply through the General Skilled Migration program or seek sponsorship from an Australian employer."
    }, {
        "title": "What is the Points Test in Australia?",
        "Desc": "It's a system that assigns points based on factors like age, English proficiency, work experience, and qualifications."
    }, {
        "title": "What is the Health Requirement for immigration to Australia?",
        "Desc": "Applicants must undergo health examinations to ensure they meet health standards."
    }, {
        "title": "Can I include my partner in my Australian visa application?",
        "Desc": "Yes, you can include your spouse, de facto partner, or same-sex partner in your application."
    }, {
        "title": "Is there an age limit for immigrating to Australia?",
        "Desc": "There is no specific age limit, but age can affect points awarded in the Points Test."
    },
    {
        "title": "How can I sponsor my spouse/partner for immigration to Australia?",
        "Desc": "You can apply for a Partner visa based on your relationship."
    },
    {
        "title": "What is the Skilled Worker Regional (Provisional) visa in Australia?",
        "Desc": "It allows skilled workers to live and work in designated regional areas of Australia for up to five years."
    },
    {
        "title": "What is the difference between a permanent visa and a temporary visa in Australia?",
        "Desc": "Permanent visas allow individuals to live and work in Australia indefinitely, while temporary visas have specific time limits."
    },
    {
        "title": "How can I check my eligibility for immigration to Australia?",
        "Desc": "You can use the SkillSelect system or seek advice from a registered migration agent."
    },
    {
        "title": "Is there a fast-track option for immigration to Australia?",
        "Desc": "Yes, certain visa categories like the Global Talent Independent Program offer expedited processing for highly skilled individuals."
    },


    ];

export { CanadaFaq, UkFaq, AustraliaFaq }