import { Icon } from '@iconify/react';
import { Box, Container, Dialog, DialogTitle, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react'
import ButtonComponent from 'src/Component/content-component/ButtonComponent';
import MainHeader from 'src/Component/content-component/MainHeader';
import ParaData from 'src/Component/content-component/ParaData';
import FormComponent from 'src/Component/form-component/Form';

const CourseMainBanner = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#effdff",
                height: "auto",
                padding: { xs: "30px 15px ", sm: "40px 40px " },
            }}
        >
            <Container>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Grid
                        item
                        lg={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ pr: { lg: "40px", xs: "" } }}>
                            <MainHeader
                                color="#00999E"
                                content={
                                    "Your passion,"
                                }
                            />
                            <MainHeader
                                color="#00999E"
                                content={
                                    "Our expertise!!!"
                                }
                            />
                            <ParaData
                                content={"Explore the diverse range of programs tailored to your academic and professional aspirations. Explore the domains in  Engineering, Computer Science and Information Technology (CSIT), Business and MBA, Social Sciences, Physical and Life Sciences, Fine and Applied Arts, Health Professions, Communications and Journalism, and Designing."}
                            />

                            <Box sx={{ my: { xs: 2, sm: 5 } }}>
                                <ButtonComponent
                                    fontSize="20px"
                                    text="Register Now"
                                    onClick={handleOpenDialog}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box
                            sx={{
                                width: "100%",
                                height: { md: "300px", sm: "300px", xs: "250px", lg: "450px" },
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img src={data?.image}
                                height={"100%"}
                                width={"100%"} style={{ objectFit: "fill" }} alt="banner" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    Register Now
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
                    >
                        <Icon icon="bitcoin-icons:cross-outline" />
                    </IconButton>
                </DialogTitle>
                <FormComponent
                    handleCloseDialog={handleCloseDialog}
                    setOpenDialog={setOpenDialog}
                />
            </Dialog>
        </Box>
    )
}

export default CourseMainBanner
