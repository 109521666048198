import { Typography, Container, Stack, createTheme, Box } from "@mui/material";
import logo from "../asset/TIE_LOGO.png";
import camera from "../asset/Group 1000001729.svg";
import fb from "../asset/Group 1000001728 (1).svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@material-ui/core";
import "../index.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import brochure from "../asset/Taksheela Brochure.pdf";

import { Link } from "react-router-dom";

const Footer = () => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920
      }
    }
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  const hr = {
    marginTop: "10px",
    marginBottom: "60px"
  };
  const openWhatsAppInNewTab = () => {
    // const phoneNumber = "+917980876611";
    const phoneNumber = "+919831241212";
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };
  const handlePhoneIconClick = () => {
    // window.open(`tel:${+917980876611}`);
    window.open(`tel:${+919831241212}`);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = brochure;
    link.setAttribute("download", "Taksheela Brochure.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Container
        sx={
          {
            // paddingTop: "40px",
          }
        }
        maxWidth={isLargeScreen ? "xl" : "lg"}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: { xs: "col", md: "row", lg: "row", sm: "col" },
            gap: { xs: "0.5rem", md: "2rem", lg: "2rem", sm: "0.5rem" },
            fontSize: "17px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize"
          }}
        >
          {/* Logo Section */}
          <Grid
            item
            xs={12}
            sm={7}
            md={3}
            lg={3}
            sx={{
              flex: 1
              // margin: { xs: "5px", md: "5px", lg: "20px", sm: "5px" },
            }}
          >
            <a
              href="https://taksheela.com/"
              target="_self"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                width="150px"
                style={{ marginTop: "-36px" }}
                alt="Taksheela Logo"
              />
            </a>

            <Stack
              sx={{
                flex: 1,
                margin: "-10px 0px 0px 12px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography
                variant="body2"
                color="#606060"
                sx={{ display: "grid" }}
              >
                <Typography sx={{ fontSize: { xs: 14, md: 16, lg: 17 } }}>
                  <Typography variant="span" sx={{ fontWeight: "600" }}>
                    Head Office :{" "}
                  </Typography>
                  79/16 Palm Avenue, Ballygunje Phari, Kolkata – 700019.
                </Typography>
                <Typography sx={{ fontSize: { xs: 14, md: 16, lg: 17 } }}>
                  <Typography variant="span" sx={{ fontWeight: "600" }}>
                    Delhi branch office:{" "}
                  </Typography>
                  Hub Hive 11, 1st Floor, 262, Plot 1, ITDC Western Marg, Near
                  Saket Metro, New Delhi 110017
                </Typography>
                <Typography sx={{ fontSize: { xs: 14, md: 16, lg: 17 } }}>
                  <Typography variant="span" sx={{ fontWeight: "600" }}>
                    Email:{" "}
                  </Typography>
                  <a href="mailto:info@taksheela.com">info@taksheela.com</a>
                </Typography>
              </Typography>

              <Stack
                sx={{
                  display: "flex",
                  gap: ".5rem",
                  flexDirection: "row",
                  marginTop: "10px",
                  fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
                  paddingBottom: {
                    xs: "12px",
                    sm: "12px",
                    md: "0px",
                    lg: "0px"
                  }
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <a
                    href="https://www.instagram.com/taksheelaedu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ margin: "5px" }}
                  >
                    <img
                      src={camera}
                      style={{ width: isSmScreen ? "35px" : "35px" }}
                      alt="Camera"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/taksheelainstituteofeducation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ margin: "5px" }}
                  >
                    <img
                      src={fb}
                      style={{ width: isSmScreen ? "35px" : "35px" }}
                      alt="Facebook"
                    />
                  </a>
                </Box>
              </Stack>

              <WhatsAppIcon
                message="Hello, how can we help you?"
                phoneNumber="6267293696"
                position="right"
                showPopup={false}
                onClick={openWhatsAppInNewTab}
                sx={{
                  position: "fixed",
                  bottom: "20px",
                  right: "90px",
                  fontSize: "40px",
                  padding: "5px",
                  backgroundColor: "#25d366",
                  color: "#fff !important",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  zIndex: 20
                }}
              />

              <CallIcon
                message="Hello, how can we help you?"
                phoneNumber="6267293696"
                position="right"
                showPopup={false}
                onClick={handlePhoneIconClick}
                style={{
                  position: "fixed",
                  bottom: "20px",
                  fontSize: "40px",
                  padding: "5px",
                  right: "20px",
                  color: "#F0F0F0",
                  backgroundColor: "#1976d2",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  zIndex: 20
                }}
              />
            </Stack>
          </Grid>

          {/* ---------------Services Section-----------*/}
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            sx={{
              margin: { xs: "10px", md: "10px", lg: "20px" },
              display: "grid",
              color: "#606060",
              gap: ".3rem"
            }}
          >
            <Stack sx={{ paddingLeft: { lg: "70px", md: "50px" } }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 14, md: 20, lg: 21 },
                  paddingBottom: "15px"
                }}
              >
                SERVICES
              </Typography>
              <Link to="/study-abroad" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Study Abroad
                </Typography>
              </Link>
              <Link to="/immigration" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Immigration
                </Typography>
              </Link>
              <Link to="/mbbs" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  MBBS
                </Typography>
              </Link>
              <Link to="/test" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Test Prep
                </Typography>
              </Link>
            </Stack>
          </Grid>
          {/* ----- Menu Section ----- */}
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            lg={3}
            sx={{
              margin: { xs: "10px", md: "10px", lg: "20px" },
              gap: ".3rem",
              fontWeight: 600,
              fontSize: { xs: 14, md: 20, lg: 21 }
            }}
          >
            <Stack
              sx={{ paddingLeft: { xl: "100px", lg: "80px", md: "70px" } }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 14, md: 20, lg: 21 },
                  paddingBottom: "15px"
                }}
              >
                MENU
              </Typography>
              <Link to="/" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Home
                </Typography>
              </Link>
              <Link to="/aboutus" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Our Story
                </Typography>
              </Link>
              <Box
                className="custom-link"
                onClick={handleDownload}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <Typography
                  // color="#606060"
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Download Brochure
                </Typography>
                {/* <Icon icon="eva:download-fill" fontSize={"22px"} /> */}
              </Box>

              <Link to="/contact" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Contact Us
                </Typography>
              </Link>
            </Stack>
          </Grid>
          {/* ---------------Other Section-----------*/}
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            sx={{
              // margin: { xs: "10px", md: "10px", lg: "20px" },
              display: "grid",
              color: "#606060",
              gap: ".3rem"
            }}
          >
            <Stack sx={{ paddingLeft: { lg: "70px", md: "50px" } }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 14, md: 20, lg: 21 },
                  paddingBottom: "15px"
                }}
              >
                Others
              </Typography>
              <Link to="/privacy-policy" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link to="/refund-policy" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Refund Policy
                </Typography>
              </Link>
              <Link to="/terms-&-conditions" className="custom-link">
                <Typography
                  variant="body2"
                  color="#606060"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: 14, md: 16, lg: 17 },
                    padding: {
                      xs: "5px 0",
                      sm: "5px 0",
                      md: "5px 0",
                      lg: "5px 0"
                    }
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>

        <hr style={hr} />
      </Container>

      {/* use letter */}
      {/* 
    <Stack>
        <Container
          sx={{
            marginTop: { xs: "10px", sm: "20px", md: "50px", lg: "10px" },
          }}
        >
          <Stack>
            <Stack
              sx={{
                display: "flex",
                gap: ".2rem",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                  lg: "col",
                },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                  lg: "space-between",
                },
                alignItems: "center",
                marginTop: { xs: "10px", sm: "10px", md: "10px", lg: "10px" },
                marginBottom: {
                  xs: "10px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                },
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  gap: ".2rem",
                  flexDirection: "row",
                  fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
                }}
              >
                <Stack
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "19px",
                      lg: "20px",
                    },
                    fontWeight: 400,
                  }}
                >
                  Made with{" "}
                </Stack>
                <Stack>
                  <img
                    sx={{
                      width: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
                    }}
                    style={{ width: isSmScreen ? "18px" : "30px" }}
                    src={heart}
                    alt="Heart"
                  ></img>
                </Stack>
                <Stack
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "19px",
                      lg: "20px",
                    },
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  in India By: EVD Technology
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    */}
    </>
  );
};

export default Footer;
