import { Box, Grid } from '@mui/material'
import React from 'react'
import ContentContainer from 'src/Component/grid-component/ContentContainer'
import Header from 'src/Component/content-component/Header'
import ParaData from 'src/Component/content-component/ParaData'
import ButtonComponent from 'src/Component/content-component/ButtonComponent'
import { Icon } from '@iconify/react'
import Cta from 'src/Component/card-components/Cta'

const CountryInfoPage = ({ data }) => {
    return (
        <Box>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ alignItems: "center" }}>
                        <Box sx={{ width: "95%", height: "100px", mt: "110px", backgroundColor: "#00999E" }}>

                        </Box>
                        <Box sx={{ mt: { md: "-220px", sm: "-170px", xs: "-150px" }, width: { md: "520px", sm: "450px", xs: "280px" }, ml: { md: "150px", sm: "150px", xs: "50px" } }}>
                            <img src={data?.image} alt='' width={"100%"} />

                        </Box>


                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} display={'flex'} justifyContent={"start"} alignItems={"center"}>
                    <Box sx={{ padding: { md: "0 70px", sm: "0 40px", xs: "0" } }}>
                        <ContentContainer
                            Header={<Header content={data?.title} />}
                            Content={
                                <ParaData
                                    color={"#525560"}
                                    fontSize={"16px"}
                                    content={
                                        data?.content
                                    }
                                />
                            }


                        />
                        <Box sx={{
                            paddingLeft: "20px",
                            // mt: "-30px"
                        }}>

                            <Cta />
                            {/* <ButtonComponent
                                width={"160px"}
                                text="Read More"
                                icon={<Icon icon="fluent:arrow-right-28-regular" />}

                            // href="/mbbs/india"
                            /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}

export default CountryInfoPage
