import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import ButtonComponent from "src/Component/content-component/ButtonComponent";

const OurServicesCard = ({ data, id }) => {
  return (

    <Grid container spacing={2} justifyContent={"space-between"} display={"flex"} flexDirection={id % 2 === 0 ? "row-reverse" : "row"}>

      <Grid item md={6} sm={12} xs={12} justifyContent={{ xs: "center" }} display={{ xs: "flex", md: "block" }} >
        <Box
          sx={{
            height: "250px",
            width: "250px",
            background: "linear-gradient(to right, #767676, #CFDFE0)",
            display: "flex",
            justifyContent: "center",

            borderRadius: "5px",
            overflow: "hidden",
            pb: "0px", pt: "auto"

          }}
        >
          <img src={data?.image} alt="" width={"100%"} />
        </Box>
      </Grid>
      <Grid item md={6} sm={12} xs={12} justifyContent={{ xs: "center" }} display={{ xs: "flex", md: "block" }}>
        <Box
          sx={{
            height: "250px",
            width: "250px",
            position: "relative",
            backgroundColor: "#00999e",
            borderRadius: "5px",
            overflow: "hidden",
            "&::before": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url("${data?.background}")`,
              backgroundSize: "cover",
              opacity: 0.1,
              zIndex: 0,

            }
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "100px", cursor: "pointer" }}>
            <Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "600", textAlign: "center", }}>
              {data?.title}
            </Typography>
          </Box>

          <Box sx={{ position: "absolute", bottom: "20px", right: "60px" }}>
            <ButtonComponent
              backgroundColor={"#ffffff"}
              hoverbackgroundColor={"#5CD2D6"}
              text={"View Details"}
              color={"#00999E"}
              fontWeight={"600"}
              width={"150px"}
              href={data?.path}
            />
          </Box>
        </Box>
      </Grid>

    </Grid>
  );
};

export default OurServicesCard;
