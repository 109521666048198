/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import MainHeader from "src/Component/content-component/MainHeader";
import FormComponent from "src/Component/form-component/Form";
import BoyImg from "src/asset/immigration.png";
// import BoyImg from "src/asset/ImmigrationBannerImage.png";
import { Icon } from "@iconify/react";
import Cta from "src/Component/card-components/Cta";

const Banner = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "rgba(16, 144, 203, 0.1)",
        height: "auto",
        padding: { xs: "30px 15px ", sm: "40px 40px " },
      }}
    >
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <MainHeader
                color="rgba(0, 0, 0, 0.7)"
                content={
                  <Box sx={{ fontSize: { xs: "20px", sm: "40px" } }}>
                    <Typography
                      variant="h2"
                      sx={{
                        color: "#00999E",
                        display: "inline-block",
                        fontWeight: "inherit",
                        fontSize: "inherit",
                      }}
                    >
                      Immigration:{" "}
                    </Typography>{" "}
                    Where dreams take flight and cultures unite.
                  </Box>
                }
              />

              <Box sx={{ my: { xs: 2, sm: 5 } }}>
                <Cta title={"BOOK A COUNSELLING SECTION"} />

              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                width: "100%",
                height: { md: "300px", sm: "300px", xs: "250px", lg: "450px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={BoyImg}
                height={"100%"}
                width={"100%"} style={{ objectFit: "fill" }} alt="banner" />
            </Box>
          </Grid>
        </Grid>
      </Container>

    </Box>
  );
};

export default Banner;
