import { Icon } from '@iconify/react'
import { Box, Container, Dialog, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ButtonComponent from 'src/Component/content-component/ButtonComponent'
import Header from 'src/Component/content-component/Header'
import FormComponent from 'src/Component/form-component/Form'

const UniversityKnowMore = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleBoxClick = (url) => {
        window.open(url, '_blank');
    };
    return (
        <Box sx={{ padding: "40px 0" }}>
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 5,
                    }}
                >
                    <Header content={"Know More"} textAlign={"center"} />
                </Box>
                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        data?.map((item, index) => (
                            <Grid item lg={4} md={4} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>


                                <Box onClick={() => handleBoxClick(item.url)}>
                                    <Box
                                        sx={{
                                            width: "250px",
                                            height: "250px",
                                            bgcolor: "#ffffff",
                                            borderRadius: "5px",
                                            // border: "2px solid #c1c1c1",
                                            boxShadow: " rgba(100, 100, 111, 0.2) 7px 7px 29px 0px",
                                            "&:hover": { bgcolor: "#effdff" },
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center", alignItems: "center",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Box sx={{ width: "240px", padding: "10px 0", height: "80px" }}>
                                            <Typography variant="h5" textAlign={"center"} sx={{ color: "#00999E", fontWeight: "600" }}>{item?.title}</Typography>
                                        </Box>
                                        <Box sx={{ width: "130px" }}>
                                            <img src={item?.img} width={"100%"} alt="immigration-benefit" />
                                        </Box>

                                    </Box>
                                </Box></Grid>
                        ))
                    }
                </Grid>
                <Box sx={{ mt: { xs: 2, sm: 8, textAlign: 'center' } }}>
                    <ButtonComponent
                        fontSize="20px"
                        text="Register Now"
                        onClick={handleOpenDialog}
                    />
                </Box>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    Register Now
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
                    >
                        <Icon icon="bitcoin-icons:cross-outline" />
                    </IconButton>
                </DialogTitle>
                <FormComponent
                    handleCloseDialog={handleCloseDialog}
                    setOpenDialog={setOpenDialog}
                />
            </Dialog>
        </Box>
    )
}

export default UniversityKnowMore
