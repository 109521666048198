import { Box, Container, Grid } from "@mui/material";
import Card from "src/Component/card-components/Card";
import countryData from "../json/countrydata";
import Header from "src/Component/content-component/Header";
import { Link } from "react-router-dom";
const CountriesList = () => {


  return (
    <>
      <Box sx={{ backgroundColor: "white", padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" } }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0",
            }}
          >
            <Header

              content={"Countries we work with "} textAlign={"center"}
            />


          </Box>
          <Grid container spacing={3} sx={{ display: "flex", justifyContent: "center" }}>
            {countryData?.map((country, index) => {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={index}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Link to={country?.path}>

                      <Card

                        data={country}
                        borderRadius="0px"
                        cardHeight={"200px"}
                        cardWidth={"200px"}
                        width="160px"
                        height="100px"
                        hoverHeight="22%"
                      />
                    </Link>
                  </Grid>
                </>
              );
            })}
          </Grid>

        </Container>
      </Box>
    </>
  );
};
export default CountriesList;
