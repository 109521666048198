import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Container } from '@mui/material';





const PostStudy = ({ data }) => {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'auto', padding: "0 40px" }}>
      <Container>

        <TableContainer component={Paper} sx={{ width: '100%', height: 'auto', border: "1px solid #c1c1c1" }}>
          <Table aria-label="customized table"  >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#00999E", }}>
                <TableCell align="center" sx={{ borderRight: "1px solid #c1c1c1", color: "#ffffff" }}>Courses</TableCell>
                <TableCell align="center" sx={{ color: "#ffffff" }}>Average Annual Salary (INR)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow sx={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f2f2f2" }}>
                  <TableCell component="th" scope="row" sx={{ borderRight: "1px solid #c1c1c1" }} >
                    {item.Field}
                  </TableCell>
                  <TableCell align="right" sx={{ textAlign: "center" }}>{item.Salary}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>       
    </Box>
  );
}

export default PostStudy;
