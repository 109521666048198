import c1 from '../../asset/courseIntro/1.png'
import c2 from '../../asset/courseIntro/2.png'
import c3 from '../../asset/courseIntro/3.png'
import c4 from '../../asset/courseIntro/4.png'
import c5 from '../../asset/courseIntro/5.png'
import c6 from '../../asset/courseIntro/6.png'
import c7 from '../../asset/courseIntro/7.png'
import c8 from '../../asset/courseIntro/8.png'
import c9 from '../../asset/courseIntro/9.png'

const EngineeringIntro = {

    image: c1,
    title: "Engineering",
    content: "Engineering programs, particularly in fields such as computer science, electrical engineering, mechanical engineering, and civil engineering, have long been popular choices for Indian students studying abroad. Engineering remains a cornerstone of academic pursuit for Indian students venturing abroad.",
};
const CSITIntro = {

    image: c2,
    title: "CSIT: A Gateway to Technological Innovation",
    content: "In today's digital age, Computer Science and Information Technology (CSIT) are crucial for technological advancement and innovation. Computer Science explores computing's theoretical foundations, while Information Technology applies computing technology practically to solve real-world problems for businesses and organizations. Together, they drive innovation in our digital world.",
};
const HsmIntro = {

    image: c3,
    title: "Health Sciences and Medicine",
    content: "Health Sciences and Medicine, grounded in biology, anatomy, and physiology, aim to promote health, prevent disease, and alleviate suffering. From pioneering medical research to compassionate patient care, they embody hope and progress, shaping the future of healthcare and inspiring generations to serve humanity with excellence.",
};
const SocialIntro = {

    image: c4,
    title: "Social Science",
    content: "Social Science encompasses a diverse range of disciplines that seek to understand human society and behavior through systematic observation, analysis, and interpretation. It explores various aspects of human life, including culture, politics, economics, psychology, anthropology, sociology, geography, history, and more. Social scientists employ both quantitative and qualitative research methods to examine social phenomena, address societal challenges, and inform public policy.",
};
const BusinessIntro = {

    image: c5,
    title: "Business and Management",
    content: "Studying Business and Management abroad equips students with essential skills like critical thinking, leadership, and teamwork. With coursework in economics, finance, marketing, and strategic management, they gain insights into diverse business environments. Access to top-notch faculty and modern resources prepares them to tackle real-world challenges, explore entrepreneurship, and pursue rewarding careers worldwide.",
};
const PlsIntro = {

    image: c6,
    title: "Physical and Life Sciences",
    content: "Studying Physical and Life Sciences abroad provides an exhilarating exploration of nature's fundamental principles. Students engage in theoretical study and hands-on experimentation across disciplines like physics, chemistry, biology, and environmental science. This interdisciplinary approach nurtures an appreciation for nature's intricacies and the unity of all life. ",
};
const FaaIntro = {

    image: c7,
    title: "Fine and Applied Art",
    content: "Studying Fine and Applied Art abroad immerses students in diverse artistic disciplines like painting, sculpture, graphic design, photography, and multimedia art. Through theoretical study and hands-on practice, it cultivates innovation, critical thinking, and technical skills. With access to top faculty, facilities, and a vibrant artistic community, students develop their artistic voice, pursue varied career paths, and contribute to global cultural discourse.",
};
const CJIntro = {

    image: c8,
    title: "Communication and Journalism",
    content: "Studying Communication and Journalism abroad immerses students in media, storytelling, and information dissemination. This interdisciplinary program blends theory and practice, covering journalism, broadcasting, public relations, digital media, and advertising. Students develop critical thinking, research, writing, and multimedia production skills to navigate the evolving media landscape.",
};
const DesigningIntro = {

    image: c9,
    title: "Designing",
    content: "Studying design abroad provides a dynamic educational journey exploring disciplines like graphic design, industrial design, fashion design, and interior design. Through theoretical learning and practical projects, students hone skills in conceptualization, visualization, and problem-solving. With access to top faculty, modern facilities, and diverse environments, students unleash creativity and prepare for global design careers.",
};


export { EngineeringIntro, CSITIntro, HsmIntro, SocialIntro, BusinessIntro, PlsIntro, FaaIntro, CJIntro, DesigningIntro }