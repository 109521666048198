import { Box, Container, Grid, Typography } from "@mui/material";
import Card from "src/Component/card-components/Card";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import Header from "src/Component/content-component/Header";
import { useNavigate } from "react-router";

import CountriesForImmigration from "src/json/CountriesForImmigration";

const TestExamStructure = ({ testAssessible }) => {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ backgroundColor: "#FFFFFF", p: '50px 0' }}>
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,

                        }}
                    >
                        <Header
                            textAlign="center"
                            content="Exam Structure, Content & Features"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 5,
                        }}
                    >
                        <Typography variant="caption" sx={{ display: "flex", justifyContent: "center", textAlign: "center" }} fontSize={"18px"} fontWeight={"600"}>{testAssessible?.heading}</Typography>
                    </Box>
                    <Grid container spacing={3} justifyContent={"center"}>
                        {testAssessible?.items?.map((item, index) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        key={index}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <Card
                                            data={item}
                                            titleFontSize={"18px"}
                                            borderRadius="0px"
                                            cardHeight={"240px"}
                                            cardWidth={"240px"}
                                            width="180px"
                                            height="130px"
                                            padding="0px 0px"
                                            fontSize={"16px"}
                                            listStyle={"none"}
                                            margin={"0px"}
                                        //   hoverHeight="22%"
                                        />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px",
                        }}
                    ><ButtonComponent
                            text="Read More"
                            height="45px" width={"210px"}
                            onClick={() => navigate("/contact")}
                        />
                        {/* <Button
                        sx={{
                            fontSize: "14px",
                            width: "80%",
                            height: "40px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            textAlign: "center",
                            color: "#ffffff",
                            borderRadius: "8px",
                            transition: "all 0.3s linear",
                            backgroundColor: "#00999E",
                            border: "2px solid #000000",
                            
                        }}
                        
                    >
                        Buy Now
                    </Button> */}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default TestExamStructure;
