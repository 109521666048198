import { Grid } from "@material-ui/core";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import vision from "../../asset/vision.png";
import "../../index.css";
import "../../globle.css";
import ellipse2 from "../../asset/mission-ellipse.png";
import Header from "../content-component/Header";

const Vision = () => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid
      container
      justifyContent="space-between"
      style={{
        height: isMediumScreen ? "auto" : "280px",
        // padding: "50px 0",
        marginBottom: "10px",
        position: "relative",
        // border: "1px solid black",
      }}
    >
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "row",
          }}
        >
          <img
            src={vision}
            alt="heroImg1"
            loading="lazy"
            style={{
              width: isSmScreen ? "100%" : isMediumScreen ? "85%" : "80%",
              height: "auto",
              // opacity: "0.8",
              zIndex: 2,
            }}
          />
        </Stack>

        <img
          src={ellipse2}
          alt="vision"
          loading="lazy"
          style={{
            position: "absolute",
            top: "50px",
            left: "-70px",
            zIndex: 1,
            // height: "50%",
            width: "150px",
            height: "150px",
            transform: "rotate(90deg)",
            display: isMediumScreen ? "none" : "block",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={7} lg={8}>
        <Box sx={{ paddingTop: { xs: "40px", sm: "0px", md: "0px", lg: "0px" }, }}> <Header
          color="#00999e"
          // textAlign="center"
          content="Vision"
        />
          {/* <Typography
            variant="h2"
            gutterBottom
            sx={{
              color: "#00999E",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: { xs: "40px", sm: "42px", md: "48px", lg: "55px" },
              textTransform: "capitalize",
              paddingTop: { xs: "40px", sm: "0px", md: "0px", lg: "0px" },
            }}
          >
            ~Vision~
          </Typography> */}
          <Typography
            variant="p"
            sx={{
              color: "black",
              fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.8",
              textTransform: "capitalize",
            }}
            className="text-justify"
          >
            Empowering students with transformative global education, promoting
            cross-cultural understanding, and creating lifelong success
            opportunities.
          </Typography>
        </Box>
        {/* <Stack sx={{
                    width: { xs: "50%", sm: "50%", md: "30%", lg: "30%" },
                    paddingTop: "50px",

                }}>
                    <Button variant="contained"
                        style={{ backgroundColor: "#00999E" }}
                    >
                        Read More
                    </Button>
                </Stack> */}
      </Grid>
    </Grid>
  );
};

export default Vision;
