import { Box, Container, Typography } from '@mui/material'
import React from 'react'

import doc1 from '../../asset/mbbs3.png';
import MainHeader from '../content-component/MainHeader';

import Cta from '../card-components/Cta';




const HeroComponet = () => {




   return (
      <>
         <Box sx={{
            width: '100%',
            backgroundColor: 'rgba(16, 144, 203, 0.1)',
            paddingTop: "30px",
         }}>
            <Container sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

               <MainHeader
                  textAlign="center"

                  color="rgba(0, 0, 0, 0.7)"
                  content={
                     <Box sx={{ fontSize: { xs: "20px", sm: "40px" } }}>
                        <Typography
                           variant="h2"
                           sx={{
                              color: "#00999E",
                              display: "inline-block",
                              fontWeight: "inherit",
                              fontSize: "inherit",
                           }}
                        >
                           MBBS:{" "}
                        </Typography>{" "}A challenging path, but with passion and perseverance, your future of healing awaits
                     </Box>
                  }
               />

               <Box
                  sx={
                     {
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0"
                     }
                  }
               >
                  <Cta title={"BOOK YOUR FREE DEMO SESSION"} />
               </Box>
               <Box sx={{ display: "flex", justifyContent: "center", width: { md: "600px", sm: "400px", xs: "300px" } }}>
                  <img src={doc1} alt='DoctorImage' width={"100%"} />
               </Box>

            </Container>
         </Box>

      </>
   )
}

export default HeroComponet


