/* eslint-disable jsx-a11y/img-redundant-alt */
import {
    Box,
    Container,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
// import MainHeader from "src/Component/content-component/MainHeader";
import FormComponent from "src/Component/form-component/Form";
// import Img from "src/asset/StudyabroadMain.svg";
import Img from '../../asset/countryintro.svg'

import { Icon } from "@iconify/react";

const StudyAbroadBanner = () => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#effdff",
                height: "auto",
                padding: { xs: "30px 15px 0px", sm: "40px 40px 0px" },
            }}
        >
            <Container maxWidth={"lg"}>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    spacing={4}
                >
                    <Grid
                        item
                        lg={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                className="flip-header"
                                variant="h4"
                                sx={{
                                    fontSize: { xs: "24px", sm: "26px", md: "36px", lg: "36px" },
                                    fontFamily: "Poppins",
                                    color: "#00999E",
                                    fontWeight: "600",
                                    padding: " 20px 0"
                                }}
                            >
                                Discover Pathways</Typography>
                            {/* <MainHeader
                                fontSize={"20px"}
                                fontWeight={"400"}
                                color="rgba(0, 0, 0, 0.7)"
                                content={

                                    "Explore the diverse educational pathways worldwide. From renowned universities to unique learning experiences, unlock the doors to your global academic adventure."}
                            /> */}

                            <Box sx={{ my: { xs: 2, sm: 5 } }}>
                                <ButtonComponent
                                    fontSize="20px"
                                    text="Register Now"
                                    height={"50px"}
                                    onClick={handleOpenDialog}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box
                            sx={{
                                aspectRatio: 16 / 9,
                                // width: { md: "600px" },
                                // height: { md: "300px", sm: "300px", xs: "410px", lg: "530px" },
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: "10px"
                            }}
                        >
                            <img src={Img} width={"100%"} alt="Boy-Image" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    Register Now
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
                    >
                        <Icon icon="bitcoin-icons:cross-outline" />
                    </IconButton>
                </DialogTitle>
                <FormComponent
                    handleCloseDialog={handleCloseDialog}
                    setOpenDialog={setOpenDialog}
                />
            </Dialog>
        </Box>
    );
};

export default StudyAbroadBanner;
