import { Box, Container, Grid } from "@mui/material";
import React from "react";
import HexagonalCard from "src/Component/HexagonalCard";
import icon1 from "../asset/counselling.svg";
import icon2 from "../asset/profile.svg";
import icon3 from "../asset/editing.svg";
import icon4 from "../asset/visa.svg";
import hovericon1 from "../asset/img1.svg";
import hovericon2 from "../asset/img2.svg";
import hovericon3 from "../asset/img3.svg";
import hovericon4 from "../asset/img4.svg";
import Header from "src/Component/content-component/Header";

const StepsForImmigration = () => {
  return (
    <Box backgroundColor="#EFFDFF" py={5}>
      <Container>
        <Box mb={5}>

          <Header
            content={"Steps For Immigration"}
            color="#00999E"
            textAlign={"center"}
          />
        </Box>
        <Grid container
          rowSpacing={3}
          sx={{

            justifyContent: "center",

            alignItems: "center",

          }}
        >
          {cardDetails.map((card, index) => {

            return (
              <Grid item md={3} sm={12} xs={12} mb={{ md: "100px", xs: "10px" }}>
                <HexagonalCard
                  lastIndex={cardDetails.length - 1}
                  ind={index}
                  id={card.id}
                  sx={{ backgroundColor: "#E0F2F3" }}
                  hoverIcon={card?.hoverIcon}
                  icon={card.icon}
                  title={card.title}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container
          rowSpacing={3}
          sx={{

            justifyContent: "center",
            // flexWrap: "wrap",
            // gap: { xs: "140px", sm: "26px", md: "26px", lg: "90px" },
            // marginTop: { xs: "120px", sm: "160px", md: "170px", lg: "170px" },
            // flexDirection: { xs: "col", sm: "row", md: "row", lg: "row" },
            alignItems: "center",
            // padding: { xs: "10px", sm: "10px", md: "35px", lg: "35px" },
          }}
        >
          {cardDetails2.map((card, index) => {
            return (
              <Grid item md={3} sm={12} xs={12}>
                <HexagonalCard
                  lastIndex={cardDetails2.length - 1}
                  ind={index}
                  id={card.id}
                  sx={{ backgroundColor: "#E0F2F3" }}
                  hoverIcon={card?.hoverIcon}
                  icon={card.icon}
                  title={card.title}
                />
              </Grid>
            );
          })}
        </Grid>

      </Container>
    </Box>
  );
};

export default StepsForImmigration;

const cardDetails = [
  {
    id: 1,

    icon: icon2,
    hoverIcon: hovericon2,
    title: "Free Consultation",
  },
  {
    id: 2,
    icon: icon1,
    hoverIcon: hovericon1,
    title: "Eligibility",
  },
  {
    id: 3,
    icon: icon4,
    hoverIcon: hovericon4,
    title: "Documents Checklist",
  },
  {
    id: 4,
    icon: icon1,
    hoverIcon: hovericon1,
    title: "Budget Preparation",
  },


];

const cardDetails2 = [

  {
    id: 5,
    icon: icon3,
    hoverIcon: hovericon3,
    title: "Application Submission",
  },
  {
    id: 6,
    icon: icon3,
    hoverIcon: hovericon3,
    title: "Follow-Up and Updates",
  },
  {
    id: 7,
    icon: icon2,
    hoverIcon: hovericon2,
    title: "Visa Approval",
  },
];
