import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  username: yup.string().when('type', {
    is: 'register',
    then: yup.string().required('Username is required'),
  }),
  password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
});

const Login = ({ type }) => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      username: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let response;
      if (type === 'register') {
        response = await axios.post(`${process.env.REACT_APP_BASE_URL}student/sign-up`, data);
      } else if (type === 'login') {
        response = await axios.post(`${process.env.REACT_APP_BASE_URL}student/login`, {
          email: data.email,
          password: data.password,
        });
      }
      if (response.data.success) {
        const token = response.data.data.token;
        const username = type === 'login' ? response.data.data.student.username : response.data.data.result.username;
        if (token) {
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('username', username);
          toast.success(type === 'login' ? 'Login successful!' : 'Register Successful!');
          reset();

          if (location.state?.path) {
            let cartData = JSON.parse(localStorage.getItem('cart'));
            if (cartData) {
              let transformedData = {
                userId: type === 'login' ? response.data.data.student.id : response.data.data._id,
                items: cartData.map(item => ({
                  planName: item.title,
                  amount: item.packagePrice,
                  planId: item.id
                }))
              };

              let cartResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}order/add-cart`, transformedData, { headers: { Authorization: response.data.data.token } });
              if (cartResponse.data.success) {
                localStorage.removeItem('cart');
              }
            }
          }

          const redirectPath = location.state?.path || '/dashboard/home';
          setTimeout(() => navigate(redirectPath), 2000);
        } else {
          toast.error('Token is missing. Please try again.');
        }
      } else {
        toast.error('An error occurred. Please try again.');
      }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'An error occurred');
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toaster position="top-right" reverseOrder={false} />
      <Stack py={2} gap={3} sx={{ width: "100%", height: "400px" }}>
        {type === 'register' && (
          <Box width={{ md: "450px", xs: "320px" }}>
            <Box sx={{ mb: "20px" }}>
              <Typography mb={1}>Email Address</Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size='small'
                    label='Enter your Email Address'
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: "20px" }}>
              <Typography mb={1}>User Name</Typography>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size='small'
                    label='Enter your User name'
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ''}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: "20px" }}>
              <Typography mb={1}>Password</Typography>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    size='small'
                    label='Enter your Password'
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size='small'
                          >
                            {showPassword === false ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        )}

        {type === 'login' && (
          <Box width={{ md: "450px", xs: "320px" }}>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography mb={1}>Email Address</Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size='small'
                    label='Enter your Email Address'
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: "20px" }}>
              <Typography mb={1}>Password</Typography>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    size='small'
                    label='Enter your Password'
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size='small'
                          >
                            {showPassword === false ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box display='flex' justifyContent='end' alignItems={"center"} onClick={() => navigate("/forgetpassword")} sx={{ cursor: "pointer" }}>

              <Typography variant='subtitle2'>Forgot Password?</Typography>
            </Box>
          </Box>
        )}

        <Box display='flex' justifyContent='flex-end'>
          <LoadingButton
            loading={loading}
            sx={{
              backgroundColor: '#00999E',
              width: "40%",
              height: "45px",
              '&:focus': {
                outline: 'none',
                backgroundColor: type === 'login' ? '#007f80' : '',
                color: type === 'login' ? '#fff' : '#007f80',
              },
              color: "#fff",
              '&:hover': {
                backgroundColor: '#007f80',
                color: '#fff',
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#ffffff"
              }
            }}
            size='large'
            type="submit"
          >
            {type === 'login' ? !loading && 'Login' : !loading && 'Register'}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};

export default Login;
