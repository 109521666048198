import React, { useState } from 'react';
import { Box, Checkbox, Typography, Grid, TextField } from '@mui/material';

const PermanentAndMailingAddress = () => {
  const [permanentAddress, setPermanentAddress] = useState({
    address: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [mailingAddress, setMailingAddress] = useState({
    address: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [isChecked, setIsChecked] = useState(false);

  const handlePermanentAddressChange = (e) => {
    const { name, value } = e.target;
    setPermanentAddress((prev) => ({ ...prev, [name]: value }));
    if (isChecked) {
      setMailingAddress((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setMailingAddress(permanentAddress);
    } else {
      setMailingAddress({
        address: '',
        state: '',
        city: '',
        pincode: '',
      });
    }
  };

  const handleMailingAddressChange = (e) => {
    const { name, value } = e.target;
    setMailingAddress((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          fontSize: {
            xs: '20px',
            sm: '20px',
            md: '20px',
            lg: '20px',
          },
          paddingBottom: '10px',
          paddingTop: '30px',
        }}
      >
        PERMANENT ADDRESS
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="Permanent Address"
            name="address"
            variant="outlined"
            fullWidth
            value={permanentAddress.address}
            onChange={handlePermanentAddressChange}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="State"
            name="state"
            variant="outlined"
            fullWidth
            value={permanentAddress.state}
            onChange={handlePermanentAddressChange}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="City"
            name="city"
            variant="outlined"
            fullWidth
            value={permanentAddress.city}
            onChange={handlePermanentAddressChange}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="Pin Code"
            name="pincode"
            variant="outlined"
            fullWidth
            value={permanentAddress.pincode}
            onChange={handlePermanentAddressChange}
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" sx={{ paddingBottom: '10px', paddingTop: '30px' }}>
       
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: '20px',
              sm: '20px',
              md: '20px',
              lg: '20px',
            },
          }}
        >
          MAILING ADDRESS
        </Typography>
   
       
        <Typography sx={{fontSize:'18px', paddingLeft:'30px',color:'grey'}}>
        
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          (same as permanent address)</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="Mailing Address"
            name="address"
            variant="outlined"
            fullWidth
            value={mailingAddress.address}
            onChange={handleMailingAddressChange}
            disabled={isChecked}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="State"
            name="state"
            variant="outlined"
            fullWidth
            value={mailingAddress.state}
            onChange={handleMailingAddressChange}
            disabled={isChecked}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="City"
            name="city"
            variant="outlined"
            fullWidth
            value={mailingAddress.city}
            onChange={handleMailingAddressChange}
            disabled={isChecked}
          />
        </Grid>
        <Grid item lg={3} xs={12} sm={3} md={3}>
          <TextField
            label="Pin Code"
            name="pincode"
            variant="outlined"
            fullWidth
            value={mailingAddress.pincode}
            onChange={handleMailingAddressChange}
            disabled={isChecked}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PermanentAndMailingAddress;
