import React, { useEffect, useState } from 'react'
import MbbsRouteContainer from 'src/Component/grid-component/MbbsRouteContainer'
import Mainpage from "../../src/MBBSCountries/Mainpage";
import Navbar from 'src/Component/Navbar';
import MBBSAspect from "../../src/MBBSCountries/MBBSAspect";
import MBBSNepalCollege from './MBBSCollege';
import LetsGetStarted from 'src/Component/LetsGetStarted';
import Footer from 'src/Component/Footer';
import { useParams } from 'react-router';
import { AustraliaMbbsInfo, BangladeshMbbsInfo, CanadaMbbsInfo, GermanyMbbsInfo, KAZAKHSTANMbbsInfo, KYRGYZSTANMbbsInfo, NepalMbbsInfo, PhilippinesMbbsInfo, RussiaMbbsInfo, UKMbbsInfo, USAMbbsInfo, UZBEKISTANMbbsInfo } from './MbbsBannerData';
import { AustraliaAspectData, BangladeshAspectData, CanadaAspectData, GermanyAspectData, KazakhstanAspectData, KyrgyzstanAspectData, NepalAspectData, PhilippinesAspectData, RussiaAspectData, UKAspectData, USAAspectData, UzbekistanAspectData } from './mbbsAspectData';
import { AustraliaMbbsCollege, BangladeshMbbsCollege, CanadaMbbsCollege, GermanyMbbsCollege, KazakhstanMbbsCollege, KyrgyzstanMbbsCollege, NepalMbbsCollege, PhilippinesMbbsCollege, RussiaMbbsCollege, UKMbbsCollege, USAMbbsCollege, UzbekistanMbbsCollege } from './mbbsCollegeData';
import { Helmet } from 'react-helmet';


const jsonData = [
  {
    pathname: "russia",
    info: RussiaMbbsInfo,
    aspect: RussiaAspectData,
    colleges: RussiaMbbsCollege
  },
  {
    pathname: "bangladesh",
    info: BangladeshMbbsInfo,
    aspect: BangladeshAspectData,
    colleges: BangladeshMbbsCollege



  },
  {
    pathname: "canada",
    info: CanadaMbbsInfo,
    aspect: CanadaAspectData,
    colleges: CanadaMbbsCollege



  },
  {
    pathname: "germany",
    info: GermanyMbbsInfo,
    aspect: GermanyAspectData,
    colleges: GermanyMbbsCollege



  },
  {
    pathname: "philippines",
    info: PhilippinesMbbsInfo,
    aspect: PhilippinesAspectData,
    colleges: PhilippinesMbbsCollege



  },
  {
    pathname: "australia",
    info: AustraliaMbbsInfo,
    aspect: AustraliaAspectData,
    colleges: AustraliaMbbsCollege



  },
  {
    pathname: "nepal",
    info: NepalMbbsInfo,
    aspect: NepalAspectData,
    colleges: NepalMbbsCollege



  },
  {
    pathname: "usa",
    info: USAMbbsInfo,
    aspect: USAAspectData,
    colleges: USAMbbsCollege


  },
  {
    pathname: "uk",
    info: UKMbbsInfo,
    aspect: UKAspectData,
    colleges: UKMbbsCollege


  },
  {
    pathname: "kazakhstan",
    info: KAZAKHSTANMbbsInfo,
    aspect: KazakhstanAspectData,
    colleges: KazakhstanMbbsCollege


  },
  {
    pathname: "kyrgyzstan",
    info: KYRGYZSTANMbbsInfo,
    aspect: KyrgyzstanAspectData,
    colleges: KyrgyzstanMbbsCollege


  },
  {
    pathname: "uzbekistan",
    info: UZBEKISTANMbbsInfo,
    aspect: UzbekistanAspectData,
    colleges: UzbekistanMbbsCollege


  },
];

const MbbsCountryMainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const { pageName } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const currentPageData = jsonData.find((data) => data.pathname === pageName);
    setPageData(currentPageData);
    console.log(currentPageData);
  }, [pageName]);

  return (
    <div>
    <Helmet>
    <title>Takshasheela Institute: MBBS Abroad Expert</title>
    <meta name="description" content="Secure your MBBS admission abroad with Takshasheela Institute. Get personalized support, find top medical universities, and achieve your medical career goals." />
    <meta name="keywords" content="mbbs in russia for indian students, lowest fee structure for mbbs in russia, Study MBBS Abroad" />
  </Helmet>
      <Navbar />
      <MbbsRouteContainer />
      <Mainpage data={pageData?.info} />
      <MBBSAspect data={pageData?.aspect} />
      <MBBSNepalCollege data={pageData?.colleges} />
      <LetsGetStarted top="20px" bottom="20px" />
      <Footer />
    </div>
  )
}

export default MbbsCountryMainPage
