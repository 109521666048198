import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Step, StepLabel, Stepper } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import DashboardEnrollmentForm from './DashboardEnrollmentForm';
import DashboardEnrollmentForm2 from './DashboardEnrollmentForm2';
import EnrollmentSuccessModal from './EnrollmentSuccessfullModel';
// import EnrollmentSuccessModal from './EnrollmentSuccessModal'; // Import the modal component

const steps = ['Personal Information', 'Additional Information'];

const DashboardEnrollmentStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  // const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Show modal instead of navigating
      setModalOpen(true);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ width: '100%', paddingTop: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '50%', paddingTop: '20px' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: 'visible' }}>
              <CardContent>
                {activeStep === 0 && <DashboardEnrollmentForm />}
                {activeStep === 1 && <DashboardEnrollmentForm2 />}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3, gap: 5 }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button variant="contained" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Render the success modal */}
      <EnrollmentSuccessModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default DashboardEnrollmentStepper;
