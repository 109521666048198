import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Footer from 'src/Component/Footer';
import Navbar from 'src/Component/Navbar';
import b1 from '../../asset/selectcourse/b1.png'
import b2 from '../../asset/selectcourse/b2.png'
import b3 from '../../asset/selectcourse/b3.png'
import b4 from '../../asset/selectcourse/b4.png'
import b5 from '../../asset/selectcourse/b5.png'
import b6 from '../../asset/selectcourse/b6.png'
import b7 from '../../asset/selectcourse/b7.png'
import b8 from '../../asset/selectcourse/b8.png'
import b9 from '../../asset/selectcourse/b9.png'
import { useNavigate } from 'react-router';
import BackRouteContainer from 'src/Component/grid-component/BackButtonComponent';
import logo from '../../asset/backcourse.png'

// Sample data for universities
const universities = [
    { id: 1, name: 'Engineering', image: b1, path: "/study-abroad/courses/enginnering" },
    { id: 2, name: 'Computer Science & Information Technology', image: b2, path: "/study-abroad/courses/csit" },
    { id: 3, name: 'Health Science and Medicine', image: b3, path: "/study-abroad/courses/healthscience" },
    { id: 4, name: 'Social Science', image: b4, path: "/study-abroad/courses/socialscience" },
    { id: 5, name: 'Bussiness Management', image: b5, path: "/study-abroad/courses/bussinessmanagement" },
    { id: 6, name: 'Physical & Life Science', image: b6, path: "/study-abroad/courses/physicalscience" },
    { id: 7, name: 'Fine & Applied Art', image: b7, path: "/study-abroad/courses/fineart" },
    { id: 8, name: 'Communication & Journalism', image: b8, path: "/study-abroad/courses/journalism" },
    { id: 9, name: 'Designing', image: b9, path: "/study-abroad/courses/designing" },

];

const SelectCourses = () => {
    const navigate = useNavigate();
    return (
        <Box>
            <Navbar />
            <BackRouteContainer path={"/study-abroad"} title={"Study Abroad Main Page"} logo={logo} />
            <Container sx={{ padding: '40px' }}>
                <Grid container spacing={4}>
                    {universities?.map((university) => (
                        <Grid item key={university?.id} xs={12} sm={6} md={4} lg={4}>
                            <Box
                                sx={{
                                    height: { lg: '300px', xs: '300px', sm: "300px", md: "350px" },
                                    cursor: 'pointer',
                                    border: '1px solid #00999E',
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    width: { lg: "350px", md: "320px", xs: "300px" },
                                    display: 'flex',
                                    boxShadow: 4,
                                    flexDirection: 'column'
                                }}
                                onClick={() => {
                                    navigate(university?.path);
                                }}
                            >
                                <img src={university?.image} alt={university?.name} width={'100%'} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '20px 10px'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: '550', textAlign: "center" }}>
                                        {university?.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Box sx={{ mb: "20px" }}>

                <BackRouteContainer path={"/study-abroad"} title={"Study Abroad Main Page"} logo={logo} />
            </Box>

            <Footer />
        </Box>
    );
};

export default SelectCourses;
