import React, { useState } from "react";
import FlipCard from "src/Component/card-components/FlipCard";
import Header from "src/Component/content-component/Header";
import immigrationEligibilityData from "src/json/immigrationEligibilityData";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import FormComponent from "src/Component/form-component/Form";
import { Icon } from '@iconify/react';
import Cta from "src/Component/card-components/Cta";

const EligibilityCriteria = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Box sx={{ padding: "50px 0" }} id="indiaCriteria">
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 50px",
            }}
          >
            <Header
              content={"Eligibility Criteria"}
              textAlign={"center"}
            />
          </Box>

          <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 14, lg: 16, xl: 16 }} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {immigrationEligibilityData?.map((eligibility, index) => {
              return (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={3} xl={3} key={index} sx={{ textAlign: "-webkit-center" }}>
                    <FlipCard data={eligibility} height="270px" width="220px" isFlip={false} isHover={true} />
                  </Grid>
                </>
              );
            })}
          </Grid>

          <Box sx={{ mt: { xs: 2, sm: 8, textAlign: 'center' } }}>
            <Cta title={"BOOK A COUNSELLING SECTION"} />

          </Box>
        </Container>
      
      </Box>
    </>
  );
};

export default EligibilityCriteria;
