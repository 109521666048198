import uk from "../asset/ukflag.jpg";
import canada from "../asset/canadaflag.jpg";
import Australia from "../asset/australiaflag.jpg";

const CountriesForImmigration = [
  {
    title: "Canada",
    items: [
      "1.6 million people of Indian origin population.",
      "In 2019, over 85,000 Indian citizens became permanent residents.",
    ],
    Image: canada,
    path: "/immigration/canada"
  },
  {
    title: "United Kingdom",
    items: [
      "Over 1.4 million of Indian Nationals.",
      "Working across IT, healthcare, finance & engineering sector.",
    ],
    Image: uk,
    path: "/immigration/uk"

  },
  {
    title: "Australia",
    items: [
      "Over 0.7 million Indian nationals.",
      "1Lac+ Indian students enrolled in different educational institutions.",
    ],
    Image: Australia,
    path: "/immigration/australia"
  },
];

export default CountriesForImmigration;
