import React, { useState, useEffect } from "react";
import background from "../asset/Testimonials.svg";
import ReviewCard from "./ReviewCard";
import Slider from "react-slick";
import { Box, Stack, Typography, createTheme } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import "../index.css";
import icon2 from "../asset/p2.png";
import icon4 from "../asset/p4.png";
import icon5 from "../asset/p1.png";
import icon7 from "../asset/p3.png";
import icon8 from "../asset/p8.png";
import icon9 from "../asset/p9.png";
import Header from "./content-component/Header";


const Testimonial = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));



  const testimonialCard = [

    {
      id: 1,
      icon: icon2,
      name: "Pallavi Majumder",
      about: "Doctor of Liberal Arts",
      university: "Hungarian University, Budapest",
      review:
        "With Taksheela’s support, I have received a doctoral position from Hungarian University of Fine Arts, Budapest with full scholarship from Hungarian Government & UGC with a stipend for the entire course of study of the PhD programme.",
    },
    {
      id: 2,
      icon: icon4,
      name: "Ankita Shaw",
      about: "M.Sc. In Engineering",
      university: "National University of Singapore",
      review:
        "Secured full scholarship admission for Masters, all thanks to Team Taksheela! Taksheela stands out by providing enduring support until success is achieved.",
    },
    {
      id: 3,
      icon: icon5,
      name: "Adrija Dutta",
      about: "MSc Environment and Development",
      university: "London School of Economics",
      review:
        "The methodical arrangement of necessary data, step by step immaculate planning and seamless coordination between the faculty and me, helped me get offer from the top ranked institution in social sciences - LSE. ",
    },

    {
      id: 4,
      icon: icon8,
      name: "Yugam Surana",
      about: "Bachelors in EE & Computer Science",
      university: "University of California, Berkeley",
      review:
        "My journey with Taksheela commenced with SAT preparations until finally I scored 1520 and with constant counseling at TIE, I could get into my dream University - UC,Berkeley. ",
    },
    {
      id: 5,
      icon: icon9,
      name: "Suvarup Ghosh",
      about: "MSc in Computer Science",
      university: "Ilmenau University of Technology",
      review:
        "Admitted to TU Ilmenau for my Master's in Computer Science in Germany, thanks to Taksheela Institute. Their unwavering support, especially in addressing problems, was crucial. Grateful for their exceptional effort and cooperation. ",
    },
    {
      id: 6,
      icon: icon7,
      name: "Hrishi Khemka",
      about: "BSc Business Administration",
      university: "Boston University",
      review:
        "Taksheela's tirelessly assisted with even the smallest queries, guided me through the application process, and meticulously reviewed my essays. Their commitment to highlighting my interests and refining my presentation, ensured I submitted my best work.",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: isSmallScreen ? 1 : 2,
    slidesToScroll: 1,
    arrows: !isLargeScreen ? true : false,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const containerStyle = {
    backgroundImage: `url("${background}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: { xs: "40px 0", sm: "40px 0", md: "40px 0", lg: "40px 0" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: '20px',

  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Stack sx={containerStyle}>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            gap: ".5rem",
          }}

        >
          <Box sx={{
            marginBottom: {
              xs: "15px",
              sm: "15px",
              md: "25px",
              lg: "25px",
            },
          }}>

            <Header
              color="#E0F2F3"
              textAlign="center"
              content="Triump Tales"
            />
          </Box>
          {/* <Typography
            color="#E0F2F3"
            variant="h4"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "32px", lg: "36px" },
              fontWeight: 600,
              marginBottom: {
                xs: "15px",
                sm: "15px",
                md: "25px",
                lg: "25px",
              },
            }}
          >
            ~Triumph Tales~
          </Typography> */}
        </Stack>
        <Container
          sc={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        // maxWidth={isLargeScreen?"xl":"lg"}
        >
          <Slider
            {...settings}
            style={{
              dipslay: "flex",
              gap: "2rem",
              marginLeft: {
                xm: "-40px",
                md: "250px",
                xs: "-40px",
                lg: "250px",
              },
              marginRight: {
                xm: "-40px",
                md: "-250px",
                xs: "-40px",
                lg: "-250px",
              },
              padding: { xm: "-40px", md: "290px", xs: "-40px", lg: "250px" },
              justifyContent: "center",
            }}
          >
            {testimonialCard.map((elem, index) => (
              <Stack>
                <ReviewCard
                  key={elem.id}
                  id={elem.id}
                  name={elem.name}
                  icon={elem.icon}
                  about={elem.about}
                  university={elem.university}
                  review={elem.review}
                />
              </Stack>
            ))}
          </Slider>
        </Container>
      </Stack>
    </>
  );
};

export default Testimonial;
