// import { Icon } from '@iconify/react';
import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import Card from 'src/Component/card-components/Card';
// import ButtonComponent from 'src/Component/content-component/ButtonComponent';
import Header from 'src/Component/content-component/Header';

const StudyUniversities = ({ data }) => {
    return (

        <Box sx={{ backgroundColor: "#effdff", padding: { xs: "10px 0", md: "50px 0" } }} id="colleges">
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={data?.heading}
                        textAlign={"center"}
                    />
                </Box>

                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: data?.content?.length <= 8 ? 12 : 15, lg: data?.content?.length <= 8 ? 12 : 15 }} sx={{ display: "flex", justifyContent: "center" }}
                >
                    {data?.content?.map((college, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    key={index}
                                    sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Card
                                        data={college}
                                        borderRadius="10%"
                                        hoverHeight={"24%"}
                                        initialheight={"24%"}
                                        width="150px"
                                        height="150px"
                                        listStyle={"none"}
                                        fontSize={"18px"}
                                    />
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "40px 0",
                    }}
                >
                    <ButtonComponent
                        height={"50px"}
                        width={"200px"}
                        text="View more"
                        icon={<Icon icon="fluent:arrow-right-28-regular" />}

                    // href="/mbbs/india"
                    />
                </Box> */}
            </Container>

        </Box>

    )
}

export default StudyUniversities
