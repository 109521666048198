import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Footer from 'src/Component/Footer'
import Navbar from 'src/Component/Navbar'
import Universitybanner from './University/Universitybanner';
import { CaliforniaBanner, ColoradoBanner, MelbourneBanner, UtahBanner, WisconsinBanner, YeshivaBanner } from './UniversityJson/UniversityBanner';
import LetsGetStarted from 'src/Component/LetsGetStarted';
import UniversityInfo from './University/UniversityInfo';
import { CaliforniaInfo, ColoradoInfo, MelbourneInfo, UtahInfo, WisconsinInfo, YeshivaInfo } from './UniversityJson/UniversityInfoData';
import WhyChooseUniversity from './University/WhyChooseUniversity';
import { CaliforniaChoose, ColoradoChoose, MelbourneChoose, UtahChoose, WisconsinChoose, YeshivaChoose } from './UniversityJson/UniversityChoose';
import UniversityFeeStructure from './University/UniversityFeeStructure';
import { CaliforniaFeeStructure, ColoradoFeeStructure, MelbourneFeeStructure, UtahFeeStructure, WisconsinFeeStructure, YeshivaFeeStructure } from './UniversityJson/UniversityFeeStructure';
import UniversityScholarship from './University/UniversityScholarship';
import { CaliforniaScholarships, ColoradoScholarships, MelbourneScholarships, UtahScholarships, WisconsinScholarships, YeshivaScholarships } from './UniversityJson/UniversityScholarship';
import UniversityKnowMore from './University/UniversityKnowMore';
import { CaliforniaKnowMore, ColoradoKnowMore, MelbourneKnowMore, UtahKnowMore, WisconsinKnowMore, YeshivaKnowMore } from './UniversityJson/UniversityKnowMore';
import UniversityRecruiters from './University/UniversityRecruiters';
import { CaliforniaRecruiter, ColoradoRecruiter, MelbourneRecruiter, UtahRecruiter, YeshivaRecruiter, wisconsinRecruiter } from './UniversityJson/UniversityRecruiters';
import BackRouteContainer from 'src/Component/grid-component/BackButtonComponent';
import logo from '../asset/backuniversity.png'

const jsonData = [
    {
        pathname: "melbourne",
        banner: MelbourneBanner,
        info: MelbourneInfo,
        choose: MelbourneChoose,
        fee: MelbourneFeeStructure,
        scholarship: MelbourneScholarships,
        know: MelbourneKnowMore,
        recruiters: MelbourneRecruiter
    },
    {
        pathname: "california",
        banner: CaliforniaBanner,
        info: CaliforniaInfo,
        choose: CaliforniaChoose,
        fee: CaliforniaFeeStructure,
        scholarship: CaliforniaScholarships,
        know: CaliforniaKnowMore,
        recruiters: CaliforniaRecruiter
    },
    {
        pathname: "wisconsin",
        banner: WisconsinBanner,
        info: WisconsinInfo,
        choose: WisconsinChoose,
        fee: WisconsinFeeStructure,
        scholarship: WisconsinScholarships,
        know: WisconsinKnowMore,
        recruiters: wisconsinRecruiter
    },
    {
        pathname: "yeshiva",
        banner: YeshivaBanner,
        info: YeshivaInfo,
        choose: YeshivaChoose,
        fee: YeshivaFeeStructure,
        scholarship: YeshivaScholarships,
        know: YeshivaKnowMore,
        recruiters: YeshivaRecruiter
    },
    {
        pathname: "utah",
        banner: UtahBanner,
        info: UtahInfo,
        choose: UtahChoose,
        fee: UtahFeeStructure,
        scholarship: UtahScholarships,
        know: UtahKnowMore,
        recruiters: UtahRecruiter
    },
    {
        pathname: "colorado",
        banner: ColoradoBanner,
        info: ColoradoInfo,
        choose: ColoradoChoose,
        fee: ColoradoFeeStructure,
        scholarship: ColoradoScholarships,
        know: ColoradoKnowMore,
        recruiters: ColoradoRecruiter
    },

];
const UniversityPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const { pageName } = useParams();
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        const currentPageData = jsonData.find((data) => data?.pathname === pageName);
        setPageData(currentPageData);
        console.log(currentPageData);
    }, [pageName]);
    return (
        <>
            <Navbar />
            <Universitybanner data={pageData?.banner} />
            <BackRouteContainer logo={logo} path={"/study-abroad/universities"} title={"Select University Page"} />
            <UniversityInfo data={pageData?.info} />
            <WhyChooseUniversity data={pageData?.choose} />
            <UniversityFeeStructure data={pageData?.fee} />
            <UniversityScholarship data={pageData?.scholarship} />
            <UniversityRecruiters data={pageData?.recruiters} />
            <UniversityKnowMore data={pageData?.know} />
            <BackRouteContainer logo={logo} path={"/study-abroad/universities"} title={"Select University Page"} />
            <LetsGetStarted />
            <Footer />
        </>
    )
}

export default UniversityPage
