import img from "../../../asset/philadelphia.svg";
import usa2 from "../../../asset/CountryPlaces/us/boston.png";
import usa3 from "../../../asset/CountryPlaces/us/newyork.png";
import usa4 from "../../../asset/CountryPlaces/us/losangeles.png";
import usa5 from "../../../asset/CountryPlaces/us/sanfrancisco.png";
import usa6 from "../../../asset/CountryPlaces/us/washington.png";
import usa7 from "../../../asset/CountryPlaces/us/chicago.png";
import uk1 from "../../../asset/CountryPlaces/uk/uk1.png";
import uk2 from "../../../asset/CountryPlaces/uk/uk2.png";
import uk3 from "../../../asset/CountryPlaces/uk/uk3.png";
import uk4 from "../../../asset/CountryPlaces/uk/uk4.png";
import uk5 from "../../../asset/CountryPlaces/uk/uk5.png";
import uk6 from "../../../asset/CountryPlaces/uk/uk6.png";
import uk7 from "../../../asset/CountryPlaces/uk/uk7.png";
import uk8 from "../../../asset/CountryPlaces/uk/uk8.png";
import ire1 from "../../../asset/CountryPlaces/ireland/ireland1.png";
import ire2 from "../../../asset/CountryPlaces/ireland/ireland2.png";
import ire3 from "../../../asset/CountryPlaces/ireland/ireland3.png";
import ire4 from "../../../asset/CountryPlaces/ireland/ireland4.png";
import ire5 from "../../../asset/CountryPlaces/ireland/ireland5.png";
import ire6 from "../../../asset/CountryPlaces/ireland/ireland6.png";
import ire7 from "../../../asset/CountryPlaces/ireland/ireland7.png";
import ire8 from "../../../asset/CountryPlaces/ireland/ireland8.png";
import can1 from "../../../asset/CountryPlaces/canada/canada1.png";
import can2 from "../../../asset/CountryPlaces/canada/canada2.png";
import can3 from "../../../asset/CountryPlaces/canada/canada3.png";
import can4 from "../../../asset/CountryPlaces/canada/canada4.png";
import can5 from "../../../asset/CountryPlaces/canada/canada5.png";
import can6 from "../../../asset/CountryPlaces/canada/canada6.png";
import can7 from "../../../asset/CountryPlaces/canada/canada7.png";
import can8 from "../../../asset/CountryPlaces/canada/canada8.png";
import ger1 from "../../../asset/CountryPlaces/germany/germany1.png";
import ger2 from "../../../asset/CountryPlaces/germany/germany2.png";
import ger3 from "../../../asset/CountryPlaces/germany/germany3.png";
import ger4 from "../../../asset/CountryPlaces/germany/germany4.png";
import ger5 from "../../../asset/CountryPlaces/germany/germany5.png";
import ger6 from "../../../asset/CountryPlaces/germany/germany6.png";
import ger7 from "../../../asset/CountryPlaces/germany/germany7.png";
import ger8 from "../../../asset/CountryPlaces/germany/germany8.png";
import aus1 from "../../../asset/CountryPlaces/australia/australia1.png";
import aus2 from "../../../asset/CountryPlaces/australia/australia2.png";
import aus3 from "../../../asset/CountryPlaces/australia/australia3.png";
import aus4 from "../../../asset/CountryPlaces/australia/australia4.png";
import aus5 from "../../../asset/CountryPlaces/australia/australia5.png";
import aus6 from "../../../asset/CountryPlaces/australia/australia6.png";
import aus7 from "../../../asset/CountryPlaces/australia/australia7.png";
import aus8 from "../../../asset/CountryPlaces/australia/australia8.png";
import sin1 from "../../../asset/CountryPlaces/singapore/singapore1.png";
import sin2 from "../../../asset/CountryPlaces/singapore/singapore2.png";
import sin3 from "../../../asset/CountryPlaces/singapore/singapore3.png";
import sin4 from "../../../asset/CountryPlaces/singapore/singapore4.png";
import sin5 from "../../../asset/CountryPlaces/singapore/singapore5.png";
import sin6 from "../../../asset/CountryPlaces/singapore/singapore6.png";
import sin7 from "../../../asset/CountryPlaces/singapore/singapore7.png";
import sin8 from "../../../asset/CountryPlaces/singapore/singapore8.png";
import ita1 from "../../../asset/CountryPlaces/Italy/italy1.png";
import ita2 from "../../../asset/CountryPlaces/Italy/italy2.png";
import ita3 from "../../../asset/CountryPlaces/Italy/italy3.png";
import ita4 from "../../../asset/CountryPlaces/Italy/italy4.png";
import ita5 from "../../../asset/CountryPlaces/Italy/italy5.png";
import ita6 from "../../../asset/CountryPlaces/Italy/italy6.png";
import ita7 from "../../../asset/CountryPlaces/Italy/italy7.png";
import ita8 from "../../../asset/CountryPlaces/Italy/italy8.png";
import fra1 from "../../../asset/CountryPlaces/france/france1.png";
import fra2 from "../../../asset/CountryPlaces/france/france2.png";
import fra3 from "../../../asset/CountryPlaces/france/france3.png";
import fra4 from "../../../asset/CountryPlaces/france/france4.png";
import fra5 from "../../../asset/CountryPlaces/france/france5.png";
import fra6 from "../../../asset/CountryPlaces/france/france6.png";
import fra7 from "../../../asset/CountryPlaces/france/france7.png";
import fra8 from "../../../asset/CountryPlaces/france/france8.png";
import dub1 from "../../../asset/CountryPlaces/dubai/dubai1.png";
import dub2 from "../../../asset/CountryPlaces/dubai/dubai2.png";
import dub3 from "../../../asset/CountryPlaces/dubai/dubai3.png";
import dub4 from "../../../asset/CountryPlaces/dubai/dubai4.png";
import dub5 from "../../../asset/CountryPlaces/dubai/dubai5.png";
import dub6 from "../../../asset/CountryPlaces/dubai/dubai6.png";
import dub7 from "../../../asset/CountryPlaces/dubai/dubai7.png";
import dub8 from "../../../asset/CountryPlaces/dubai/dubai8.png";

const USAPlace = {
  title: "Popular Places to Study in the USA",
  data: [
    {
      city: "Boston",
      img: usa2
    },
    {
      city: "New York City",
      img: usa3
    },
    {
      city: "Los Angeles",
      img: usa4
    },
    {
      city: "San Francisco Bay Area",
      img: usa5
    },
    {
      city: "Washington",
      img: usa6
    },
    {
      city: "Chicago",
      img: usa7
    },
    {
      city: "Philadelphia",
      img: img
    }
  ]
};
const UKPlace = {
  title: "Popular Places to Study in the United Kingdom",

  data: [
    {
      city: "Oxford",
      img: uk1
    },
    {
      city: "London",
      img: uk2
    },
    {
      city: "Edinburgh",
      img: uk3
    },
    {
      city: "Manchester",
      img: uk4
    },
    {
      city: "Bristol",
      img: uk5
    },
    {
      city: "Birmingham",
      img: uk6
    },
    {
      city: "Glasgow",
      img: uk7
    },
    {
      city: "Nottingham",
      img: uk8
    }
  ]
};
const IrelandPlace = {
  title: "Popular Places to Study in the Ireland",

  data: [
    {
      city: "Dublin",
      img: ire1
    },
    {
      city: "Cork",
      img: ire2
    },
    {
      city: "Galway",
      img: ire3
    },
    {
      city: "Limerick",
      img: ire4
    },
    {
      city: "Waterford",
      img: ire5
    },
    {
      city: "Maynooth",
      img: ire6
    },
    {
      city: "Dundalk",
      img: ire7
    },
    {
      city: "Athlone",
      img: ire8
    }
  ]
};
const CanadaPlace = {
  title: "Popular Places to Study in the Canada",

  data: [
    {
      city: "Toronto",
      img: can1
    },
    {
      city: "Vancouver",
      img: can2
    },
    {
      city: "Montreal",
      img: can3
    },
    {
      city: "Ottawa",
      img: can4
    },
    {
      city: "Calgary",
      img: can5
    },
    {
      city: "Edmonton",
      img: can6
    },
    {
      city: "Quebec City",
      img: can7
    },
    {
      city: "Halifax",
      img: can8
    }
  ]
};
const GermanyPlace = {
  title: "Popular Places to Study in the Germany",

  data: [
    {
      city: "Berlin",
      img: ger1
    },
    {
      city: "Munich",
      img: ger2
    },
    {
      city: "Hamburg",
      img: ger3
    },
    {
      city: "Frankfurt",
      img: ger4
    },
    {
      city: "Cologne",
      img: ger5
    },
    {
      city: "Stuttgart",
      img: ger6
    },
    {
      city: "Dusseldorf",
      img: ger7
    },
    {
      city: "Leipzig",
      img: ger8
    }
  ]
};
const AustraliaPlace = {
  title: "Popular Places to Study in the Australia",

  data: [
    {
      city: "Sydney",
      img: aus1
    },
    {
      city: "Melbourne",
      img: aus2
    },
    {
      city: "Brisbane",
      img: aus3
    },
    {
      city: "Perth",
      img: aus4
    },
    {
      city: "Adelaide",
      img: aus5
    },
    {
      city: "Canberra",
      img: aus6
    },
    {
      city: "Gold Coast",
      img: aus7
    },
    {
      city: "Hobart",
      img: aus8
    }
  ]
};
const SingaporePlace = {
  title: "Popular Places to Study in the Singapore",

  data: [
    {
      city: "Singapore City",
      img: sin1
    },
    {
      city: "Jurong",
      img: sin2
    },
    {
      city: "Tampines",
      img: sin3
    },
    {
      city: "Hougang",
      img: sin4
    },
    {
      city: "Woodlands",
      img: sin5
    },
    {
      city: "Bukit Batok",
      img: sin6
    },
    {
      city: "Bedok",
      img: sin7
    },
    {
      city: "Pasir Ris",
      img: sin8
    }
  ]
};
const ItalyPlace = {
  title: "Popular Places to Study in the Italy",

  data: [
    {
      city: "Rome",
      img: ita1
    },
    {
      city: "Milan",
      img: ita2
    },
    {
      city: "Florence",
      img: ita3
    },
    {
      city: "Venice",
      img: ita4
    },
    {
      city: "Bologna",
      img: ita5
    },
    {
      city: "Naples",
      img: ita6
    },
    {
      city: "Turin",
      img: ita7
    },
    {
      city: "Pisa",
      img: ita8
    }
  ]
};
const FrancePlace = {
  title: "Popular Places to Study in the France",

  data: [
    {
      city: "Paris",
      img: fra1
    },
    {
      city: "Lyon",
      img: fra2
    },
    {
      city: "Marseille",
      img: fra3
    },
    {
      city: "Toulouse",
      img: fra4
    },
    {
      city: "Bordeaux",
      img: fra5
    },
    {
      city: "Nice",
      img: fra6
    },
    {
      city: "Lille",
      img: fra7
    },
    {
      city: "Strasbourg",
      img: fra8
    }
  ]
};
const DubaiPlace = {
  title: "Popular Places to Study in the Dubai",

  data: [
    {
      city: "Dubai City",
      img: dub1
    },
    {
      city: "Abu Dhabi",
      img: dub2
    },
    {
      city: "Sharjah",
      img: dub3
    },
    {
      city: "Al Ain",
      img: dub4
    },
    {
      city: "Ajman",
      img: dub5
    },
    {
      city: "Ras Al Khaimah",
      img: dub6
    },
    {
      city: "Fujairah",
      img: dub7
    },
    {
      city: "Umm Al Quwain",
      img: dub8
    }
  ]
};

export {
  USAPlace,
  UKPlace,
  IrelandPlace,
  CanadaPlace,
  GermanyPlace,
  AustraliaPlace,
  SingaporePlace,
  ItalyPlace,
  FrancePlace,
  DubaiPlace
};
