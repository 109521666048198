import Header from "src/Component/content-component/Header";
import ParaData from "src/Component/content-component/ParaData";
import ContentContainer from "src/Component/grid-component/ContentContainer";
import ImageContainer from "src/Component/grid-component/ImageContainer";
import MainContainer from "src/Component/grid-component/MainContainer";
import mbbsImg1 from "../asset/whatismbbs.png";
const WhatisMbbs = () => {
  return (
    <>
      <MainContainer
        com1={
          <ContentContainer
            Header={
              <Header content={"What is MBBS?"} />
            }
            Content={
              <ParaData
                color={"#525560"}
                fontSize={"16px"}
                content={
                  "MBBS, or Bachelor of Medicine, Bachelor of Surgery, is an undergraduate degree program that prepares students for a career in medicine and surgery. It is a rigorous and rewarding journey that equips students with the medical knowledge, clinical skills, and ethical foundation necessary to become competent and compassionate physicians."
                }
              />
            }
          />
        }
        com2={<ImageContainer img={mbbsImg1} />}
      />
    </>
  );
};
export default WhatisMbbs;
