import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";

const validationSchema = yup.object({
  academicLevel: yup.string().required("Academics is required"),
  marks: yup
    .string()
    .required("Marks/CGPA is required")
    .matches(
      /^([1-9][0-9]?(\.\d{1,2})?|100(\.0{1,2})?)$/,
      "Invalid percentage"
    ),
  passingYear: yup
    .string()
    .required("Expected/Year of Passing is required")
    .matches(/^(19|20)\d{2}$/, "Invalid year"),
  degree: yup.string().required("Degree is required"),
  college: yup.string().required("College is required"),
  university: yup.string().required("University is required"),
});

const AcademicBackground = ({ sendDataToParent }) => {
  const [academicData, setAcademicData] = useState([]);

 useEffect(() => {
  console.log("Academic Data:", academicData);
  }, [academicData]);

  const formik = useFormik({
    initialValues: {
      academicLevel: "",
      marks: "",
      passingYear: "",
      degree: "",
      college: "",
      university: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleAdd(values);
      sendDataToParent(academicData);
    },
  });

  const handleAdd = (newAcademics) => {
    // Check if the current academic data already exists in the array
    const isDuplicate = academicData.some((item) =>
      Object.keys(item).every((key) => item[key] === newAcademics[key])
    );

    // If it's not a duplicate, add it to the array
    if (!isDuplicate) {
      setAcademicData([...academicData, newAcademics]);
      formik.resetForm();
    } else {
      alert("Duplicate entry detected!");
    }
  };

  const handleDelete = (index) => {
    const updatedData = academicData.filter((_, i) => i !== index);
    setAcademicData(updatedData);
  };

 
 
  return (
    <div>
      <Box sx={{ paddingTop: "20px"}}>
        
        <Typography varient= "h4" sx={{fontSize:{xs:"20px",sm:'20px',md:'20px',lg:'20px'}, paddingBottom:'10px'}}>ACADEMIC BACKGROUND</Typography>
      </Box>
      <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Academics
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Academics"
                {...formik.getFieldProps("academicLevel")}
              >
                <MenuItem value="X">X</MenuItem>
                <MenuItem value="XII">XII</MenuItem>
                <MenuItem value="Graduation">Graduation</MenuItem>
                <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
              </Select>
              {formik.touched.academicLevel && formik.errors.academicLevel ? (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {formik.errors.academicLevel}
                </Typography>
              ) : null}
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="%Marks/CGPA"
            name="marks"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("marks")}
          />
          {formik.touched.marks && formik.errors.marks ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.marks}
            </Typography>
          ) : null}
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="Expected/Year of Passing"
            name="passingYear"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("passingYear")}
          />
          {formik.touched.passingYear && formik.errors.passingYear ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.passingYear}
            </Typography>
          ) : null}
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="Degree"
            name="degree"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("degree")}
          />
          {formik.touched.degree && formik.errors.degree ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.degree}
            </Typography>
          ) : null}
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="College"
            name="college"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("college")}
          />
          {formik.touched.college && formik.errors.college ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.college}
            </Typography>
          ) : null}
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="University"
            name="university"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("university")}
          />
          {formik.touched.university && formik.errors.university ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.university}
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "14px",
        }}
      >
        <Button variant="contained" onClick={formik.handleSubmit}>
          Add
        </Button>
      </Box>

      {academicData.length > 0 && (
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Academics</TableCell>
                <TableCell>%Marks/CGPA</TableCell>
                <TableCell>Expected/Year of Passing</TableCell>
                <TableCell>Degree</TableCell>
                <TableCell>College</TableCell>
                <TableCell>University</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {academicData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.academicLevel}</TableCell>
                  <TableCell>{row.marks}</TableCell>
                  <TableCell>{row.passingYear}</TableCell>
                  <TableCell>{row.degree}</TableCell>
                  <TableCell>{row.college}</TableCell>
                  <TableCell>{row.university}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AcademicBackground;

