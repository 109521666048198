import b1 from '../../asset/immigrationbenefit/benefit1.png'
import b2 from '../../asset/immigrationbenefit/benefit2.png'
import b3 from '../../asset/immigrationbenefit/benefit3.png'
import b4 from '../../asset/immigrationbenefit/benefit4.png'
import b5 from '../../asset/immigrationbenefit/benefit5.png'
import b6 from '../../asset/immigrationbenefit/benefit6.png'
import b7 from '../../asset/immigrationbenefit/benefit7.png'
import b8 from '../../asset/immigrationbenefit/benefit8.png'


const CanadaImmigrationBenefit = [

    {
        title: "Economic Growth",
        img: b1,
    },
    {
        title: "Cultural Enrichment",
        img: b2,
    },
    {
        title: "Demographic Stability",
        img: b3,
    },
    {
        title: "Global Talent Attraction",
        img: b4,
    },
    {
        title: "Innovation and Entrepreneurship",
        img: b5,
    },
    {
        title: "Social and Community Contributions",
        img: b6,
    },


];
const UkImmigrationBenefit = [

    {
        title: "Quality Healthcare",
        img: b1,
    },
    {
        title: "World-Class Education",
        img: b2,
    },
    {
        title: "Cultural Richness",
        img: b3,
    },
    {
        title: "Global Connectivity",
        img: b4,
    },
    {
        title: "Social Services",
        img: b5,
    },
    {
        title: "English Proficiency",
        img: b6,
    },
    {
        title: "Legal Protections",
        img: b7,
    },
    {
        title: "Financial Stability",
        img: b8,
    },

];
const AustraliaImmigrationBenefit = [

    {
        title: "Cultural Diversity and Enrichment",
        img: b1,
    },
    {
        title: "Economic Growth and Prosperity",
        img: b2,
    },

    {
        title: "Social Contribution and Community Development",
        img: b3,
    },
    {
        title: "Global Connectivity and Networks",
        img: b4,
    },
    {
        title: "Aging Population and Workforce Sustainability",
        img: b5,
    },
    {
        title: "Increased Consumer Base and Market Growth",
        img: b6,
    },
    {
        title: "Investment and Business Opportunities",
        img: b7,
    },
    {
        title: "Humanitarian Assistance and Refugee Resettlement",
        img: b8,
    },

];

export { CanadaImmigrationBenefit, UkImmigrationBenefit, AustraliaImmigrationBenefit }