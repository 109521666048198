import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import home1 from "../asset/dasboardhome1.png";
import home2 from "../asset/dashboardhome2.png";
import MainHeader from "src/Component/content-component/MainHeader";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { Icon } from "@iconify/react";
import Header from "src/Component/content-component/Header";
import Slider from "react-slick";
import NewsCard from "src/Component/card-components/NewsCard";
import DashBoardSlider from "./json/DashboardHomeSliderData";
const username = sessionStorage.getItem("username");

const DashboardHome = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrow: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 3000,
          autoplaySpeed: 2000
        }
      }
    ]
  };
  return (
    <Box sx={{ margin: "0px 20px", display: "flex", flexDirection: "column" }}>
      <Grid container sx={{ height: "500px" }}>
        <Grid
          item
          md={6}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MainHeader content={"WELCOME"} />
            <MainHeader content={username} color={"#454545"} />
            <Typography
              sx={{
                py: "20px",
                color: "#757575",
                fontWeight: "550",
                fontSize: "24px"
              }}
            >
              We’re Excited To Help You On Your Journey Towards Academic Success
            </Typography>
            <Typography
              sx={{
                pb: "20px",
                color: "#00999E",
                fontWeight: "500",
                fontSize: "24px"
              }}
            >
              Please complete the enrollment form
            </Typography>
            <ButtonComponent
              text={"Enroll Now"}
              // href={"enrollment-form"}
              icon={
                <Icon icon="eva:arrow-forward-fill" style={{ color: "#fff" }} />
              }
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              width: { lg: "560px", md: "350px", sm: "450px", xs: "280px" }
            }}
          >
            <Box
              sx={{
                position: "relative"
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  right: "0px",
                  width: "90%",
                  height: {
                    lg: "500px",
                    md: "450px",
                    xs: "350px",
                    sm: "400px"
                  },
                  bgcolor: "#a5d5d5",
                  borderRadius: "0px 0px 10px 10px"
                }}
              ></Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "0px",
                  width: { lg: "360px", sm: "250px", md: "230px", xs: "230px" },
                  height: { lg: "215px", sm: "200px", xs: "200px" },
                  marginTop: {
                    lg: "200px",
                    md: "160px",
                    sm: "150px",
                    xs: "200px"
                  },
                  borderRadius: "0px 0px 10px 10px"
                }}
              >
                <img
                  src={home1}
                  alt="home1"
                  width={"100%"}
                  style={{ borderRadius: "40px 0px 40px 0px" }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: "0px",
                  width: { lg: "360px", sm: "250px", md: "230px", xs: "230px" },
                  height: { lg: "215px", sm: "200px", xs: "200px" },
                  marginTop: "50px",
                  borderRadius: "0px 0px 10px 10px"
                }}
              >
                <img
                  src={home2}
                  alt="home2"
                  width={"100%"}
                  style={{ borderRadius: "40px 0px 40px 0px" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* {DashboardCardData?.map((item) => (
          <Grid item md={3} md={6} xs={12}>
            <DashboardCard data={item} />
          </Grid>
        ))} */}
      </Grid>

      <Box
        sx={{
          padding: "20px 0"
        }}
      >
        <Header content={"Recently Brought Product"} />
        <Slider {...settings} sx={{ pt: "20px" }}>
          {DashBoardSlider?.map((item, index) => {
            return (
              <Box sx={{ textAlign: "-webkit-center", pt: "40px" }}>
                <Box sx={{ width: "300px" }}>
                  <NewsCard imageheight={"250"} key={index} data={item} />
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
      <Box
        sx={{
          padding: "20px 0"
        }}
      >
        <Header content={"Our Best Sellers"} />
        <Slider {...settings} sx={{ pt: "20px" }}>
          {DashBoardSlider?.map((item, index) => {
            return (
              <Box sx={{ textAlign: "-webkit-center", pt: "40px" }}>
                <Box>
                  <NewsCard
                    imagewidth={"300px"}
                    imageheight={"250px"}
                    key={index}
                    data={item}
                  />
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default DashboardHome;
