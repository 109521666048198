import * as React from "react";
import { Box, Container } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CostofStudy from "../StudyAbroadMainComponents/Cost_of_Study";
import PostStudy from "./PostStudy";
import VisaEnlightenment from "./VisaEnlightenment";
import Popularcard from "./Popular_card";
import Intakes from "./Intakes";

export default function ScrollableTabsButtonPrevent({ data1, data2, data3, data4, data5 }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box pt={6} pb={8} sx={{ backgroundColor: "#ffffff" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: "15px",
                bgcolor: "background.paper",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-evenly", // Center horizontally
                boxShadow: "0px 4px 8px rgba(0.3, 0.3, 0.3, 0.3)",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                textTransform="none"
                scrollButtons={true}
                aria-label="scrollable prevent tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "blue", // Change background color of the selected tab
                    height: 0, // Remove the underline
                  },
                }}
                sx={{
                  textTransform: "none",
                  "& .MuiTabs-flexContainer": {
                    display: "flex",
                    justifyContent: "space-around",
                  },
                }}
              >
                <Tab
                  label="Popular Programs"
                  sx={{
                    color: "#00999E",
                    "&.Mui-selected": {
                      backgroundColor: "#00999E",
                      color: "white", // Change background color of the selected tab
                    },
                  }}
                />
                <Tab
                  label="Intakes"
                  sx={{
                    color: "#00999E",
                    "&.Mui-selected": {
                      backgroundColor: "#00999E",
                      color: "white", // Change background color of the selected tab
                    },
                  }}
                />
                <Tab
                  label="Cost of Education"
                  sx={{
                    color: "#00999E",
                    "&.Mui-selected": {
                      backgroundColor: "#00999E",
                      color: "white", // Change background color of the selected tab
                    },
                  }}
                />
                <Tab
                  label="Post study"
                  sx={{
                    color: "#00999E",
                    "&.Mui-selected": {
                      backgroundColor: "#00999E",
                      color: "white", // Change background color of the selected tab
                    },
                  }}
                />
                <Tab
                  label="Visa Enlightenment"
                  sx={{
                    color: "#00999E",
                    "&.Mui-selected": {
                      backgroundColor: "#00999E",
                      color: "white", // Change background color of the selected tab
                    },
                  }}
                />
              </Tabs>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box pt={6} pb={8} sx={{ backgroundColor: "#ffffff" }}>
        {/* <Container maxWidth="lg"> */}
        {value === 0 && <Popularcard data={data1} />}
        {value === 1 && <Intakes data={data2} />}
        {value === 2 && <CostofStudy data={data3} />}
        {value === 3 && <PostStudy data={data4} />}
        {value === 4 && <VisaEnlightenment data={data5} />}
        {/* </Container> */}
      </Box>
    </>
  );
}
