import { Box, Container, Grid } from "@mui/material";
import FlipCard from "src/Component/card-components/FlipCard";
import Header from "src/Component/content-component/Header";
import eligibilityAbroadData from "src/json/eligibilityAbroadData";
const EligibilityAbroadData = () => {
    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", padding: "20px 0", display: 'flex', justifyContent: 'center' }} id="abroadCriteria">
                <Container maxWidth="lg">
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}>

                        <Header content={"Eligibility Criteria for MBBS Abroad"} textAlign={"center"}  />
                    </Box>

                    <Grid container spacing={3} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {eligibilityAbroadData?.map((eligibility, index) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: '-webkit-center' }}>
                                        <FlipCard data={eligibility} />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
export default EligibilityAbroadData;
