import { Box, Container, Grid } from "@mui/material";
import Header from "src/Component/content-component/Header";
import TestCard from "src/Component/card-components/OurTestCard";
import OurTestData from "src/json/OurTestTitle";
const OurTest = () => {
    return (
        <>
            <Box sx={{ backgroundColor: "#fFFfFF", padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" } }}>
                <Container >
                    <Box
                        sx={{

                            margin: "30px 0px",
                        }}
                    >
                        <Header

                            content={"Resource Hub"} textAlign={{ md: "center", xs: "center" }}
                        />


                    </Box>
                    <Grid container spacing={3} sx={{ justifyContent: "center" }}>
                        {OurTestData?.map((test, index) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        key={index}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <TestCard data={test} />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
export default OurTest;
