//melbourneimages
import m1 from '../../asset/melbourne/1.png'
import m2 from '../../asset/melbourne/2.png'
import m3 from '../../asset/melbourne/3.png'
import m4 from '../../asset/melbourne/4.png'
import m5 from '../../asset/melbourne/5.png'
import m6 from '../../asset/melbourne/6.png'
import m7 from '../../asset/melbourne/7.png'
import m8 from '../../asset/melbourne/8.png'
import m9 from '../../asset/melbourne/9.png'
import m10 from '../../asset/melbourne/10.png'
//californiaImages
import c1 from '../../asset/california/1.png'
import c2 from '../../asset/california/2.png'
import c3 from '../../asset/california/3.png'
import c4 from '../../asset/california/4.png'
import c5 from '../../asset/california/5.png'
import c6 from '../../asset/california/6.png'
import c7 from '../../asset/california/7.png'
import c8 from '../../asset/california/8.png'
import c9 from '../../asset/california/9.png'
import c10 from '../../asset/california/10.png'
//wisconsin
import w1 from '../../asset/wisconsin/1.png'
import w2 from '../../asset/wisconsin/2.png'
import w3 from '../../asset/wisconsin/3.png'
import w4 from '../../asset/wisconsin/4.png'
import w5 from '../../asset/wisconsin/5.png'
import w6 from '../../asset/wisconsin/6.png'
import w7 from '../../asset/wisconsin/7.png'

import w11 from '../../asset/wisconsin/11.png'
import w12 from '../../asset/wisconsin/12.png'
import w13 from '../../asset/wisconsin/13.png'

//yeshivaimages
import y1 from '../../asset/yeshiva/1.png'
import y2 from '../../asset/yeshiva/2.png'
import y3 from '../../asset/yeshiva/3.png'
import y4 from '../../asset/yeshiva/4.png'
import y5 from '../../asset/yeshiva/5.png'
import y6 from '../../asset/yeshiva/6.png'
import y7 from '../../asset/yeshiva/7.png'
import y8 from '../../asset/yeshiva/8.png'
import y9 from '../../asset/yeshiva/9.png'
import y10 from '../../asset/yeshiva/10.png'

//UtahImages
import u1 from '../../asset/utah/1.png'
import u2 from '../../asset/utah/2.png'
import u3 from '../../asset/utah/3.png'
import u4 from '../../asset/utah/4.png'
import u5 from '../../asset/utah/5.png'
import u6 from '../../asset/utah/6.png'
import u7 from '../../asset/utah/7.png'
import u8 from '../../asset/utah/8.png'
import u9 from '../../asset/utah/9.png'
import u10 from '../../asset/utah/10.png'
//ColoradoImages
import co1 from '../../asset/colorado/1.png'
import co2 from '../../asset/colorado/2.png'
import co3 from '../../asset/colorado/3.png'
import co4 from '../../asset/colorado/4.png'
import co5 from '../../asset/colorado/5.png'
import co6 from '../../asset/colorado/6.png'
import co7 from '../../asset/colorado/7.png'
import co8 from '../../asset/colorado/8.png'
import co9 from '../../asset/colorado/9.png'
import co10 from '../../asset/colorado/10.png'



const MelbourneRecruiter = [
    {
        id: 1,
        Image: m1,
        title: ["Deloitte"],
    },
    {
        id: 2,
        Image: m2,
        title: ["Pricewater houseCoopers (PwC)"],
    },
    {
        id: 3,
        Image: m3,
        title: ["KPMG"],
    },
    {
        id: 4,
        Image: m4,
        title: ["EY (Ernst & Young)"],
    },
    {
        id: 5,
        Image: m5,
        title: ["McKinsey & Company"],
    },
    {
        id: 6,
        Image: m6,
        title: ["BCG (Boston Consulting Group)"],
    },
    {
        id: 7,
        Image: m7,
        title: ["Google"],
    },
    {
        id: 8,
        Image: m8,
        title: ["Microsoft"],
    },
    {
        id: 9,
        Image: m9,
        title: ["IBM"],
    },
    {
        id: 10,
        Image: m10,
        title: ["Apple"],
    }
];
const CaliforniaRecruiter = [
    {
        id: 1,
        Image: c1,
        title: ["Google"],
    },
    {
        id: 2,
        Image: c2,
        title: ["Apple"],
    },
    {
        id: 3,
        Image: c3,
        title: ["Microsoft"],
    },
    {
        id: 4,
        Image: c4,
        title: ["Amazon"],
    },
    {
        id: 5,
        Image: c5,
        title: ["Facebook (Meta)"],
    },
    {
        id: 6,
        Image: c6,
        title: ["Tesla"],
    },
    {
        id: 7,
        Image: c7,
        title: ["Deloitte"],
    },
    {
        id: 8,
        Image: c8,
        title: ["PwC (Pricewater houseCoopers)"],
    },
    {
        id: 9,
        Image: c9,
        title: ["Intel"],
    },
    {
        id: 10,
        Image: c10,
        title: ["LinkedIn"],
    }
];
const wisconsinRecruiter = [
    {
        id: 1,
        Image: w1,
        title: ["Google"],
    },
    {
        id: 2,
        Image: w2,
        title: ["Amazon"],
    },
    {
        id: 3,
        Image: w3,
        title: ["Microsoft"],
    },
    {
        id: 4,
        Image: w4,
        title: ["Apple"],
    },
    {
        id: 5,
        Image: w5,
        title: ["IBM"],
    },
    {
        id: 6,
        Image: w6,
        title: ["Goldman Sachs"],
    },
    {
        id: 7,
        Image: w7,
        title: ["Deloitte"],
    },

    {
        id: 8,
        Image: w11,
        title: ["Facebook (Meta)"],
    },
    {
        id: 9,
        Image: w12,
        title: ["Oracle"],
    },
    {
        id: 10,
        Image: w13,
        title: ["Johnson & Johnson"],
    },

];
const YeshivaRecruiter = [
    {
        id: 1,
        Image: y1,
        title: ["Google"],
    },
    {
        id: 2,
        Image: y2,
        title: ["Amazon"],
    },
    {
        id: 3,
        Image: y3,
        title: ["Goldman Sachs"],
    },
    {
        id: 4,
        Image: y4,
        title: ["Deloitte"],
    },
    {
        id: 5,
        Image: y5,
        title: ["PwC (Pricewater houseCoopers)"],
    },
    {
        id: 6,
        Image: y6,
        title: ["Ernst & Young (EY)"],
    },
    {
        id: 7,
        Image: y7,
        title: ["KPMG"],
    },
    {
        id: 8,
        Image: y8,
        title: ["IBM"],
    },
    {
        id: 9,
        Image: y9,
        title: ["Facebook (Meta)"],
    },
    {
        id: 10,
        Image: y10,
        title: ["JPMorgan Chase"],
    },

];
const UtahRecruiter = [
    {
        id: 1,
        Image: u1,
        title: ["Intermountain Healthcare"],
    },
    {
        id: 2,
        Image: u2,
        title: ["SkyWest Airlines"],
    },
    {
        id: 3,
        Image: u3,
        title: ["Utah State Government"],
    },
    {
        id: 4,
        Image: u4,
        title: ["Zions Bank"],
    },
    {
        id: 5,
        Image: u5,
        title: ["Southern Utah University"],
    },
    {
        id: 6,
        Image: u6,
        title: ["Wells Fargo"],
    },
    {
        id: 7,
        Image: u7,
        title: ["Adobe"],
    },
    {
        id: 8,
        Image: u8,
        title: ["Utah Shakespeare Festival"],
    },
    {
        id: 9,
        Image: u9,
        title: ["Southwest Technical College"],
    },
    {
        id: 10,
        Image: u10,
        title: ["Iron County School District"],
    },

];
const ColoradoRecruiter = [
    {
        id: 1,
        Image: co1,
        title: ["Lockheed Martin"],
    },
    {
        id: 2,
        Image: co2,
        title: ["Intel"],
    },
    {
        id: 3,
        Image: co3,
        title: ["IBM"],
    },
    {
        id: 4,
        Image: co4,
        title: ["Hewlett-Packard (HP)"],
    },
    {
        id: 5,
        Image: co5,
        title: ["Agilent Technologies"],
    },
    {
        id: 6,
        Image: co6,
        title: ["Ball Aerospace & Technologies Corp."],
    },
    {
        id: 7,
        Image: co7,
        title: ["Arrow Electronics"],
    },
    {
        id: 8,
        Image: co8,
        title: ["Woodward, Inc."],
    },
    {
        id: 9,
        Image: co9,
        title: ["Charles Schwab"],
    },
    {
        id: 10,
        Image: co10,
        title: ["Kaiser Permanente"],
    }
];

export { MelbourneRecruiter, CaliforniaRecruiter, wisconsinRecruiter, YeshivaRecruiter, UtahRecruiter, ColoradoRecruiter }