/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import MainHeader from "src/Component/content-component/MainHeader";
import FormComponent from "src/Component/form-component/Form";
import { Icon } from "@iconify/react";
import TestRouteContainer from "src/Component/grid-component/TestRouteContainer";

const TestInfo = ({ testInfo }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Box backgroundColor="#effdff" width="auto"

      height="auto">
      <TestRouteContainer />

      <Box
        sx={{

          padding: { xs: "20px 15px 0px 15px", sm: "20px 40px 0px 40px" },
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              lg={6} md={6} sm={12} xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <MainHeader
                  color="rgba(0, 0, 0, 0.7)"
                  content={
                    <Box sx={{ fontSize: { xs: "20px", sm: "30px" } }}>
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#00999E",
                          display: "inline-block",
                          fontWeight: "inherit",
                          fontSize: { xs: "24px", sm: "26px", md: "36px", lg: "36px" },
                        }}
                      >
                        {testInfo?.title}:{" "}
                      </Typography>{" "}
                      {testInfo?.content}
                    </Box>
                  }
                />


                <Box sx={{ my: { xs: 2, sm: 5 } }}>
                  <ButtonComponent
                    width={"auto"}
                    fontSize="20px"
                    text={testInfo?.cta}
                    onClick={handleOpenDialog}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box
                sx={{
                  width: { md: "100%", sm: "90%", xs: "90%" },
                  // height: { xs: "270px", sm: "300px", md: "410px", lg: "530px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={testInfo?.image} alt={testInfo?.title} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            Register Now
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
            >
              <Icon icon="bitcoin-icons:cross-outline" />
            </IconButton>
          </DialogTitle>
          <FormComponent
            handleCloseDialog={handleCloseDialog}
            setOpenDialog={setOpenDialog}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default TestInfo;
