import webinar from "../asset/event4.jpg";
import img from "../asset/mainNews.jpg";
import img2 from "../asset/event3.jpg";

const WebinarData = [
    {
        date: "Apr 12, 2024",
        title: "NEET Webinar Recap: Insights and Strategies for Success",
        content: [
            "Taksheela recently hosted a dynamic NEET webinar featuring Ms. Sanskriti R. Ganeriwal, a 3rd-year MBBS student from Rajarshi Dashrath Autonomous College, Ayodhya. With over 50 eager participants, Sanskriti shared invaluable insights on NEET preparation, effective study strategies, and mastering time management. Held via Google Meet on April 12, 2024, it was an enriching session for all aspiring medical students."
        ],
        image: webinar
    },

    {
        date: "May 16, 2024",

        title: "Join Us for an Exclusive MBBS Counseling Session at Taksheela!",
        content: [
            "Taksheela Institute of Education is excited to announce an open MBBS counseling session on May 19, 2024. Whether you're seeking guidance on medical school admissions or exploring study abroad options, our team of experts is here to assist you every step of the way.",
            "Date: May 19, 2024", "Time: 11:00 AM - 7:00 PM", "You can still reach out to us via email at info@taksheela.com for personalized assistance and guidance."
        ],
        image: img
    },
    {
        date: "May 29, 2024",

        title: "Free Webinar on NEET Counseling Process",
        content: [
            "Attention aspirants! A free webinar on NEET Counseling Process (State/AIQ) will be held on May 30th at 8 PM via Google Meet. Hosted by Mrs. Sujata Pradhan, this session will provide expert tips to help you navigate the counseling process. Don't miss this valuable opportunity!"
        ],
        image: img2
    }
];
export default WebinarData;
