import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Navbar from "src/Component/Navbar";
import Footer from "src/Component/Footer";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Box
        sx={{
          marginBottom: "100px"
        }}
      >
        <Container>
          <Typography
            variant="h2"
            sx={{
              fontSize: "32px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Welcome to Taksheela Institute of Education!{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            These terms and conditions outline the rules and regulations for the
            use of Taksheela Institute of Education's Website, located at
            www.taksheela.com.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Taksheela Institute of Education
            if you do not agree to take all of the terms and conditions stated
            on this page.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Company's stated services, in accordance with and
            subject to, prevailing governing laws of India. Any use of the above terminology
            or other words in the singular, plural, capitalization and/or he/she
            or they, are taken as interchangeable and therefore as referring to
            same.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Cookies
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We employ the use of cookies. By accessing Taksheela Institute of
            Education, you agreed to use cookies in agreement with the Taksheela
            Institute of Education's Privacy Policy.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Most interactive websites use cookies to let us retrieve the user's
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            License
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Unless otherwise stated, Taksheela Institute of Education and/or its
            licensors own the intellectual property rights for all material on
            Taksheela Institute of Education. All intellectual property rights
            are reserved. You may access this from Taksheela Institute of
            Education for your own personal use subjected to restrictions set in
            these terms and conditions.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            You must not:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Republish material from Taksheela Institute of Education
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Sell, rent or sub-license material from Taksheela Institute of
              Education
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Reproduce, duplicate or copy material from Taksheela Institute of
              Education
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Redistribute content from Taksheela Institute of Education
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            This Agreement shall begin on the date hereof. Our Terms and
            Conditions were created with the help of the{" "}
            <a href="https://www.termsandconditionsgenerator.com/">
              Free Terms and Conditions Generator
            </a>
            .{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            Taksheela Institute of Education does not filter, edit, publish or
            review Comments prior to their presence on the website. Comments do
            not reflect the views and opinions of Taksheela Institute of
            Education,its agents and/or affiliates. Comments reflect the views
            and opinions of the person who post their views and opinions. To the
            extent permitted by applicable laws, Taksheela Institute of
            Education shall not be liable for the Comments or for any liability,
            damages or expenses caused and/or suffered as a result of any use of
            and/or posting of and/or appearance of the Comments on this website.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Taksheela Institute of Education reserves the right to monitor all
            Comments and to remove any Comments which can be considered
            inappropriate, offensive or causes breach of these Terms and
            Conditions.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            You warrant and represent that:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            You hereby grant Taksheela Institute of Education a non-exclusive
            license to use, reproduce, edit and authorize others to use,
            reproduce and edit any of your Comments in any and all forms,
            formats or media.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Hyperlinking to our Content{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            The following organizations may link to our Website without prior
            written approval:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Government agencies;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Search engines;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              News organizations;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We may consider and approve other link requests from the following
            types of organizations:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              commonly-known consumer and/or business information sources;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              dot.com community sites;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              associations or other groups representing charities;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              online directory distributors;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              internet portals;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              accounting, law and consulting firms; and
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              educational institutions and trade associations.
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Taksheela
            Institute of Education; and (d) the link is in the context of
            general resource information.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Taksheela Institute of Education. Please
            include your name, your organization name, contact information as
            well as the URL of your site, a list of any URLs from which you
            intend to link to our Website, and a list of the URLs on our site to
            which you would like to link. Wait 2-3 weeks for a response.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Approved organizations may hyperlink to our Website as follows:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              By use of our corporate name; or
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              By use of the uniform resource locator being linked to; or
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party's site.
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            No use of Taksheela Institute of Education's logo or other artwork
            will be allowed for linking absent a trademark license agreement.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            iFrames
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Content Liability
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Reservation of Rights
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it's linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Removal of links from our website
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.{" "}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: "28px",
              margin: "30px 0px",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Disclaimer
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:{" "}
          </Typography>
          <ul
            style={{
              width: "95%",
              marginLeft: "auto",
            }}
          >
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li
              style={{
                fontSize: "16px",
                margin: "12px 0px",
                color: "#000",
                fontWeight: "400",
              }}
            >
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              margin: "12px 0px",
              color: "#000",
            }}
          >
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.{" "}
          </Typography>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
