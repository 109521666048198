import React, { useState } from 'react';
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, Card, Typography, Container } from '@mui/material';
import Header from 'src/Component/content-component/Header';



const UniversityFeeStructure = ({ data }) => {

    console.log('data :>> ', data);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const selectedCourseDetails = data?.find(course => course?.course === selectedCourse);


    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };


    return (
        <Box>
            <Container sx={{ padding: "50px 0" }}>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 5,
                        marginTop: 5
                    }}
                >
                    <Header content={"Eligibility and Fee Structure of some popular courses"} textAlign={"center"} />
                </Box>

                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>

                        <FormControl sx={{
                            mb: 2, width: "300px", display: "flex",
                            justifyContent: "center",
                        }}>
                            <InputLabel id="course-select-label">Select a course</InputLabel>
                            <Select
                                labelId="course-select-label"
                                id="course-select"
                                value={selectedCourse}
                                label="Select a course"
                                onChange={handleCourseChange}
                            >
                                {data?.map((course, index) => (
                                    <MenuItem key={index} value={course.course}>
                                        {course.course}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* <Typography variant="h4" component="h2" gutterBottom>
                {selectedCourse}
            </Typography> */}
                    <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
                        {selectedCourseDetails?.details?.map((detail, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex", justifyContent: "center" }}>
                                <Card sx={{ boxShadow: 3, width: { md: "350px", xs: "290px" }, height: "300px", borderRadius: "10px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <Box sx={{ mb: 2, width: "120px", justifyContent: "center", display: "flex" }}>
                                        <img src={detail.image} alt={detail.title} style={{ width: '100%' }} />
                                    </Box>
                                    <Box sx={{ height: "100px", width: "270px" }}>

                                        <Typography variant="h6" component="h3" sx={{ textAlign: "center" }}>
                                            {detail.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                                            {detail.value}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default UniversityFeeStructure;
