import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Image1 from "../../../src/asset/StydyUSA/image1.png";
import Image2 from "../../../src/asset/StydyUSA/image2.png";
import Image3 from "../../../src/asset/StydyUSA/image3.png";
import Image4 from "../../../src/asset/StydyUSA/image4.png";
import Image5 from "../../../src/asset/StydyUSA/image5.png";
import Image6 from "../../../src/asset/StydyUSA/image6.png";
import Header from "src/Component/content-component/Header";

const StudyUSA = ({ data }) => {
  return (
    <Box mt={5} sx={{ background: "#EFFDFF" }}>
      <Container>
        <Grid>
          <Box
            pt={10}
            pb={10}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
              justifyContent: "center"
            }}
          >
            <Header
              content={data?.title}
              textAlign={{ md: "center", sm: "center", xs: "center" }}
            />

          </Box>
          <Grid pb={10} item md={12} xs={12} n display={"flex"} justifyContent={"center"}>


            <Box >
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  border: "4px dashed whitesmoke",
                  height: { md: "500px" },
                  width: { md: "500px" },
                  borderRadius: "50%",
                  background: "#63D7DB",
                  position: "relative",
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "4px dashed whitesmoke",
                    height: { md: "300px" },
                    width: { md: "300px" },
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid whitesmoke",
                      height: { md: "150px" },
                      width: { md: "150px" },
                      borderRadius: "50%",
                      background: "whitesmoke",
                      boxShadow: "4px 7px 4px rgba(0,0,0,0.2)"
                    }}
                  >
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        left: "-15px", top: "-25px",
                        padding: "20px 0",
                        width: "170px",
                        height: "120px",
                      }}
                    >

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image1} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "13px" }, fontWeight: 500
                        }}
                      >
                        {data?.point1}
                      </Typography>
                    </Card>
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        right: "-15px", top: "-25px",
                        width: "170px",
                        height: "120px", padding: "20px 0",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image2} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "13px" }, fontWeight: 500
                        }}
                      >
                        {data?.point2}
                      </Typography>
                    </Card>
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        left: "-15px", bottom: "-25px",
                        width: "170px",
                        height: "120px", padding: "20px 0",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image5} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "13px" }, fontWeight: 500
                        }}
                      >
                        {data?.point5}
                      </Typography>
                    </Card>
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        // padding: "10px",
                        position: "absolute",
                        right: "-15px", bottom: "-25px",
                        width: "170px",
                        height: "120px", padding: "20px 0",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image6} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "13px" }, fontWeight: 500
                        }}
                      >
                        {data?.point6}
                      </Typography>
                    </Card>
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        left: "-120px",
                        width: "170px",
                        height: "120px", padding: "20px 0",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image3} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "13px" }, fontWeight: 500
                        }}
                      >
                        {data?.point3}
                      </Typography>
                    </Card>
                    <Card
                      sx={{
                        zIndex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        right: "-120px",
                        width: "170px",
                        height: "120px", padding: "20px 0",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "80px",
                          margin: "auto",
                        }}
                      >
                        <img src={Image4} alt="" width="70%" />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xl: "13px", lg: "13px", md: "14px" }, fontWeight: 500
                        }}
                      >
                        {data?.point4}
                      </Typography>
                    </Card>
                  </Box>
                </Box>
              </Box>
            </Box>

          </Grid>
        </Grid>
        <Grid item sm={12} xs={12} >
          <Box
            sx={{
              display: {
                xl: "none",
                lg: "none",
                md: "none",
                sm: "screen",
                xs: "screen",
              },
              justifyContent: "center"
            }}
          >
            <Header
              content={data?.title}
              textAlign={{ md: "center", sm: "center", xs: "center" }}
            // fontSize={"36px"}
            />

          </Box>
          <Grid pt={5} item md={12} xs={12}>
            <Box sx={{}}>
              <Box
                sx={{
                  justifyContent: "center",
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "flex",
                    xs: "flex",
                  },
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image1} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point1}
                  </Typography>
                </Card>
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image2} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point2}
                  </Typography>
                </Card>
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image3} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point3}
                  </Typography>
                </Card>
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image4} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point4}
                  </Typography>
                </Card>
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image5} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point5}
                  </Typography>
                </Card>
                <Card
                  pb={5}
                  sx={{
                    zIndex: "1",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "250px",
                    height: "180px",
                    marginBottom: "25px",
                    padding: "20px 5px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        sm: "80px", xs: "80px"
                      },
                      margin: "auto",
                    }}
                  >
                    <img src={Image6} alt="" width="100%" />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: { xl: "20px", lg: "12px", md: "13px", sm: "16px", xs: "16px" }, fontWeight: 500,
                      pt: "5px"
                    }}
                  >
                    {data?.point6}
                  </Typography>
                </Card>

              </Box>



            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default StudyUSA;
