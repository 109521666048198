import college1 from "../asset/college1.png";
import college2 from "../asset/college2.png";
import college3 from "../asset/college3.png";
import college4 from "../asset/college4.png";
import college5 from "../asset/college5.png";
import college6 from "../asset/college6.png";
import college7 from "../asset/college7.png";
import college8 from "../asset/college8.png";
import college9 from "../asset/college9.png";
import college10 from "../asset/college10.png";

const collegeData = [
  {
    title: "AIIMS",
    items: [

      "All India Institute of Medical Sciences (AIIMS), New Delhi",
    ],
    Image: college1,
  },
  {
    title: "CMC",
    items: ["Christian Medical College (CMC), Vellore"],
    Image: college2,
  },
  {
    title: "AFMC",
    items: ["Armed Forces Medical College (AFMC), Pune"],
    Image: college3,
  },
  {
    title: "MAMC",
    items: ["Maulana Azad Medical College (MAMC), New Delhi"],
    Image: college4,
  },
  {
    title: "GMC",
    items: ["Grant Medical College (GMC), Mumbai"],
    Image: college5,
  },
  {
    title: "KGMU",
    items: [


      "King George's Medical University (KGMU), Lucknow",
    ],
    Image: college6,
  },
  {
    title: "JIPMER",
    items: [


      "Jawaharlal Institute of Postgraduate Medical Education and Research (JIPMER), Puducherry",
    ],
    Image: college7,
  },
  {
    title: "LHMC",
    items: ["Lady Hardinge Medical College (LHMC), New Delhi"],
    Image: college8,
  },
  {
    title: "SGSMC",
    items: ["Seth GS Medical College (SGSMC), Mumbai"],
    Image: college9,
  },
  {
    title: "BMCRI",
    items: [

      "Bangalore Medical College and Research Institute (BMCRI), Bangalore",
    ],
    Image: college10,
  },
];

export default collegeData;
