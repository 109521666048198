import { Box, Container, Grid } from "@mui/material";
import Cta from "src/Component/card-components/Cta";
import FlipCard from "src/Component/card-components/FlipCard";
import Header from "src/Component/content-component/Header";
const MBBSAspect = ({ data }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#ffffff",
                    padding: { xs: "20px", md: "50px 0" },
                    display: "flex",
                    justifyContent: "center"
                }}
                id="indiaCriteria"
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "30px 0"
                        }}
                    >
                        <Header content={"Aspect & Facts"} textAlign={"center"} />
                    </Box>

                    <Grid
                        container
                        spacing={3}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        {data?.map((Aspect, index) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        key={index}
                                        sx={{ textAlign: "-webkit-center" }}
                                    >
                                        <FlipCard data={Aspect} />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>

                        <Cta title={"BOOK YOUR FREE DEMO SESSION"} />
                    </Box>
                </Container>
            </Box>
        </>
    );
};
export default MBBSAspect;
