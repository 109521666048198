/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Navbar from "src/Component/Navbar";
import Footer from "src/Component/Footer";
const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Box
        sx={{
          marginBottom: "100px"
        }}
      >
        <Container>
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontSize: "40px",
                fontWeight: "600",
                margin: "30px 0",
                letterSpacing: "1px",
              }}
            >
              Return Policy
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                margin: "12px 0px",
                color: "#000",
              }}
            >
              Thank you for choosing Taksheela Institute of Education for your educational needs. We strive to provide the highest quality services to our customers. If you are not entirely satisfied with your purchase, we're here to help.
            </Typography>


            <h3

            >
              Cancellation and Refund Policy
            </h3>

            <ul
              style={{
                width: "95%",
                marginLeft: "auto",
              }}
            >
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  Customers can cancel their subscription within 7 days of the initial purchase for a full refund.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  No refund will be provided for cancellations made after the 7 days period.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  To cancel a subscription, customers must contact our support team by <strong>email</strong> -<a href="mailto:info@taksheela.com" rel="noreferrer" target="_blank">info@taksheela.com </a> <strong> /phone number- <a style={{ color: "#000", textDecoration: "none" }} href="tel:+919831241212" target="_blank" rel="noreferrer" >+919831241212</a></strong> with their account details and reason for cancellation.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  Refunds will be processed within 3- 4 business days from the date of cancellation confirmation.
                </Typography>
              </li>


            </ul>
            <h3>Service Quality Guarantee</h3>

            <ul
              style={{
                width: "95%",
                marginLeft: "auto",
              }}
            >
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  If customers are dissatisfied with the quality of our services, they can request a refund within 7 days of the service delivery.

                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >To request a refund, customers must provide details of their concerns and reasons for dissatisfaction to our support team at <strong>contact email -</strong> <a href="mailto:info@taksheela.com" target="_blank" rel="noreferrer">info@taksheela.com</a><strong> /phone number- <a href="tel:+919831241212" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#000" }}>+919831241212</a></strong>.


                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  Refunds will be issued based on the nature of the concern and at the discretion of our management team.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "12px 0px",
                    color: "#000",
                  }}
                >
                  Refunds for services already delivered will be prorated based on the remaining subscription period.
                </Typography>
              </li>
            </ul>

            <h3>Non-Refundable Services</h3>

            <ul
              style={{
                width: "95%",
                marginLeft: "auto",
              }}
            >
              <li
                style={{
                  fontSize: "16px",
                  margin: "12px 0px",
                  color: "#000",
                  fontWeight: "400",
                }}
              >
                Certain services, such as personalised coaching sessions or customised course materials, may not be eligible for refunds due to their nature.
              </li>
              <li
                style={{
                  fontSize: "16px",
                  margin: "12px 0px",
                  color: "#000",
                  fontWeight: "400",
                }}
              >
                Customers will be notified in advance if any service is non-refundable, and such information will be clearly communicated during the purchase process.
              </li>
            </ul>









            <Typography
              variant="h2"
              sx={{
                fontSize: "32px",
                margin: "30px 0px",
                color: "#000",
                fontWeight: "600",
              }}
            >
              Contact Information
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                margin: "12px 0px",
                color: "#000",
              }}
            >
              For any questions or concerns regarding our return policy or to request a refund, please contact our support team at <strong> contact email -</strong><a href="mailto:info@taksheela.com" target="_blank" rel="noreferrer"> info@taksheela.com </a>
              <strong> /phone number- <a href="tel:+919831241212" target="_blank" rel="noreferrer" style={{ color: "#000", textDecoration: "none" }}>+919831241212</a></strong>. We are here to assist you and ensure your satisfaction with our services

            </Typography>

          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default RefundPolicy;
