import React from 'react';
import { Box } from '@mui/material';
import NavBar from 'src/Component/dashboard/navbar/NavBar';
import { Outlet } from 'react-router-dom';
import TopBar from 'src/Component/dashboard/TopBar';

const DashboardLayout = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: "100vh",
        display: 'flex',
      }}
    >
      {/* Left Sidebar */}
      <Box
        sx={{
          overflowY: 'auto',
          height: '100%',
          boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Right side shadow
        }}
      >
        <NavBar />
      </Box>
      {/* Right Content */}
      <Box
        sx={{
          overflowY: 'auto',
          height: '100%',
          width: { md: `calc(100% - 200px)`, xs: "100%" }
        }}
      >
        <TopBar />
        <Outlet />
      </Box>
    </Box>
  )
}

export default DashboardLayout;
