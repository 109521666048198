import ChooseUsImg1 from "../asset/testchoose1.svg";
import ChooseUsImg2 from "../asset/testchoose2.svg";
import ChooseUsImg3 from "../asset/testchoose3.svg";
import ChooseUsImg4 from "../asset/testchoose4.svg";

const IeltsChooseUs = {
    title: "Why choose IELTS?",
    reasons: [
        {
            title: "Availability",
            items: ["Offered in 140+ countries with 1600+ test centers globally."],
            Image: ChooseUsImg1,
        },
        {
            title: "Recognition",
            items: [
                "Accepted worldwide.",
            ],
            Image: ChooseUsImg2,
        },
        {
            title: "Enhances Significance",
            items: [
                "Aids immigration pathways.",
            ],
            Image: ChooseUsImg3,
        },
        {
            title: "Ease of Access",
            items: [
                "User-friendly in computer and paper formats."
            ],
            Image: ChooseUsImg4,
        },

    ]
}

const GmatChooseUs = {
    title: "Why choose GMAT?",
    reasons:
        [

            {
                title: "Premier Endorsement",
                items: [
                    "Gateway to over 7,700 business schools."],
                Image: ChooseUsImg1,
            },
            {
                title: "Widely Accepted",
                items: [
                    "Recognized by business schools globally",
                ],
                Image: ChooseUsImg2,
            },
            {
                title: "Scholarship Giveaway",
                items: [
                    "Provides financial aid to top business schools.",
                ],
                Image: ChooseUsImg3,
            },
            {
                title: "Accessibility",
                items: [
                    "Offers both in-person and online test options"
                ],
                Image: ChooseUsImg4,
            },

        ]
}

const PteChooseUs = {
    title: "Why choose PTE?",
    reasons: [
        {
            title: "Versatile Exam Schedules",
            items: [
                "Offers test dates throughout the year"
            ], Image: ChooseUsImg1,
        },
        {
            title: "Global Recognition",
            items: [
                "Accepted by thousands of universities worldwide"
            ], Image: ChooseUsImg2,
        },
        {
            title: "Fast Results",
            items: [
                "Scores typically available within 2-3 days"
            ], Image: ChooseUsImg3,
        },
        {
            title: "Fair Grading",
            items: [
                "Unbiased scoring through artificial intelligence"
            ], Image: ChooseUsImg4,
        }
    ]

}

const GreChooseUs = {
    title: "Why choose GRE?",
    reasons: [
        {
            title: "Versatility",
            items: [
                "Accepted by thousands of institutions worldwide"
            ], Image: ChooseUsImg1,
        },
        {
            title: "Flexibility",
            items: [
                "Test offered year-round at numerous centers globally"
            ], Image: ChooseUsImg2,
        },
        {
            title: "Accessibility",
            items: [
                "Offers both at-home and in-person testing"
            ], Image: ChooseUsImg3,
        },
        {
            title: "Scholarships",
            items: [
                "Institutions offer financial aid based on GRE scores"
            ], Image: ChooseUsImg4,
        }
    ]
}


const ToeflChooseUs =
{
    title: "Why choose TOEFL?",
    reasons: [
        {
            title: "Widely Accepted",
            items: [
                "Accepted by 11,000 institutions in 150+ countries."
            ], Image: ChooseUsImg1,
        },
        {
            title: "Global Recognition",
            items: [
                "Recognized as a reliable measure"
            ], Image: ChooseUsImg2,
        },
        {
            title: "Internet-Based Testing (iBT)",
            items: [
                "Offers the flexibility of taking the test online"
            ], Image: ChooseUsImg3,
        },
        {
            title: "Gateway to Opportunities",
            items: [
                "Opens doors admission to professional development."
            ], Image: ChooseUsImg4,
        }
    ]
}
const SatChooseUs = {
    title: "Why choose IELTS?",
    reasons: [
        {
            title: "Scholarship Opportunities",
            items: [
                "High scores can lead to financial aid"
            ], Image: ChooseUsImg1,
        },
        {
            title: "College Admission",
            items: [
                "Accepted by many colleges globally"
            ], Image: ChooseUsImg2,
        },
        {
            title: "Flexibility",
            items: [
                "Offered multiple times a year"
            ], Image: ChooseUsImg3,
        },
        {
            title: "Standardized Evaluation",
            items: [
                "Common measure for comparing applicants"
            ], Image: ChooseUsImg4,
        }
    ]
}






export { IeltsChooseUs, GmatChooseUs, PteChooseUs, GreChooseUs, ToeflChooseUs, SatChooseUs };
