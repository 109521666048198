import React, { useState } from 'react'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, List, Typography } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import logo from '../../asset/TIE_LOGO.png';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import { Icon } from '@iconify/react';


const TopBar = () => {


  const navigate = useNavigate();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);



  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    sessionStorage.removeItem('token');

    // Navigate to the home page

    setLogoutDialogOpen(false);
    navigate('/');
    // Perform logout action here
  };

  const HoverTypography = ({ children, fontSize, fontWeight, isActive }) => (
    <Typography
      variant="h6"
      color="inherit"
      sx={{
        textDecoration: "none",
        underline: "none",
        border: "2px solid white",
        borderRadius: 2,
        padding: {
          xs: "5px 0px 5px 0px",
          sm: "5px 0px 5px 0px",
          md: "5px 8px 5px 8px",
          lg: "5px 20px 5px 20px",
        },
        fontSize: { md: "14px", lg: "16px" },
        fontStyle: "normal",

        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "0.2px",
        "&:hover": {
          color: "#00999e",
          border: isActive ? "2px solid #00999e" : "",
          cursor: "pointer",
          padding: {
            xs: "5px 0px 5px 0px",
            sm: "5px 0px 5px 0px",
            md: "5px 20px 5px 20px",
            lg: "5px 20px 5px 20px",
          },
        },
      }}
    >
      {children}
    </Typography>
  );

  const username = sessionStorage.getItem("username");

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#00999E",
        padding: "18px 30px",
        display: "flex", alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

        <Box sx={{ display: { md: "none", xs: "block" } }}>
          <Box sx={{ display: "flex", flexDirection: "row", color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            sx={{
              "& .MuiDrawer-paper": {
                width: "90%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <NavLink to="/dashboard/home" className="custom-link">
                <img width="150px" src={logo} alt="Logo" />
              </NavLink>
              <IconButton
                onClick={handleDrawerClose}
                sx={{ alignSelf: "flex-start" }}
              >
                <CloseIcon sx={{ color: "#00999E" }} />
              </IconButton>
            </Box>
            <List sx={{ padding: "0px 20px" }}>
              <NavLink to="/dashboard/home" className="custom-link" onClick={handleDrawerClose}>
                <HoverTypography
                  fontSize={{
                    xs: "14px",
                    sm: "16px",
                    md: "16px",
                    lg: "20px",
                  }}
                  fontWeight={500}
                  sx={{ width: "80px" }}
                >
                  Home
                </HoverTypography>
              </NavLink>
              <NavLink to="/dashboard/order" className="custom-link" onClick={handleDrawerClose}>
                <HoverTypography
                  fontSize={{
                    xs: "14px",
                    sm: "16px",
                    md: "16px",
                    lg: "17px",
                  }}
                  fontWeight={500}
                  sx={{ width: "60px" }}
                >
                  Order
                </HoverTypography>
              </NavLink>
              {/* <NavLink to="/dashboard/enrollment-form" className="custom-link" onClick={handleDrawerClose}>
                <HoverTypography
                  fontSize={{
                    xs: "14px",
                    sm: "16px",
                    md: "16px",
                    lg: "17px",
                  }}
                  fontWeight={500}
                  sx={{ width: "60px" }}
                >
                  Enrollment Form
                </HoverTypography>
              </NavLink> */}
              {/* <NavLink to="/dashboard/precounselling-form" className="custom-link" onClick={handleDrawerClose}>
                <HoverTypography
                  fontSize={{
                    xs: "14px",
                    sm: "16px",
                    md: "16px",
                    lg: "17px",
                  }}
                  fontWeight={500}
                  sx={{ width: "60px" }}
                >
                  Pre Counselling Form
                </HoverTypography>
              </NavLink> */}
            </List>
          </Drawer>

        </Box>
        <Box
          sx={{
            // borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "300px",
            minWidth: "250px",
            width: "30%",
            padding: "6px 10px"
          }}
        >
          <input
            style={{
              padding: "5px",
              fontSize: "0.9rem",
              color: "#444",
              outline: "none",
              border: "none",
              backgroundColor: "transparent",

            }}
            type='text'
            placeholder='search'
          />
          <SearchIcon
            sx={{
              color: "#444",
            }}
          />
        </Box>

      </Box>
      <Box sx={{ display: " flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          sx={{
            height: "45px",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "50%",
            backgroundColor: "#fff",
            padding: "10px"


          }}

        >

          <Icon icon="mingcute:notification-fill" style={{ color: "#000000", fontSize: "25px" }} />     </Box>
        <Box sx={{
          backgroundColor: "#ffffff",
          borderRadius: "40px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
          padding: "6px",
          mx: "10px"

        }}>
          <Typography sx={{ color: "#000000", fontWeight: "550", fontSize: "15px", mx: "10px" }}> Hey, {username}</Typography>
          <Avatar />

        </Box>


        <Box
          sx={{
            height: "45px",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "50%",
            backgroundColor: "#fff",
            padding: "10px"

          }} onClick={handleLogout}

        >

          <Icon icon="ep:switch-button" style={{ color: "#000000", fontSize: "25px" }} />      </Box>
      </Box>

      <Dialog open={logoutDialogOpen} onClose={() => setLogoutDialogOpen(false)} sx={{
        '& .MuiDialog-paper': {
          width: { sm: '450px', xs: "300px" }, // You can set your custom width here
        }
      }}>

        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color="primary"
            onClick={() => setLogoutDialogOpen(false)}>Cancel</Button>
          <Button variant='contained' color="error" onClick={confirmLogout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TopBar