import React from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import CoursesTable from "../CoursesTable";

const DashboardEnrollmentForm2 = () => {
  return (
    <Grid item md={12} lg={12} xs={12} sx={{ paddingTop: "20px" }}>
      <Box sx={{ margin: "0 20px" }}>
        <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: "visible" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ flex: 1, paddingTop: "30px", paddingLeft: "10px" }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  You came to know about TAKSHEELA from:
                </Typography>
              </Box>
              <Box sx={{ flex: 1, paddingTop: "30px", paddingLeft: "10px" }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={[]}
                    multiple
                  >
                    <MenuItem value="Friends">Friends</MenuItem>
                    <MenuItem value="Newspaper">Newspaper</MenuItem>
                    <MenuItem value="Internet">Internet</MenuItem>
                    <MenuItem value="Poster">Poster</MenuItem>
                    <MenuItem value="Flyer">Flyer</MenuItem>
                    <MenuItem value="Seminar">Seminar</MenuItem>
                    <MenuItem value="TV">TV</MenuItem>
                    <MenuItem value="Emailer">Emailer</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "30px",
                paddingLeft: "10px",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  Mention the Newspaper/Magazine that you read
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <TextField
                  sx={{
                    width: "100%",
                    paddingTop: "15px",
                  }}
                  label=""
                  name="mention"
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ paddingTop: "30px", paddingLeft: "10px" }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  I can access the Internet at:
                </Typography>
              </Box>
              <Box>
                <FormControl sx={{ paddingLeft: "20px", paddingTop: "24px" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="office"
                      control={<Radio />}
                      label="Office"
                    />
                    <FormControlLabel
                      value="cybercafe"
                      control={<Radio />}
                      label="Cybercafe"
                    />
                    <FormControlLabel
                      value="noaccess"
                      control={<Radio />}
                      label="No Access"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ paddingTop: "20px" }}>
          <CoursesTable />
        </Box>

        <Box sx={{ paddingTop: "20px" }}>
          <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: "visible" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Typography
                  sx={{
                    paddingLeft: "30px",
                    paddingTop: "30px",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  Please give the name address of any two of your friends who
                  would like to receive information about TAKSHEELA courses
                </Typography>



                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Address"
                    name="address"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Mobile Number"
                    name="mobilenumber"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>


                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Address"
                    name="address"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Mobile Number"
                    name="mobilenumber"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={3} xs={6} sm={3} md={3}>
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>


              </Grid>

            </CardContent>
          </Card>
        </Box>

        <Box sx={{ paddingTop: "20px" }}>
          <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: "visible" }}>
            <CardContent>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    paddingLeft: "30px",
                    paddingTop: "30px",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "25px",
                    },
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center", // Center the text horizontally
                    textDecoration: "underline", // Add underline
                  }}
                >
                  DECLARATION
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Checkbox />
                  <Typography
                    sx={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "20px",
                      },
                      display: "flex",
                      justifyContent: "center",
                      // Center the text horizontally
                    }}
                  >
                    I ......., agree to the terms and conditions mentioned in the
                    enrollment form. I agree that the mailing address I have
                    mentioned in this form is correct and all correspondence to me
                    should be directed to this mailing address. I agree that
                    Taksheela will not be responsible for non-recept of any
                    material due to incorrect mailing address. I would like
                    Important informations to be send to me through Emails & SMS.
                    I will inform TAKSHEELA in case of any changes in my Mailing
                    Address/EmailID/Mobile Number.
                  </Typography>
                </Box>


              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Grid>
  );
};

export default DashboardEnrollmentForm2;
