import { Container, Grid } from "@material-ui/core";
import {
  Box,
  Button,
  Stack,
  createTheme,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import "../../index.css";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HeroImg1 from "../../asset/heroImg1.png";
import HeroImg2 from "../../asset/heroImg2.png";
import "../../globle.css";

const About = () => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        lg: 1024,
        xxl: 1920
      }
    }
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  const mediumLarge = useMediaQuery(largeScreen.breakpoints.up("lg"));

  return (
    <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
      <Grid
        container
        spacing={isSmScreen ? 4 : isMediumScreen ? 4 : mediumLarge ? 4 : 8}
        display="flex"
        justifyContent="space-between"
        style={{
          height: "100%",
          padding: "50px 0"
        }}
      >
        <Grid item xs={12} sm={6} md={7} lg={7}>
         
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              color: "#00999E",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: { xs: "50px", sm: "50px", md: "80px", lg: "100px" },
              textTransform: "capitalize",
            }}
          >
            ~Our Story~
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "black",
              fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.8",
              // textTransform: "capitalize",
              textAlign: "justify"
              // textJustify: "inter-word",
            }}
          // className="text-justify-services"
          >
            Welcome to TIE, where dreams transform into global opportunities. As
            a leading study abroad consultancy, we believe education transcends
            borders. Our commitment is unlocking doors to international
            education, fostering academic and personal growth. With a focus on
            excellence, integrity, and a student-centric approach, we pave the
            way for global citizens ready to thrive in an interconnected world.
          </Typography>
          S
          <br /> <br />
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              color: "#141414",
              fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.8"
              // textTransform: "capitalize",
            }}
            className="text-justify-services"
          >
            Join us on a journey where education knows no boundaries, and the
            world becomes your campus
          </Typography>
          {/*  <Stack sx={{
            width: "30%",
            paddingTop: "50px",

          }}>
            <Button variant="contained" endIcon={<ArrowRightAltIcon />}
              style={{ backgroundColor: "#00999E" }}
            >
              Send
            </Button>
        </Stack> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={5}
          sx={{
            width: "100%",
            margin: "auto"
          }}
        >
          <Stack
            style={{
              width: "100%",
              height: "480px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative"
            }}
          >
            <Stack
              sx={{
                // width: { xs: "380px", sm: "300px", md: "380px", lg: "400px", },
                width: isSmScreen ? "85%" : isMediumScreen ? "90%" : "400px",
                background: "#00999E",
                height: isSmScreen ? "85%" : isMediumScreen ? "85%" : "360px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                borderRadius: "10px 0px 10px 0px",
                "@media screen and (min-width: 1024px) and (max-width: 1024px)":
                {
                  width: "350px !important"
                }
              }}
            >
              <img
                src={HeroImg1}
                alt="heroImg1"
                loading="lazy"
                style={{
                  width: isSmScreen
                    ? "80%"
                    : isMediumScreen
                      ? "70%"
                      : mediumLarge
                        ? "60%"
                        : largeScreen
                          ? "50%"
                          : "60%",
                  height: isSmScreen
                    ? "45%"
                    : isMediumScreen
                      ? "45%"
                      : largeScreen
                        ? "47%"
                        : "47%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  borderRadius: "10px"
                }}
              />
              <img
                src={HeroImg2}
                alt="heroImg2"
                loading="lazy"
                style={{
                  width: isSmScreen
                    ? "80%"
                    : isMediumScreen
                      ? "70%"
                      : mediumLarge
                        ? "60%"
                        : largeScreen
                          ? "50%"
                          : "60%",
                  height: isSmScreen
                    ? "45%"
                    : isMediumScreen
                      ? "45%"
                      : largeScreen
                        ? "47%"
                        : "47%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  borderRadius: "10px"
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
