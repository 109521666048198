/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-comment-textnodes */
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Icon } from "@iconify/react";
import notfound from "../asset/notfound.png";
import { useNavigate } from "react-router";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <Navbar />
            <Container>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Card
                        sx={{
                            padding: "25px",
                            m: 6,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",

                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: " center",

                                paddingLeft: {
                                    xs: "10px",
                                    sm: "40px",
                                    md: "40px",
                                    lg: "40px"
                                }
                            }}
                        >
                            <Box sx={{ width: { xs: "300px", md: "500px" } }}>
                                <img
                                    src={notfound}
                                    alt="Thank-you image"
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Box>

                            <Typography
                                variant="h3"
                                sx={{
                                    color: " #000000BF",
                                    fontSize: {
                                        xs: "16px",
                                        sm: "16px",
                                        md: "24px",
                                        lg: "30px"
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    wordWrap: "break-word",
                                    paddingBottom: 1
                                }}
                            >
                                Something Went Wrong
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: " #000000BF",
                                    fontSize: {
                                        xs: "16px"
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: "450",
                                    textTransform: "capitalize",
                                    wordWrap: "break-word",
                                    paddingBottom: 1
                                }}
                            >
                                Sorry, We can’t find the page you’re looking for.
                            </Typography>
                        </Box>

                        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
                            <Button
                                onClick={() => navigate("/")}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#00999E",
                                    width: "200px",
                                    "&:hover": {
                                        bgcolor: "#00999E"
                                    }
                                }}
                                startIcon={<Icon icon="solar:arrow-left-linear" />}
                            >
                                Go Home
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </Container>
            <Footer />{" "}
        </>
    );
};

export default NotFound;
