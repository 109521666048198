import React, { useState } from "react";
import { Stack, createTheme } from "@mui/material";
import '../globle.css';
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";


const MuiCard = ({ heading, subHeading, id }) => {
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  // const isMediumScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"))
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  return (
    <Stack
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        borderRadius: "5px",
        margin: "4px",
        backgroundColor: "#5cd2d6",
        color: "white",
        transition: "background-color 0.3s",
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        "&:hover": {
          backgroundColor: "#ffffff",
          color: "black",
        },
        padding: isLargeScreen ? '10px' : 'none'
      }}
    >
      <Stack
        spacing={2}
        sx={{
          padding: 2,
        }}
      >
        <Stack
          component="div"
          variant="h5"
          sx={{
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "18px",
              lg: "18px",
            },
            fontWeight: 700,
          }}
        >
          {heading}
        </Stack>
        <Typography

          sx={{
            fontSize: {
              xs: "12px",
              sm: "12px",
              md: "16px",
              lg: "16px",
            },
            textAlign: "start",
            fontWeight: 400,
          }}
        >
          {subHeading}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MuiCard;
