import React, { useEffect } from 'react'
import Navbar from './Navbar'
import CollegeList from 'src/mbbs/College'
import LetsGetStarted from './LetsGetStarted'
import Footer from './Footer'
import EligibilityData from 'src/mbbs/Eligibility'
import MbbsRouteContainer from './grid-component/MbbsRouteContainer'
import { Helmet } from 'react-helmet'
const MbbsIndia = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentSection = window.location.hash.substring(1);
    if (currentPath && currentSection) {
      const targetElement = document.getElementById(currentSection);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
  return (
    <><Helmet>
    <title>Takshasheela Institute: MBBS Abroad Expert</title>
    <meta name="description" content="Secure your MBBS admission abroad with Takshasheela Institute. Get personalized support, find top medical universities, and achieve your medical career goals." />
    <meta name="keywords" content="mbbs in russia for indian students, lowest fee structure for mbbs in russia, Study MBBS Abroad" />
  </Helmet>
      <Navbar />
      <MbbsRouteContainer />

      <EligibilityData />
      <CollegeList />
      <MbbsRouteContainer />
      <LetsGetStarted top="20px" bottom="20px" />
      <Footer />
    </>
  )
}

export default MbbsIndia