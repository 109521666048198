import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import NewsCard from "src/Component/card-components/NewsCard";
import Header from "src/Component/content-component/Header";
import newsData from "src/json/NewsData";

export default function NewsComponent() {
    var settings = {


        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        dots: true,

        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,

                    dots: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 800,
                settings: {

                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    autoplay: true,

                    speed: 3000,
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,

                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },

        ]

    };
    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    padding: "50px 0"
                }}
            >
                <Container maxWidth="lg">
                    <Header
                        color="#00999e"
                        textAlign="center"
                        content="Blogs & Newsletters"
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "700",
                            textTransform: "uppercase",
                            fontSize: "16px",
                            margin: "20px 0",
                            letterSpacing: "1.5px"

                        }}
                    >

                    </Typography>
                    <Slider {...settings}>
                        {
                            newsData?.map((news, index) => {
                                return <NewsCard
                                    key={index}
                                    data={news}
                                />
                            })
                        }
                    </Slider>
                </Container>
            </Box>
        </>
    );
}