import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import OurServicesCard from "./OurServicesCard";
import ourserviceshomedata from "src/json/ourserviceshomedata";
import icon from "../asset/homecounselling.png";
import { useNavigate } from "react-router";
import Header from "src/Component/content-component/Header";

const OurServices = () => {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };
    return (
        <Box
            sx={{
                backgroundColor: "#ffffff",
                padding: {
                    xs: "20px 0px",
                    sm: "30px 0",
                    md: "50px 0",
                    lg: "50px 0"
                },
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
            }}
        >
            <Box sx={{ mb: "20px", }}>
                <Header
                    color="#00999e"
                    textAlign="center"
                    content="Our Services"
                />
                {/* <Typography
                    variant="h5"
                    sx={{
                        color: "#00999e",
                        fontSize: {
                            xs: "22.54px",
                            sm: "30.54px",
                            md: "36.00px"
                        },
                        fontWeight: "600",
                        wordWrap: "break-word",
                        display: "flex",
                        mb: "70px",
                        justifyContent: "center",
                        textAlign: "center"
                    }}
                >
                    ~Our Services~
                </Typography> */}
            </Box>
            <Container>
                <Grid
                    container
                    width={"100%"}
                    padding={"0 20px"}
                    sx={{
                        display: "flex",
                        justifyContent: { md: "space-evenly", sm: "center", xs: "center" }
                    }}
                    spacing={1}
                    gap={1}
                >
                    {ourserviceshomedata?.map((item, index) => (
                        <>
                            <Grid
                                item
                                md={5}
                                sm={12}
                                xs={12}
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <OurServicesCard data={item} id={index + 1} />
                            </Grid>
                        </>
                    ))}
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        cursor: "pointer"
                    }} onClick={() => handleNavigation("/")}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            // aspectRatio: 1 / 1,
                            // width: "80px"
                            // height: "auto"
                        }}

                    >
                        <img src={icon} alt="" style={{
                            //   position: 'absolute',
                            //   top: 0,
                            //   left: 0,
                            width: '80px',
                            height: '80px'
                            // height: '100%',
                            // objectFit: 'cover',
                        }} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            paddingLeft: "20px"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: "center",
                                fontSize: { md: "26px", sm: "20px", xs: "18px" }
                            }}
                        >
                            Don’t Know What to do ?
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                color: "#00999E",
                                fontWeight: "600",
                                textAlign: "center",
                                fontSize: { md: "36px", sm: "26px", xs: "22px" }
                            }}
                        >
                            Get free Counselling
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default OurServices;
