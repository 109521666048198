import img from '../asset/event1.png';
import img2 from '../asset/event2.jpg';
import img3 from '../asset/homeedge.jpg';
import img4 from '../asset/blog4.jpg';
import img5 from '../asset/blog5.jpg';

const newsData = [

  {
    "image": img,
    "date": "Apr 13, 2024",
    "title": "Taksheela and Pearson Join Forces to Transform Education",
    "content": "In a groundbreaking collaboration, Taksheela and Pearson have merged their expertise to revolutionize education. By combining resources and knowledge, they aim to empower learners worldwide, fostering innovation and excellence in education. Together, they envision a future where every student can achieve their fullest potential."
  },
  {
    "image": img2,
    "date": "Apr 20, 2024",
    "title": "Taksheela Appointed as TOEFL iBT Market Ambassador",
    "content": "Taksheela, a leading platform for English language learning, has appointed a TOEFL iBT Market Ambassador to enhance its support for students. Excited about this collaboration, Taksheela aims to further empower students on their English language journey. The partnership promises to bring valuable resources and guidance to TOEFL iBT aspirants."
  }, {
    "image": img3,
    "date": "Apr 28, 2024",
    "title": "Bengal Business Award 2024",
    "url": "https://www.hindustantimes.com/brand-stories/top-mbbs-admission-consultants-for-indian-students-in-delhi-india-study-abroad-101716288472997.html"

  },
  {
    "image": img4,
    "date": "May 8, 2024",
    "title": "Taksheela Shines: Best Study Abroad Consultant in Delhi, India for 2024",
    "url": "https://www.outlookindia.com/hub4business/top-study-abroad-consultant-near-me-in-delhi-india-2024-best-overseas-consultant"

  },
  {
    "image": img5,
    "date": "May 27, 2024",
    "title": "Hindustan Times Highlights Taksheela as Top Immigration Consultant in Delhi, India",
    "url": "https://www.hindustantimes.com/brand-stories/top-immigration-consultant-in-delhi-india-nationwide-immigration-services-101716808260812.html"

  },


]

export default newsData;
