import testprep1 from "../asset/testprep1.png";
import testprep2 from "../asset/testprep2.png";
import testprep3 from "../asset/testprep3.png";
import testprep4 from "../asset/testprep4.png";
import testprep5 from "../asset/testprep5.png";
import testprep6 from "../asset/testprep6.png";

const GmatTestInfo =
{
    title: "GMAT",
    content: "Unlock your dream business school with GMAT™ Focus Edition. Elevate your academic and career prospects. A strong performance opens doors to top-tier schools and prestigious opportunities.",
    image: testprep1,
    cta: "BOOK YOUR GMAT DEMO"
};


const IeltsTestInfo =
{
    title: "IELTS",
    content: "Are you planning to study, work, or migrate abroad? The International English Language Testing System (IELTS) is a globally recognized examination that assesses your English language proficiency.",
    image: testprep2,
    cta: "BOOK YOUR IELTS DEMO"
};

const PteTestInfo =
{
    title: "PTE",
    content: "Welcome to PTE, your gateway to global success. This computer-based English proficiency test offers a quick and reliable assessment of your language skills, opening doors to academic and professional opportunities worldwide.",
    image: testprep3,
    cta: "BOOK YOUR PTE DEMO"
};

const GreTestInfo =
{
    title: "GRE",
    content: "Embarking on your journey to graduate, business, or law school? Whether you're refining your ambitions or solidifying your path, showcasing your best is essential. The GRE General Test empowers you to demonstrate your potential and seize opportunities for success.",
    image: testprep4,
    cta: "BOOK YOUR GRE DEMO"
};

const ToeflTestInfo =
{
    title: "TOEFL",
    content: "Welcome to a world of opportunities with TOEFL! It's your passport to success, whether for studying abroad, advancing in an English-speaking career, or enhancing your language skills.",
    image: testprep5,
    cta: "BOOK YOUR TOEFL DEMO"
};

const SatTestInfo =
{
    title: "SAT",
    content: "The SAT is your ticket to higher education, unlocking doors to top-tier schools and scholarships, shaping your academic and career journey.",
    image: testprep6,
    cta: "BOOK YOUR SAT DEMO"
};

export { IeltsTestInfo, GmatTestInfo, PteTestInfo, GreTestInfo, ToeflTestInfo, SatTestInfo };