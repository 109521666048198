// import { Icon } from '@iconify/react';
import { Box,  Container } from '@mui/material'
import React from 'react'
import Slider from 'react-slick';
// import ButtonComponent from 'src/Component/content-component/ButtonComponent';
import Header from 'src/Component/content-component/Header';
// import testChooseUsData from 'src/json/testChooseUsData';
// import webinar from '../asset/webinarImg.svg'
import WebinarData from './WebinarData';
import NewsCard from 'src/Component/card-components/NewsCard';

const WebinarEvent = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrow: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 800,
                settings: {

                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 3000,
                    autoplaySpeed: 2000,
                }
            },

        ]

    };
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#effdff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}>
                    <Header
                        content={"Webinar & Events"}
                        textAlign={{ md: "center", sm: "left", xs: "left" }}/>
                </Box>
                <Slider {...settings} >
                    {WebinarData?.map((item, index) => {
                        return (
                            <Box sx={{ textAlign: "-webkit-center" }}>
                                <Box>

                                    <NewsCard
                                        imageheight={"250"}
                                        key={index}
                                        data={item}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Slider>
            </Container>
        </Box>
    );
}

export default WebinarEvent
