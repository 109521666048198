import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VisaEnlightenment = ({ data }) => {
  return (
    <Container>

      <Grid
        container
        sx={{
          // backgroundColor: "#EFFDFF",
          padding: { xs: "20px", sm: "40px", lg: "50px" },
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={6}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Box sx={{ width: "500px" }}>
            <img src={data?.image} alt="Visa" width={"100%"} />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          lg={6}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* Title */}
            <Typography
              sx={{
                fontSize: { xs: "25px", sm: "36px", lg: "36px" },
                marginBottom: "20px",
                color: "#00999E",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
            >
              ~Visa Enlightenment~
            </Typography>

            {/* Content Items */}
            {data?.data?.map((step, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: { xs: "15px", sm: "20px", md: "20px", lg: "20px" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <CheckCircleIcon sx={{ color: "#00999E", marginRight: "10px" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "15px", sm: "18px", md: "18px", lg: "20px" },
                  }}
                >
                  {step}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};



export default VisaEnlightenment;
