import k1 from '../../asset/universityKnow/k1.png'
import k2 from '../../asset/universityKnow/k2.png'
import k3 from '../../asset/universityKnow/k3.png'
// import k4 from '../../asset/universityKnow/k4.png'
import k5 from '../../asset/universityKnow/k5.png'
import k6 from '../../asset/universityKnow/k6.png'
const MelbourneKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://research.unimelb.edu.au/study/experience/campus-life"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://www.colleges.unimelb.edu.au/how-to-apply/"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://fbe.unimelb.edu.au/alumni"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://mdhs.unimelb.edu.au/study/current-students/placements"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://study.unimelb.edu.au/connect-with-us/view-our-brochures"
    },


];



const CaliforniaKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://www.berkeley.edu/campus-life/"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://www.berkeley.edu/admissions/"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://career.berkeley.edu/alumni/"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://socialwelfare.berkeley.edu/academics/practicum-placement"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://www.be.berkeley.edu/wp-content/uploads/Cal-Facts-2023-brochure.pdf"
    },


];


const WisconsinKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://www.wisc.edu/student-life/"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://admissions.wisc.edu/how-do-i-apply-to-uw-madison/"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://uwalumni.com/"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://econ.wisc.edu/doctoral/career-placement/"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://wiseli.wisc.edu/resources/guidebooks-brochures/"
    },


];


const YeshivaKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://www.yu.edu/student-life"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://yu.elluciancrmrecruit.com/Apply/Account"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://www.yu.edu/alumni"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://www.yu.edu/sgc/caps/"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://www.yu.edu/sites/default/files/inline-files"
    },


];


const UtahKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://www.suu.edu/life/"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://www.suu.edu/admissions"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://www.suu.edu/alumni/"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://www.suu.edu/hss/mis/job-placement.html"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://www.suu.edu/international/downloads.html"
    },


];


const ColoradoKnowMore = [

    {
        title: "Campus life",
        img: k1,
        url: "https://admissions.colostate.edu/about-csu/campus-life/"
    },
    {
        title: "How to Apply",
        img: k2,
        url: "https://admissions.colostate.edu/"
    },

    {
        title: "Alumni",
        img: k3,
        url: "https://alumni.colostate.edu/"
    },

    {
        title: "Placements Statistics",
        img: k5,
        url: "https://career.colostate.edu/career-outcomes-and-data/"
    },
    {
        title: "Brochure",
        img: k6,
        url: "https://international.colostate.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
    },


];

export { MelbourneKnowMore, CaliforniaKnowMore, WisconsinKnowMore, YeshivaKnowMore, UtahKnowMore, ColoradoKnowMore }