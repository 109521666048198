import { Icon } from "@iconify/react";
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Thankuimg from "../../src/asset/image/thanku.png";

const ThankYouPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Card
            sx={{
              padding: "25px",
              m: 6,
              width: "100%",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
          

            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: {
                      xs: "10px",
                      sm: "40px",
                      md: "40px",
                      lg: "40px",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: " #000000BF",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "24px",
                        lg: "30px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                      paddingBottom:1
                    }}
                  >
                    Thank you for reaching out to us
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: " #000000BF",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "24px",
                        lg: "30px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                      paddingBottom:1
                    }}
                  >
                    Our team will reach out to you
                  </Typography>

                  <Typography
                    variant="h3"
                    sx={{
                      color: " #000000BF",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "26px",
                        lg: "30px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                    }}
                  >
                    Soon!!
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Thankuimg}
                  alt="Thank-you image"
                  style={{ maxWidth: "70%", height: "auto" }}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
              <Button
                onClick={() => navigate("/")}
                variant="contained"
                sx={{
                  backgroundColor: "#00999E",
                  width:'200px',
                  "&:hover": {
                    bgcolor: "#00999E",
                    
                  },
                }}
                startIcon={<Icon icon="solar:arrow-left-linear" />}
              >
                Go Home
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default ThankYouPage;
