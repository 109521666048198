import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Header from "src/Component/content-component/Header";
import ButtonComponent from "src/Component/content-component/ButtonComponent";

const initialDisplayCount = 8;

const Questions = () => {
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const handleExploreAllClick = () => {
    setDisplayCount(data.length);
  };

  const handleShowLessClick = () => {
    setDisplayCount(initialDisplayCount);
  };
  return (
    <Box sx={{ padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" }, background: "#EFFDFF" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <Header
            content={"Frequently Asked Questions"}
            // color="#18181B"
            textAlign={"center"}
          />
        </Box>
        <Grid container spacing={3}>
          {data.slice(0, displayCount).map((item, i) => (
            <Grid item xs={12} md={6}>
              <AccordianCard data={item} i={i} />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // margin: "15px 0",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <ButtonComponent
            borderRadius="30px"
            text={displayCount === initialDisplayCount ? "Explore All Questions" : "Show Less"}
            fontSize="18px"
            padding="10px 10px"
            // href="/"
            width="220px"
            onClick={displayCount === initialDisplayCount ? handleExploreAllClick : handleShowLessClick}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Questions;

const AccordianCard = ({ data, i }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Accordion
      key={i}
      expanded={expanded === `panel${i}`}
      onChange={handleChange(`panel${i}`)}
      sx={{
        background: expanded === `panel${i}` ? "#DDFEFF" : "#fff",
        borderRadius: "15px",
        boxShadow: "5px 11px 14px -4px rgba(0,0,0,0.1)",
        mb: 3,
        ":before": {
          backgroundColor: "transparent ",
        },
        minHeight: "100px",
        alignContent: "center",
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === `panel${i}` ? (
            <IconButton
              sx={{
                background: "#00999E",
                borderRadius: "5px",
                ":hover": { background: "#00999E" },
              }}
            >
              <Icon icon="lucide:minus" color="#fff" />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                background: "#F7F7FF",
                borderRadius: "5px",
                ":hover": { background: "#F7F7FF" },
              }}
            >
              <Icon
                icon="ic:baseline-add"
                color="#00999E"
                className="add-icon"
              />
            </IconButton>
          )
        }
        aria-controls={`panel${i}-content`}
        id={`panel${i}-header`}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: { sm: "20px", xs: "17px" },
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif !important",
                color: "#170F49",
              }}
            >
              {i + 1}. {data?.title}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            ml: 1,
            fontSize: { sm: "18px", xs: "16px" },
            fontWeight: "500",
            fontFamily: "Poppins, sans-serif !important",
            color: "#6F6C90",
          }}
        >
          {data?.Desc}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const data = [
  {
    title: "Why should I consider studying MBBS abroad?",
    Desc: "Studying MBBS abroad offers access to high-quality education, exposure to diverse medical practices, and potential career opportunities worldwide.",
  },
  {
    title: "What entrance exams do I need to take to study MBBS abroad?",
    Desc: "The specific exams vary by country and university. Common exams include the NEET for Indian students and the MCAT for some countries.",
  },
  {
    title: "How long does it take to complete MBBS abroad?",
    Desc: "The duration varies by country but is typically around 5 to 6 years, including both academic study and practical training.",
  },
  {
    title: "Are the degrees recognized internationally?",
    Desc: "Yes, most countries offer degrees that are recognized globally, but it's essential to check the recognition status in the country where you plan to practice.",
  },
  {
    title: "What is the medium of instruction?",
    Desc: "The medium of instruction varies by university and country. Some countries offer English-taught programs, while others may require proficiency in the local language.",
  },

  {
    title: "Are scholarships available for international students?",
    Desc: "Some universities and governments offer scholarships and financial aid to international students. It's advisable to check with the universities for specific details.",
  },
  {
    title: "Can I work part-time while studying MBBS abroad?",
    Desc: "It depends on the country's regulations and your visa status. Some countries allow international students to work part-time during their studies",
  },
  {
    title: "What are the living costs for international students?",
    Desc: "Living costs vary by country and city. It's essential to consider expenses such as accommodation, food, transportation, and health insurance.",
  },
  {
    title: "What are the visa requirements for studying MBBS abroad?",
    Desc: "Visa requirements vary by country. Generally, you'll need to obtain a student visa or permit to study abroad. The process may include providing proof of acceptance from a university, financial documents, and health insurance.",
  },
  {
    title: "Do I need to take an English proficiency test?",
    Desc: "If English is not your first language and the program is taught in English, you may need to provide proof of English proficiency through tests such as the IELTS or TOEFL.",
  },
  {
    title: "What are the accommodation options for international students?",
    Desc: "Accommodation options vary by university and country. They may include on-campus dormitories, off-campus apartments, or homestays.",
  },
  {
    title: "Is it necessary to complete a pre-medical program before MBBS abroad?",
    Desc: "In some countries, a pre-medical program may be required, while in others, students can directly enroll in the MBBS program after completing their secondary education.",
  },
  {
    title: "Are there opportunities for clinical rotations during MBBS abroad?",
    Desc: "Many MBBS programs abroad include clinical rotations in hospitals and healthcare facilities, providing students with hands-on experience in patient care.",
  },
  {
    title: "What are the career prospects after completing MBBS abroad?",
    Desc: "Graduates of MBBS programs abroad have various career opportunities, including medical practice, research, academia, and public health.",
  },
  {
    title: "How do I transfer my medical license to practice in my home country?",
    Desc: "The process for transferring a medical license varies by country. It typically involves passing licensing exams and meeting other requirements set by the medical regulatory authority in your home country",
  },
  {
    title: "Are there opportunities for international medical electives during MBBS abroad?",
    Desc: "Many universities abroad offer international medical electives, allowing students to gain clinical experience in different healthcare settings",
  },
  {
    title: "What is the process for clinical rotations and internships during MBBS abroad?",
    Desc: "Clinical rotations and internships are typically organized by the university in collaboration with affiliated hospitals and healthcare institutions.",
  },
  {
    title: "Is it possible to pursue medical research opportunities during MBBS abroad?",
    Desc: "Many universities abroad offer opportunities for medical research, allowing students to explore their interests and contribute to advancements in healthcare",
  },
  {
    title: "Can I participate in extracurricular activities and clubs while studying MBBS abroad?",
    Desc: "Yes, universities abroad offer a wide range of extracurricular activities, clubs, and student organizations for students to participate in and pursue their interests outside of academics.",
  },
  {
    title: "What are the steps to take after graduating from MBBS abroad?",
    Desc: "After graduating, you may need to complete licensing exams, apply for residency programs, pursue postgraduate studies, or explore career opportunities in your chosen field of medicine.",
  },
];
