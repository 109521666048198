import c1 from '../../asset/coursebanner/1.png'
import c2 from '../../asset/coursebanner/2.png'
import c3 from '../../asset/coursebanner/3.png'
import c4 from '../../asset/coursebanner/4.png'
import c5 from '../../asset/coursebanner/5.png'
import c6 from '../../asset/coursebanner/6.png'
import c7 from '../../asset/coursebanner/7.png'
import c8 from '../../asset/coursebanner/8.png'
import c9 from '../../asset/coursebanner/9.png'

const EngineeringBanner = {
    
    image: c1
};
const CSITBanner = {
    
    image: c2
};
const HsmBanner = {
    
    image: c3
};
const SocialBanner = {
    
    image: c4
};
const BusinessBanner = {
    
    image: c5
};
const PlsBanner = {
    
    image: c6
};
const FaaBanner = {
    
    image: c7
};
const CJBanner = {
    
    image: c8
};
const DesigningBanner = {
    
    image: c9
};


export {EngineeringBanner,CSITBanner,HsmBanner,SocialBanner,BusinessBanner,PlsBanner,FaaBanner,CJBanner,DesigningBanner  }