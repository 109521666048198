import ChooseUsImg1 from "../asset/testchoose1.svg";
import ChooseUsImg2 from "../asset/testchoose2.svg";
import ChooseUsImg3 from "../asset/testchoose3.svg";
import ChooseUsImg4 from "../asset/testchoose4.svg";
import ChooseUsImg5 from "../asset/ChooseUsImg05.svg";

const testChooseUsData = [
    {
        title: "Supportive Environment",
        content: [
            "Experience a supportive and motivating atmosphere that fosters learning and growth."
        ],
        Image: ChooseUsImg5,
    },

    {
        title: "Expert Guidance",
        content: [
            "Benefit from our experienced instructors who provide personalized attention."],
        Image: ChooseUsImg1,
    },
    {
        title: "Proven Results",
        content: [
            "Join a community of successful test-takers who have achieved their goals.",
        ],
        Image: ChooseUsImg2,
    },
    {
        title: "Comprehensive Resources",
        content: [
            "Access a wealth of study materials and practice tests to optimize your preparation.",
        ],
        Image: ChooseUsImg3,
    },
    {
        title: "Flexible Learning",
        content: [
            "Enjoy flexible schedules and learning formats tailored to your needs."
        ],
        Image: ChooseUsImg4,
    },

];

export default testChooseUsData;
