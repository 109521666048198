import Navbar from "./Navbar";
import Footer from "./Footer";
import LetsGetStarted from "./LetsGetStarted";
import StepsForImmigration from "src/immigration/StepsForImmigration";
import WhatIsImmigration from "src/immigration/WhatIsImmigration";
import Banner from "src/immigration/Banner";
import React, { useEffect } from "react";

import ImmigrationQuestions from "src/immigration/ImmigrationFaq";
import CountriesWeServe from "src/immigration/CountriesWeServe";
import EligibilityCriteria from "src/immigration/EligibilityCriteria";
import ServiceWeOffer from "src/immigration/ServiceWeOffer";
import WhyChooseUs from "src/immigration/WhyChooseUs";
import { Helmet } from "react-helmet";
const Immigration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
     <Helmet>
        <title>Immigration Services | Takshasheela Institute</title>
        <meta name="description" content="Simplify your immigration process with Takshasheela Institute. Get expert guidance, comprehensive support, and personalized assistance for a smooth transition abroad." />
        <meta name="keywords" content="australia immigration consultants, best immigration consultant in india, global immigration consultant, immigration agent Canada, top immigration consultants in india" />
      </Helmet>
      <Navbar />
      <Banner />
      <WhatIsImmigration />
      <EligibilityCriteria />
      <CountriesWeServe />
      <ServiceWeOffer />
      <StepsForImmigration />
      <WhyChooseUs />
      
      <ImmigrationQuestions />
      <LetsGetStarted marginTop={6} />
      <Footer />
    </div>
  );
};

export default Immigration;
