import u1 from '../../asset/universitybanner/u1.png'
import u2 from '../../asset/universitybanner/u2.png'
import u3 from '../../asset/universitybanner/u3.png'
import u4 from '../../asset/universitybanner/u4.png'
import u5 from '../../asset/universitybanner/u5.png'
import u6 from '../../asset/universitybanner/u6.png'

const MelbourneBanner = {
    title: "University of Melbourne",
    image: u1
};
const CaliforniaBanner = {
    title: "University of California",
    image: u2
};
const WisconsinBanner = {
    title: "University of Wisconsin-Madison",
    image: u3
};
const YeshivaBanner = {
    title: "Yeshiva University",
    image: u4
};
const UtahBanner = {
    title: "Southern Utah University",
    image: u5
};
const ColoradoBanner = {
    title: "Colorado State University",
    image: u6
};

export { MelbourneBanner, CaliforniaBanner, WisconsinBanner, YeshivaBanner, UtahBanner, ColoradoBanner }