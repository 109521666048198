import React from "react";
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import logo from "../../../asset/TIE_LOGO.png";
import homeIcon from "../../../asset/homeicon.png";
import { useLocation, useNavigate } from "react-router-dom";

const json = [
  {
    title: "Dashboard",
    icon: homeIcon,
    path: "enrollment" // Add path key
  },
  {
    title: "Order",
    icon: homeIcon,
    path: "order" // Adjust path as needed
  }
  // {
  //   title: 'Enrollment-Form',
  //   icon: homeIcon
  // },
  // {
  //   title: 'Precounselling-Form',
  //   icon: homeIcon
  // },
];

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: { md: "block", xs: "none" } }}>
      <Box
        sx={{
          // width: '',
          height: "100%",
          backgroundColor: "#FFFFFF",
          padding: "10px 5px",
          borderRight: "1px solid #ccc",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            width: "220px"
          }}
        >
          <img src={logo} alt="dashboard-logo" width={"100%"} />
        </Box>
        <List
          sx={{
            marginTop: "20px"
          }}
        >
          {json.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                margin: "5px 0",
                padding: "8px  10px",
                fontWeight: "500",
                backgroundColor: location.pathname.includes(
                  item?.path?.toLowerCase()
                )
                  ? "#C9EFFB"
                  : "transparent",
                color: location.pathname.includes(item?.path?.toLowerCase())
                  ? "#000"
                  : "#706C6D",
                "&:hover": {
                  backgroundColor: "#C9EFFB",
                  color: "#000"
                },
                cursor: "pointer",
                transition: "backgroundColor 0.3s linear",
                borderRadius: "5px"
              }}
              onClick={
                // item.title === 'Logout' ? handleLogout :
                () => navigate(`${item?.path?.toLowerCase()}`)
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  marginRight: "8px"
                }}
              >
                <img src={item.icon} alt={item.title} />
              </ListItemIcon>
              <Typography variant="inherit">{item.title}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default NavBar;
