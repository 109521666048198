import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { FormControlLabel, Radio, TextField } from '@mui/material';



const CoursesTable = () => {
    const [rows, setRows] = useState([
        { name: 'GRE', option1: false, option2: false, amount: '' },
        { name: 'GMAT', option1: false, option2: false, amount: '' },
        { name: 'SAT', option1: false, option2: false, amount: '' },
        { name: 'IELTS', option1: false, option2: false, amount: '' },
        { name: 'TOEFL', option1: false, option2: false, amount: '' },
        { name: 'OTHERS', option1: false, option2: false, amount: '' },
        

      ]);
    
      const handleInputChange = (index, event) => {
        const { name, value, type, checked } = event.target;
        const newRows = [...rows];
        if (type === 'radio') {
          const optionName = name.split('-')[0];
          newRows[index] = { ...newRows[index], option1: false, option2: false, [optionName]: checked };
        } else {
          newRows[index] = { ...newRows[index], [name]: value };
        }
        setRows(newRows);
      };

    return (
        <div className="App">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontSize:'20px'}}>Courses</TableCell>
              <TableCell align="center" sx={{fontSize:'20px'}}>FULL COURSE</TableCell>
              <TableCell align="center" sx={{fontSize:'20px'}}>MOCK TEST</TableCell>
              <TableCell align="center" sx={{fontSize:'20px'}}> FEE  (in Rs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{fontSize:'20px'}} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={row.option1}
                        onChange={(e) => handleInputChange(index, e)}
                        name={`option1-${index}`}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell align="center">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={row.option2}
                        onChange={(e) => handleInputChange(index, e)}
                        name={`option2-${index}`}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    size="small"
                    name="amount"
                    value={row.amount}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CoursesTable