export const RulesData = [
    {
        "title": "Eligibility Criteria",
        "content": "Individuals who have completed their MBBS degree from a medical university recognized by the NMC are eligible to practise medicine in India. Minimum 54 months criteria is there + 1-year Internship in same university"
    },
    {
        "title": "Mandatory Screening Test",
        "content": "Graduates of foreign medical universities must appear for the Foreign Medical Graduates Examination (FMGE), candidates must clear the exam with the minimum qualifying marks set by the NMC. This test evaluates the candidate's knowledge and skills in basic medical sciences and clinical subjects. The FMGE is typically held twice a year, and candidates are allowed multiple attempts to clear the exam."
    },
    {
        "title": "Internship Requirement",
        "content": "After passing the FMGE, candidates are required to undergo a compulsory internship of one year in an Indian medical institution recognized by the NMC. The internship provides practical training and exposure to the Indian healthcare system."
    },
    {
        "title": "Registration with State Medical Council",
        "content": "Upon completion of the internship, candidates must register with the State Medical Council (SMC) or the NMC, depending on the regulations of the respective state."
    },
    {
        "title": "State-Specific Regulations",
        "content": "Some states may have additional requirements or regulations for foreign medical graduates practising within their jurisdiction. Candidates should familiarise themselves with the specific rules of the state where they intend to practise."
    }
];


