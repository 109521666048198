
import img1 from "../../asset/ChooseUsImg1.svg";
import img2 from "../../asset/ChooseUsImg2.svg";
import img3 from "../../asset/ChooseUsImg3.svg";
import img4 from "../../asset/ChooseUsImg4.svg";

const MelbourneFeeStructure = [
    {
        course: "Bachelor of Arts",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "12th grade"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 6.5 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹20,00,000 - ₹25,00,000"
            }

        ]
    },
    {
        course: "Bachelor of Science",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "12th grade"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 6.5 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹22,00,000 - ₹27,00,000"
            }

        ]
    },
    {
        course: "Bachelor of Commerce",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "12th grade with mathematics"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹23,00,000 - ₹28,00,000"
            }

        ]
    },
    {
        course: "Bachelor of Engineering",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "12th grade with mathematics and physics"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band "
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹25,00,000 - ₹30,00,000"
            }

        ]
    },
    {
        course: "Master of Business Administration (MBA)",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "Bachelor's degree with  work experience"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹35,00,000 - ₹40,00,000"
            }

        ]
    },
    {
        course: "Master of Computer Science",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "Bachelor's degree in computer science"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band "
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹30,00,000 - ₹35,00,000"
            }

        ]
    },

    {
        course: "Master of Public Health",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "Bachelor's degree in science, social science or health science"
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "₹25,00,000 - ₹30,00,000"
            }

        ]
    },
    {
        course: "PhD in Engineering",
        details: [
            {
                image: img1,
                title: "Eligibility Criteria",
                value: "Master's degree in engineering "
            },
            {
                image: img2,
                title: "English Proficiency Test",
                value: "IELTS - 7.0 band"
            },
            {
                image: img3,
                title: "Approximate Annual Fees (INR)",
                value: "Varies based on research area"
            }

        ]
    },

];
const CaliforniaFeeStructure = [
    {
        "course": "Bachelor of Arts",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Commerce",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores, math and science prerequisites"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Master of Business Administration (MBA)",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GMAT/GRE scores, work experience"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹50,00,000 - ₹60,00,000"
            }
        ]
    },
    {
        "course": "Master of Computer Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree in computer science, GRE scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹35,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Master of Public Health",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GRE scores, Work experience"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹35,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "PhD in Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's or master's degree in engineering, GRE scores, strong research"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹25,00,000 - ₹35,00,000"
            }
        ]
    }
];
const WisconsinFeeStructure = [
    {
        "course": "Bachelor of Arts",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Commerce",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th grade, SAT/ACT scores, math and science prerequisites"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Master of Business Administration (MBA)",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GMAT/GRE scores, work experience"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹50,00,000 - ₹60,00,000"
            }
        ]
    },
    {
        "course": "Master of Computer Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree in computer science, GRE scores"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹35,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Master of Public Health",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GRE scores, work experience"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹35,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "PhD in Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's or master's degree in engineering, GRE scores, strong research"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 90+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹25,00,000 - ₹35,00,000"
            }
        ]
    }
];
const YeshivaFeeStructure = [
    {
        "course": "Bachelor of Arts",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma, SAT/ACT scores, strong academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹40,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma, SAT/ACT scores, strong academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹40,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Commerce",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma, SAT/ACT scores, strong academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹40,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma, SAT/ACT scores, strong academic record, math and science prerequisites"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 80+, IELTS: 6.5+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹40,00,000 - ₹45,00,000"
            }
        ]
    },
    {
        "course": "Master of Business Administration (MBA)",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GMAT/GRE scores, work experience, strong academic and professional record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 100+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹50,00,000 - ₹60,00,000"
            }
        ]
    },
    {
        "course": "Master of Computer Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree in computer science or related field, GRE scores, strong academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 100+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹45,00,000 - ₹55,00,000"
            }
        ]
    },
    {
        "course": "Master of Public Health",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree, GRE scores, relevant work or volunteer experience, strong academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 100+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹45,00,000 - ₹55,00,000"
            }
        ]
    },
    {
        "course": "PhD in Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's or master's degree in engineering or related field, GRE scores, strong research and academic record"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 100+, IELTS: 7.0+"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹35,00,000 - ₹45,00,000"
            }
        ]
    }
];
const UtahFeeStructure = [
    {
        "course": "Bachelor of Arts",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th Grade"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 71, IELTS: 6.0"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹1,500,000 - ₹1,800,000"
            }
        ]
    },
    {
        "course": "Bachelor of Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th Grade"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 71, IELTS: 6.0"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹1,500,000 - ₹1,800,000"
            }
        ]
    },
    {
        "course": "Bachelor of Commerce",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th Grade"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 71, IELTS: 6.0"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹1,500,000 - ₹1,800,000"
            }
        ]
    },
    {
        "course": "Bachelor of Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "12th Grade with Science and maths"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 71, IELTS: 6.0"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹1,800,000 - ₹2,000,000"
            }
        ]
    },
    {
        "course": "Master of Business Administration (MBA)",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹2,000,000 - ₹2,500,000"
            }
        ]
    },
    {
        "course": "Master of Computer Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree in related field"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹2,000,000 - ₹2,500,000"
            }
        ]
    },
    {
        "course": "Master of Public Health",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor's degree"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹2,000,000 - ₹2,500,000"
            }
        ]
    },
    {
        "course": "PhD in Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Master's degree in related field"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img3,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹2,500,000 - ₹3,000,000"
            }
        ]
    }
];
const ColoradoFeeStructure = [
    {
        "course": "Bachelor of Arts",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹20,00,000 - ₹25,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹20,00,000 - ₹25,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Commerce",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹20,00,000 - ₹25,00,000"
            }
        ]
    },
    {
        "course": "Bachelor of Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "High school diploma with science stream"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 79, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹25,00,000 - ₹30,00,000"
            }
        ]
    },
    {
        "course": "Master of Business Administration (MBA)",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor’s degree"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS, GMAT"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 80, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    },
    {
        "course": "Master of Computer Science",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor’s degree in related field"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS, GRE"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 80, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹25,00,000 - ₹30,00,000"
            }
        ]
    },
    {
        "course": "Master of Public Health",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Bachelor’s degree"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 80, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹25,00,000 - ₹30,00,000"
            }
        ]
    },
    {
        "course": "PhD in Engineering",
        "details": [
            {
                "image": img1,
                "title": "Eligibility Criteria",
                "value": "Master’s degree in related field"
            },
            {
                "image": img2,
                "title": "English Proficiency Test",
                "value": "TOEFL, IELTS"
            },
            {
                "image": img3,
                "title": "Test Score Requirement",
                "value": "TOEFL: 80, IELTS: 6.5"
            },
            {
                "image": img4,
                "title": "Approximate Annual Fees (INR)",
                "value": "₹30,00,000 - ₹35,00,000"
            }
        ]
    }
];


export { MelbourneFeeStructure, CaliforniaFeeStructure, WisconsinFeeStructure, YeshivaFeeStructure, UtahFeeStructure, ColoradoFeeStructure }