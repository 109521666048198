import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";

const validationSchema = yup.object({
   
    company: yup.string().required("Company is required"),
    designation: yup.string().required("Designation is required"),
    duration: yup.string().required("Duration is required"),
  });
 
const WorkExperience = () => {
    const [workData, setworkData] = useState([]);
    const formik = useFormik({
        initialValues: {
          company: "",
          designation: "",
          duration: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleAdd(values);
        },
      });
    
      const handleAdd = (newWork) => {
        // Check if the current academic data already exists in the array
        const isDuplicate = workData.some((item) =>
          Object.keys(item).every((key) => item[key] === newWork[key])
        );
    
        if (!isDuplicate) {
            setworkData([...workData, newWork]);
            formik.resetForm();
          } else {
            alert("Duplicate entry detected!");
          }
        };
    
        const handleDelete = (index) => {
            const updatedData = workData.filter((_, i) => i !== index);
            setworkData(updatedData);
          };
        

  return (
    <div> <Box sx={{ paddingTop: "20px"}}>
        
    <Typography varient= "h4" sx={{fontSize:{xs:"20px",sm:'20px',md:'20px',lg:'20px'}, paddingBottom:'10px'}}>WORK EXPERIENCE</Typography>
  </Box>
  <Grid container spacing={2} sx={{ paddingTop: '10px' }}>

  <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="Company"
            name="company"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("company")}
          />
          {formik.touched.company && formik.errors.company ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.company}
            </Typography>
          ) : null}
        </Grid>

    
        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="Designation"
            name="designation"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("designation")}
          />
          {formik.touched.designation && formik.errors.designation ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.designation}
            </Typography>
          ) : null}
        </Grid>



        <Grid item lg={4} xs={12} sm={4} md={4}>
          <TextField
            label="Duration"
            name="duration"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps("duration")}
          />
          {formik.touched.duration && formik.errors.duration ? (
            <Typography variant="body2" sx={{ color: "red" }}>
              {formik.errors.duration}
            </Typography>
          ) : null}
        </Grid>
    
  </Grid>
  <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "14px",
        }}
      >
        <Button variant="contained" onClick={formik.handleSubmit} >
          Add
        </Button>
      </Box>


      {workData.length > 0 && (
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Duration</TableCell>
               
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>{row.designation}</TableCell>
                  <TableCell>{row.duration}</TableCell>
                 
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
  </div>
  )
}

export default WorkExperience