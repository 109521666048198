import React from "react";
import { Grid } from "@material-ui/core";
import { Typography, Container, } from "@mui/material";
import icon1 from "../../asset/teamImg1.png";
import icon2 from "../../asset/teamImg2.png";
import icon3 from "../../asset/teamImg3.png";
import richika from "../../asset/richika.jfif";
import sheetal from "../../asset/sheetal.jfif";
import sumit from "../../asset/teams/Sumit.png";
import Box from "@mui/material/Box";
import background from "../../asset/temaBg.svg";
import "../../globle.css";
import Header from "../content-component/Header";

const OurTeam = () => {
  // const theme = useTheme();
  // const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  // const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   arrows: false,
  //   autoPlay: true,
  //   autoplaySpeed: 3000,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 5000,
  //       settings: "unslick",
  //     },
  //   ],
  // };

  const OurTeamCardData = [
    {
      id: 1,
      icon: icon1,
      name: "Sheetal Jalan, Co-Founder",
      image: sheetal,
      greeting: "Dear Explorers,",
      message:
        "In the early 20th century, Ford manufactured cars in only black. Today, such one-size-fits-all thinking is outdated. Similarly, when embarking on our overseas journey, despite choosing a top player in the industry, we realized the importance of offering a world-class customized solution to each student, respecting their unique dreams and aspirations. At Taksheela, our goal is to inspire every student to attain what they truly desire and deserve!",
    },
    {
      id: 2,
      icon: icon2,
      name: "Richika Singhi, Co-Founder",
      image: richika,
      greeting: "Dear Students,",
      message: `Welcome to a journey that opens the door to a world of possibilities ! 
          As founders, our vision is simple yet profound — to empower you with the wings of knowledge and experience so that you may soar beyond the confines of traditional education. It's about immersing yourself in new cultures, embracing diversity, and sculpting a global perspective that will shape the rest of your life. So, let the world be your classroom, and let your education be the catalyst for a future without borders! `,
    },
    {
      id: 3,
      icon: icon3,
      name: "Sumit Jalan, Director",
      image: sumit,
      greeting: "Dear Trailblazers,",
      message:
        "As the Director of TIE, I invite you to a transformative journey where education transcends boundaries. We're not just about study programs; we're architects of global experiences. Our commitment is your success—personally, academically, and globally. From personalized guidance to world-class partnerships, we pave the way for your global odyssey. This isn't just education; it's an expedition into a future without borders. Welcome to a community that believes in your limitless potential.",
    },
  ];
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        opacity: 0.85,
        background: "#DFDFDF",
        padding: "50px 0",
        backgroundImage: `url("${background}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          direction="column"
        > <Header
                        color="#00999e"
                        textAlign="center"
                        content="Our Team"
                    />
          {/* <Typography
            variant="h1"
            gutterBottom
            sx={{
              color: "#00999E",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            ~Our Team~
          </Typography> */}
          {/* <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
            color: "#333333",
            fontSize: { xs: "14px", sm: "18px", md: "20px", lg: "19px" },
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: { xs: "28.07px", sm: "32.07px", md: "32.07px", lg: "32.07px" },
            textTransform: "capitalize",
            textAlign: "center",
            width: "100%",
            margin: "auto"

        }}
    >
    Lorem ipsum dolor sit amet, usu cu esse possit referrentur, at eam falli deterruisset. No duo populo animal noluisse, enim deterruisset eos et. An mundi noster pri, ea eos quas expetendis scripserit, affert gubergren dissentias et cum.Lorem ipsum dolor sit amet, usu cu esse possit referrentur, at eam falli deterruisset. No duo populo animal noluisse, enim deterruisset eos et. An mundi noster pri, ea eos quas expetendis scripserit, affert gubergren dissentias et cum.
      </Typography> */}

          <Grid container justifyContent="center" alignItems="center">
            {OurTeamCardData.map((card, index) => (
              <Grid key={index} lg={6} md={6} sm={6} xs={12}>
                <Box
                  sx={{
                    padding: "35px 20px",
                    backgroundColor: "#fff",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Box
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "5px",
                        textAlign: "left",
                        padding: "-7 0px",
                        border: "2px solid #00999E",
                        marginBottom: "5px"
                      }}
                    >
                      <img
                        src={card.image}
                        alt={card.name}
                        style={{
                          width: "140px",
                          height: "147px",
                          borderRadius: "5px",
                          textAlign: "left",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      color: "#00999E",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "125.259%",
                      textAlign: "center"
                    }}
                  >
                    {card.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: "black",
                      fontSize: 17,
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: " 20px 0 ",
                    }}
                  >
                    {card.greeting}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: "black",
                      fontSize: 15,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                    className="text-justify-hyphen-none"
                  >
                    {card.message}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurTeam;
