import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Header from 'src/Component/content-component/Header'

const UniversityRecruiters = ({ data }) => {
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#effdff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={"Popular Recruiters"}
                        textAlign={{ md: "center", sm: "center", xs: "center" }}
                    />
                </Box>
                <Box >
                    <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 15 }} sx={{ display: "flex", justifyContent: "center" }}>
                        {data?.map((item, index) => (

                            <Grid item xs={15} sm={6} md={3} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                                <Box>
                                    <Box
                                        sx={{
                                            width: "210px",
                                            height: "250px",
                                            bgcolor: "#ffffff",
                                            borderRadius: "10px",
                                            // border: "2px solid #00999E",
                                            boxShadow: " rgba(100, 100, 111, 0.2) 7px 7px 29px 0px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center", alignItems: "center"
                                        }}
                                    >
                                        <Box sx={{ width: "110px" }}>
                                            <img src={item?.Image} width={"100%"} alt="immigration-benefit" />
                                        </Box>
                                        <Box sx={{ width: "180px", height: "100px", padding: "10px 0" }}>
                                            <Typography variant="h6" textAlign={"center"} sx={{ color: "#00999E" }}>{item?.title}</Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </Box>
            </Container>
        </Box>
    )
}

export default UniversityRecruiters
