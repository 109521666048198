const MelbourneInfo = {
    name: "University of Melbourne",
    location: "Melbourne, Victoria, Australia",
    type: "Public research university",
    enrollment: "Approximately 50,000",
    indian: "Around 2,000 (approximate)",
    students: "Approximately 52,000 (as of 2022)",
    url: "https://www.unimelb.edu.au/",
};
const CaliforniaInfo = {
    name: "University of California, Berkeley",
    location: "Berkeley, California, USA",
    type: "Public Research University",
    enrollment: "Approximately 46000",
    indian: "Over 500",
    students: "Approximately 43,000",
    url: "https://www.berkeley.edu",
};
const WisconsinInfo = {
    name: "University of Wisconsin-Madison",
    location: "Madison, Wisconsin, USA",
    type: "Public Research University",
    enrollment: "48,557",
    indian: "Over 500",
    students: "27,527",
    url: "https://www.wisc.edu",
};
const YeshivaInfo = {
    name: "Yeshiva University",
    location: "New York City, New York, USA",
    type: "Private Research University",
    enrollment: "7800",
    indian: "NA",
    students: "6,500",
    url: "http://www.yu.edu",
};
const UtahInfo = {
    name: "Southern Utah University (SUU)",
    location: "Cedar City, Utah",
    type: "Public University",
    enrollment: "15,000",
    indian: "50+",
    students: "14,330",
    url: "https://www.suu.edu",
};
const ColoradoInfo = {
    name: "Colorado State University",
    location: "Fort Collins, Colorado",
    type: "Public",
    enrollment: "35,000",
    indian: "100+",
    students: "33,364",
    url: "http://www.colostate.edu",
};

export { MelbourneInfo, CaliforniaInfo, WisconsinInfo, YeshivaInfo, UtahInfo, ColoradoInfo }