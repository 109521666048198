import React from "react";
import { Box, Container, Card, Grid } from "@mui/material";
import { Typography } from "@material-ui/core";

const CostofStudy = ({ data }) => {
  return (
    <Box pt={6} pb={8} sx={{ backgroundColor: "#ffffff" }}>
      <Container maxWidth="lg">

        <Grid
          container
          spacing={2}
          columns={{ xs: 12, sm: 12, md: 15, lg: 15 }} sx={{ display: "flex", justifyContent: "center" }}

        >
          {data?.map((data, index) => {
            return (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    key={index}
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "100%",
                        bgcolor: "#00999E1F",
                        width: "160px",
                        height: "160px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={data?.image}
                        alt=""
                        style={{ maxWidth: "100%", maxHeight: "80%" }}
                      />
                    </Box>
                    <Typography
                      variant="p"
                      style={{
                        fontFamily: "Poppins",
                        color: "#00999E",
                        fontSize: "18px",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {data?.title}
                    </Typography>
                    <Typography
                      variant="p"
                      style={{
                        fontFamily: "Poppins",
                        color: "#18181B",
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: 550,
                      }}
                    >
                      {data?.cost}
                    </Typography>
                  </Card>
                </Grid>
              </>
            );
          })}
        </Grid>

      </Container>
    </Box>
  );
};

export default CostofStudy;
