import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Footer from 'src/Component/Footer';
import Navbar from 'src/Component/Navbar';
import u1 from '../../asset/universityselect/u1.png';
import u2 from '../../asset/universityselect/u2.png';
import u3 from '../../asset/universityselect/u3.png';
import u4 from '../../asset/universityselect/u4.png';
import u5 from '../../asset/universityselect/u5.png';
import u6 from '../../asset/universityselect/u6.png';
import { useNavigate } from 'react-router';
import BackRouteContainer from 'src/Component/grid-component/BackButtonComponent';
import logo from '../../asset/backuniversity.png'

// Sample data for universities
const universities = [
    { id: 1, name: 'University of Melbourne, Australia', image: u1, path: "/study-abroad/universities/melbourne" },
    { id: 2, name: 'University of California, Berkely, California', image: u2, path: "/study-abroad/universities/california" },
    { id: 3, name: 'University of Wisconsin-Madison, Wisconsin', image: u3, path: "/study-abroad/universities/wisconsin" },
    { id: 4, name: 'Yeshiva University, New York City', image: u4, path: "/study-abroad/universities/yeshiva" },
    { id: 5, name: 'Southern Utah University, Cedar Utah', image: u5, path: "/study-abroad/universities/utah" },
    { id: 6, name: 'Colorado State University, Fort Collins Colorado', image: u6, path: "/study-abroad/universities/colorado" },

];

const SelectUniversities = () => {
    const navigate = useNavigate();
    return (
        <Box>
            <Navbar />
            <BackRouteContainer
                logo={logo} path={"/study-abroad"} title={"Study Abroad Page"} />

            <Container sx={{ padding: '40px' }}>
                <Grid container spacing={4}>
                    {universities?.map((university) => (
                        <Grid item key={university?.id} xs={12} sm={6} md={6} lg={6}>
                            <Box
                                sx={{
                                    height: { lg: '420px', xs: '300px', sm: "300px", md: "350px" },
                                    cursor: 'pointer',
                                    border: '1px solid #00999E',
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                                onClick={() => {
                                    navigate(university?.path);
                                }}
                            >
                                <img src={university?.image} alt={university?.name} width={'100%'} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '20px 10px'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: '550', textAlign: "center" }}>
                                        {university?.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Box sx={{ mb: "30px" }}>
                <BackRouteContainer
                    logo={logo} path={"/study-abroad"} title={"Study Abroad Page"} />
            </Box>
            <Footer />
        </Box>
    );
};

export default SelectUniversities;
