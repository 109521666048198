

import us1 from '../../../asset/CountryUniversities/us/us1.png'
import us2 from '../../../asset/CountryUniversities/us/us2.png'
import us3 from '../../../asset/CountryUniversities/us/us3.png'
import us4 from '../../../asset/CountryUniversities/us/us4.png'
import us5 from '../../../asset/CountryUniversities/us/us5.png'
import us6 from '../../../asset/CountryUniversities/us/us6.png'
import us7 from '../../../asset/CountryUniversities/us/us7.png'
import us8 from '../../../asset/CountryUniversities/us/us8.png'
import us9 from '../../../asset/CountryUniversities/us/us9.png'
import us10 from '../../../asset/CountryUniversities/us/us10.png'
import uk1 from '../../../asset/CountryUniversities/uk/uk1.png'
import uk2 from '../../../asset/CountryUniversities/uk/uk2.png'
import uk3 from '../../../asset/CountryUniversities/uk/uk3.png'
import uk4 from '../../../asset/CountryUniversities/uk/uk4.png'
import uk5 from '../../../asset/CountryUniversities/uk/uk5.png'
import uk6 from '../../../asset/CountryUniversities/uk/uk6.png'
import uk7 from '../../../asset/CountryUniversities/uk/uk7.png'
import uk8 from '../../../asset/CountryUniversities/uk/uk8.png'
import uk9 from '../../../asset/CountryUniversities/uk/uk9.png'
import ire1 from '../../../asset/CountryUniversities/ireland/ire1.png'
import ire2 from '../../../asset/CountryUniversities/ireland/ire2.png'
import ire3 from '../../../asset/CountryUniversities/ireland/ire3.png'
import ire4 from '../../../asset/CountryUniversities/ireland/ire4.png'
import ire5 from '../../../asset/CountryUniversities/ireland/ire5.png'
import ire6 from '../../../asset/CountryUniversities/ireland/ire6.png'
import ire7 from '../../../asset/CountryUniversities/ireland/ire7.png'
import ire8 from '../../../asset/CountryUniversities/ireland/ire8.png'
import can1 from '../../../asset/CountryUniversities/canada/can1.png'
import can2 from '../../../asset/CountryUniversities/canada/can2.png'
import can3 from '../../../asset/CountryUniversities/canada/can3.png'
import can4 from '../../../asset/CountryUniversities/canada/can4.png'
import can5 from '../../../asset/CountryUniversities/canada/can5.png'
import can6 from '../../../asset/CountryUniversities/canada/can6.png'
import can7 from '../../../asset/CountryUniversities/canada/can7.png'
import can8 from '../../../asset/CountryUniversities/canada/can8.png'
import ger1 from '../../../asset/CountryUniversities/germany/ger1.png'
import ger2 from '../../../asset/CountryUniversities/germany/ger2.png'
import ger3 from '../../../asset/CountryUniversities/germany/ger3.png'
import ger4 from '../../../asset/CountryUniversities/germany/ger4.png'
import ger5 from '../../../asset/CountryUniversities/germany/ger5.png'
import ger6 from '../../../asset/CountryUniversities/germany/ger6.png'
import ger7 from '../../../asset/CountryUniversities/germany/ger7.png'
import ger8 from '../../../asset/CountryUniversities/germany/ger8.png'
import aus1 from '../../../asset/CountryUniversities/australia/aus1.png'
import aus2 from '../../../asset/CountryUniversities/australia/aus2.png'
import aus3 from '../../../asset/CountryUniversities/australia/aus3.png'
import aus4 from '../../../asset/CountryUniversities/australia/aus4.png'
import aus5 from '../../../asset/CountryUniversities/australia/aus5.png'
import aus6 from '../../../asset/CountryUniversities/australia/aus6.png'
import aus7 from '../../../asset/CountryUniversities/australia/aus7.png'
import aus8 from '../../../asset/CountryUniversities/australia/aus8.png'
import sin1 from '../../../asset/CountryUniversities/singapore/sin1.png'
import sin2 from '../../../asset/CountryUniversities/singapore/sin2.png'
import sin3 from '../../../asset/CountryUniversities/singapore/sin3.png'
import sin4 from '../../../asset/CountryUniversities/singapore/sin4.png'
import sin5 from '../../../asset/CountryUniversities/singapore/sin5.png'
import sin6 from '../../../asset/CountryUniversities/singapore/sin6.png'
import sin7 from '../../../asset/CountryUniversities/singapore/sin7.png'
import sin8 from '../../../asset/CountryUniversities/singapore/sin8.png'
import ity1 from '../../../asset/CountryUniversities/italy/ity1.png'
import ity2 from '../../../asset/CountryUniversities/italy/ity2.png'
import ity3 from '../../../asset/CountryUniversities/italy/ity3.png'
import ity4 from '../../../asset/CountryUniversities/italy/ity4.png'
import ity5 from '../../../asset/CountryUniversities/italy/ity5.png'
import ity6 from '../../../asset/CountryUniversities/italy/ity6.png'
import ity7 from '../../../asset/CountryUniversities/italy/ity7.png'
import ity8 from '../../../asset/CountryUniversities/italy/ity8.png'
import frn1 from '../../../asset/CountryUniversities/france/frn1.png'
import frn2 from '../../../asset/CountryUniversities/france/frn2.png'
import frn3 from '../../../asset/CountryUniversities/france/frn3.png'
import frn4 from '../../../asset/CountryUniversities/france/frn4.png'
import frn5 from '../../../asset/CountryUniversities/france/frn5.png'
import frn6 from '../../../asset/CountryUniversities/france/frn6.png'
import frn7 from '../../../asset/CountryUniversities/france/frn7.png'
import frn8 from '../../../asset/CountryUniversities/france/frn8.png'
import dub1 from '../../../asset/CountryUniversities/dubai/dub1.png'
import dub2 from '../../../asset/CountryUniversities/dubai/dub2.png'
import dub3 from '../../../asset/CountryUniversities/dubai/dub3.png'
import dub4 from '../../../asset/CountryUniversities/dubai/dub4.png'
import dub5 from '../../../asset/CountryUniversities/dubai/dub5.png'
import dub6 from '../../../asset/CountryUniversities/dubai/dub6.png'
import dub7 from '../../../asset/CountryUniversities/dubai/dub7.png'
import dub8 from '../../../asset/CountryUniversities/dubai/dub8.png'




const USAUniversities =
{
    heading: "Popular Universities to Study in  the USA",
    content: [
        {
            title: "Harvard University",
            items: [

                "Harvard University - Cambridge, Massachusetts",
            ],
            Image: us1,
        },
        {
            title: "MIT",
            items: ["Massachusetts Institute of Technology (MIT) - Cambridge, Massachusetts"],
            Image: us2,
        },
        {
            title: "Stanford University",
            items: ["Stanford University - Stanford, California"],
            Image: us3,
        },
        {
            title: "Caltech",
            items: ["California Institute of Technology (Caltech) - Pasadena, California"],
            Image: us4,
        },
        {
            title: "UC Berkeley",
            items: ["University of California, Berkeley (UC Berkeley) - Berkeley, California"],
            Image: us5,
        },
        {
            title: "Princeton University",
            items: ["Princeton University - Princeton, New Jersey"],
            Image: us6,
        },
        {
            title: "Yale University",
            items: ["Yale University - New Haven, Connecticut"],
            Image: us7,
        },
        {
            title: "Columbia University",
            items: ["Columbia University - New York City, New York"],
            Image: us8,
        },
        {
            title: "University of Chicago",
            items: ["University of Chicago - Chicago, Illinois"],
            Image: us9,
        },
        {
            title: "UPenn",
            items: ["University of Pennsylvania (UPenn) - Philadelphia, Pennsylvania"],
            Image: us10,
        },

    ]
};
const UKUniversities =
{
    heading: "Popular Universities to Study in  the UK",
    content: [
        {
            title: "Oxford University",
            items: [

                "Oxford University, Oxford",
            ],
            Image: uk1,
        },
        {
            title: "Cambridge University",
            items: ["Cambridge University, Cambridge"],
            Image: uk2,
        },
        {
            title: "Imperial College London",
            items: ["Imperial College London, London"],
            Image: uk3,
        },
        {
            title: "LSE",
            items: ["London School of Economics (LSE), London"],
            Image: uk4,
        },
        {
            title: "UCL",
            items: ["University College London (UCL), London"],
            Image: uk5,
        },
        {
            title: "University of Manchester,",
            items: ["University of Manchester, Manchester"],
            Image: uk6,
        },
        {
            title: "University of Edinburgh",
            items: ["University of Edinburgh, Edinburgh"],
            Image: uk7,
        },
        {
            title: "University of Warwick",
            items: ["University of Warwick, Coventry"],
            Image: uk8,
        },
        {
            title: "University of Bristol",
            items: ["University of Bristol, Bristol"],
            Image: uk9,
        },

    ]
};
const IrelandUniversities =
{
    heading: "Popular Universities to Study in  the Ireland",
    content: [
        {
            title: "Trinity College Dublin",
            items: [

                "Trinity College Dublin - Dublin",
            ],
            Image: ire1,
        },
        {
            title: "UCD",
            items: ["University College Dublin (UCD) - Dublin"],
            Image: ire2,
        },
        {
            title: "NUIG",
            items: ["National University of Ireland, Galway (NUIG) - Galway"],
            Image: ire3,
        },
        {
            title: "UCC",
            items: ["University College Cork (UCC) - Cork"],
            Image: ire4,
        },
        {
            title: "DCU",
            items: ["Dublin City University (DCU) - Dublin"],
            Image: ire5,
        },
        {
            title: "University of Limerick",
            items: ["University of Limerick - Limerick"],
            Image: ire6,
        },
        {
            title: "Maynooth University",
            items: ["Maynooth University - Maynooth"],
            Image: ire7,
        },
        {
            title: "TU Dublin",
            items: ["Technological University Dublin - Dublin"],
            Image: ire8,
        },

    ]
};
const CanadaUniversities =
{
    heading: "Popular Universities to Study in  the Canada",
    content: [
        {
            title: "University of Toronto",
            items: [

                "University of Toronto - Toronto, Ontario",
            ],
            Image: can1,
        },
        {
            title: "UBC",
            items: ["University of British Columbia (UBC) - Vancouver, British Columbia"],
            Image: can2,
        },
        {
            title: "McGill University",
            items: ["McGill University - Montreal, Quebec"],
            Image: can3,
        },
        {
            title: "University of Alberta",
            items: ["University of Alberta - Edmonton, Alberta"],
            Image: can4,
        },
        {
            title: "University of Waterloo",
            items: ["University of Waterloo - Waterloo, Ontario"],
            Image: can5,
        },
        {
            title: "McMaster University",
            items: ["McMaster University - Hamilton, Ontario"],
            Image: can6,
        },
        {
            title: "University of Calgary",
            items: ["University of Calgary - Calgary, Alberta"],
            Image: can7,
        },
        {
            title: "University of Ottawa",
            items: ["University of Ottawa - Ottawa, Ontario"],
            Image: can8,
        },

    ]
};
const GermanyUniversities =
{
    heading: "Popular Universities to Study in  the Germany",
    content: [
        {
            title: "TUM",
            items: [

                "Technical University of Munich (TUM) - Munich",
            ],
            Image: ger1,
        },
        {
            title: "LMUM",
            items: ["Ludwig Maximilian University of Munich - Munich"],
            Image: ger2,
        },
        {
            title: "Heidelberg University",
            items: ["Heidelberg University - Heidelberg"],
            Image: ger3,
        },
        {
            title: "Humboldt University",
            items: ["Humboldt University of Berlin - Berlin"],
            Image: ger4,
        },
        {
            title: "Freie Universität Berlin ",
            items: ["vFreie Universität Berlin - Berlin"],
            Image: ger5,
        },
        {
            title: "RWTH",
            items: ["RWTH Aachen University - Aachen"],
            Image: ger6,
        },
        {
            title: "University of Freiburg",
            items: ["University of Freiburg - Freiburg"],
            Image: ger7,
        },
        {
            title: "University of Bonn",
            items: ["University of Bonn - Bonn"],
            Image: ger8,
        },

    ]
};
const AustraliaUniversities =
{
    heading: "Popular Universities to Study in  the Australia",
    content: [
        {
            title: "ANU",
            items: [

                "Australian National University (ANU) - Canberra",
            ],
            Image: aus1,
        },
        {
            title: "University of Melbourne",
            items: ["University of Melbourne - Melbourne"],
            Image: aus2,
        },
        {
            title: "University of Sydney",
            items: ["University of Sydney - Sydney"],
            Image: aus3,
        },
        {
            title: "UQ",
            items: ["University of Queensland (UQ) - Brisbane"],
            Image: aus4,
        },
        {
            title: "UNSW",
            items: ["University of New South Wales (UNSW) - Sydney"],
            Image: aus5,
        },
        {
            title: "Monash University",
            items: ["Monash University - Melbourne"],
            Image: aus6,
        },
        {
            title: "UWA",
            items: ["University of Western Australia (UWA) - Perth"],
            Image: aus7,
        },
        {
            title: "University of Adelaide",
            items: ["University of Adelaide - Adelaide"],
            Image: aus8,
        },

    ]
};
const SingaporeUniversities =
{
    heading: "Popular Universities to Study in  the Singapore",
    content: [
        {
            title: "NUS",
            items: [

                "National University of Singapore (NUS) - Singapore",
            ],
            Image: sin1,
        },
        {
            title: "NTU",
            items: ["Nanyang Technological University (NTU) - Singapore"],
            Image: sin2,
        },
        {
            title: "SMU",
            items: ["Singapore Management University (SMU) - Singapore"],
            Image: sin3,
        },
        {
            title: "SUTD",
            items: ["Singapore University of Technology and Design (SUTD) - Singapore"],
            Image: sin4,
        },
        {
            title: "SIT",
            items: ["Singapore Institute of Technology (SIT) - Singapore"],
            Image: sin5,
        },
        {
            title: "JCUS",
            items: ["James Cook University Singapore - Singapore"],
            Image: sin6,
        },
        {
            title: "PSB",
            items: ["PSB Academy - Singapore"],
            Image: sin7,
        },
        {
            title: "SIM",
            items: ["SIM University - Singapore"],
            Image: sin8,
        },

    ]
};
const ItalyUniversities =
{
    heading: "Popular Universities to Study in  the Italy",
    content: [
        {
            title: "Bologna",
            items: [

                "University of Bologna - Bologna",
            ],
            Image: ity1,
        },
        {
            title: "Rome",
            items: ["Sapienza University of Rome - Rome"],
            Image:ity2,
        },
        {
            title: "Milan",
            items: ["University of Milan - Milan"],
            Image:ity3,
        },
        {
            title: "Padua",
            items: ["University of Padua - Padua"],
            Image:ity4,
        },
        {
            title: "Florence",
            items: ["University of Florence - Florence"],
            Image:ity5,
        },
        {
            title: "Milan",
            items: ["Polytechnic University of Milan - Milan"],
            Image:ity6,
        },
        {
            title: "Pisa",
            items: ["University of Pisa - Pisa"],
            Image:ity7,
        },
        {
            title: "Turin",
            items: ["University of Turin - Turin"],
            Image:ity8,
        },

    ]
};
const FranceUniversities =
{
    heading: "Popular Universities to Study in  the France",
    content: [
        {
            title: "Paris-Saclay",
            items: [

                "University of Paris-Saclay",
            ],
            Image: frn1,
        },
        {
            title: "PSL",
            items: ["Paris Sciences et Lettres - PSL Research University Paris"],
            Image: frn2,
        },
        {
            title: "Paris",
            items: ["University of Paris"],
            Image: frn3,
        },
        {
            title: "Sorbonne",
            items: ["Sorbonne University"],
            Image: frn4,
        },
        {
            title: "Ecole",
            items: ["École Polytechnique"],
            Image: frn5,
        },
        {
            title: "École Normale",
            items: ["École Normale Supérieure de Lyon"],
            Image: frn6,
        },
        {
            title: "Lyon",
            items: ["University of Lyon"],
            Image: frn7,
        },
        {
            title: "Strasboury",
            items: ["University of Strasbourg"],
            Image: frn8,
        },
        

    ]
};
const DubaiUniversities =
{
    heading: "Popular Universities to Study in  the Dubai",
    content: [
        {
            title: "University of Dubai",
            items: [

                "University of Dubai, Dubai",
            ],
            Image: dub1,
        },
        {
            title: "AUD",
            items: ["American University in Dubai (AUD), Dubai"],
            Image: dub2,
        },
        {
            title: "Zayed University",
            items: ["Zayed University: Dubai and Abu Dhabi"],
            Image: dub3,
        },
        {
            title: "AUS",
            items: ["American University of Sharjah (AUS), Sharjah"],
            Image: dub4,
        },
        {
            title: "University of Wollongong",
            items: ["University of Wollongong in Dubai, Dubai"],
            Image: dub5,
        },
        {
            title: "Murdoch University",
            items: ["Murdoch University Dubai, Dubai"],
            Image: dub6,
        },
        {
            title: "Canadian University",
            items: ["Canadian University Dubai, Dubai"],
            Image: dub7,
        },
        {
            title: "Middlesex University",
            items: ["Middlesex University Dubai, Dubai"],
            Image: dub8,
        },
       

    ]
};





export { USAUniversities, UKUniversities, IrelandUniversities, CanadaUniversities, GermanyUniversities, AustraliaUniversities, SingaporeUniversities, ItalyUniversities, FranceUniversities, DubaiUniversities };