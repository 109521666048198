import React from "react";
import { Box, TextField } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/[0-9]{10}$/, "Phone number must be exactly 10 digits ")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

function FormComponent({ handleCloseDialog, setOpenDialog }) {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setLoading(true);

    const { name, email, phone } = values;

    const serviceId = "service_i9rs2hj";
    const templateId = "template_ueblkrv";
    const publicKey = "ncDIHkoEOeITGgzJI";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "TIE",
      phone: phone,
    };
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((res) => {
        toast.success("Email sent successfully!");

        resetForm();
        setTimeout(() => {
          handleCloseDialog();
        }, 1000);
        navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Failed to send email");
      })
      .finally(() => {
        setSubmitting(false);
      });
    setLoading(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Formik
        initialValues={{ name: "", email: "", phone: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            style={{
              padding: "20px",
              display: "grid",
              alignContent: "space-between",
            }}
          >
            <Field
              as={TextField}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              color="#eeeeee"
              name="name"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              helperText={<ErrorMessage name="name" component="div" style={{ color: 'red' }} />}
            />
            <Field
              as={TextField}
              id="outlined-basic"
              label="Phone"
              type="text"
              variant="outlined"
              color="#eeeeee"
              name="phone"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              helperText={<ErrorMessage name="phone" component="div" style={{ color: 'red' }} />}
            />
            <Field
              as={TextField}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              color="#eeeeee"
              type="email"
              name="email"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              helperText={<ErrorMessage name="email" component="div" style={{ color: 'red' }} />}
            />
            <Box
              component="span"
              style={{
                color: "white",
                backgroundColor: "#00999e",
                marginBottom: "5px",
                padding: isSmallScreen ? "7px" : "10px",
              }}
              clone
            >
              <LoadingButton
                type="submit"
                disabled={isSubmitting}
                loading={loading}
                sx={{
                  fontSize: "16px",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "#ffffff"
                  }
                }}
              >
                {!loading && "Schedule A Meeting"}
              </LoadingButton>
              {/* <Button type="submit" style={{ fontSize: "16px" }} disabled={isSubmitting}>
                Schedule A Meeting
              </Button> */}
            </Box>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
}

export default FormComponent;
