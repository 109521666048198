import webinar from "../../asset/dashboardslider1.png";
import img from "../../asset/dashboardslider2.png";
import img2 from "../../asset/dashboardslider3.png";

const DashBoardSlider = [
    {
        date: "Jan 1, 2024",
        title: "IELTS 2024 : Eligibility, Syllabus, Question Papers.",
        content: [
            "IELTS exam is an English language test that is required of international students who seek to study"
        ],
        image: webinar
    },

    {
        date: "Jan 1, 2024",

        title: "GMAT 2024 : Eligibility, Syllabus, Question Papers.",
        content: [
            "GMAT exam is an English language test that is required of international students who seek to study",
        ],
        image: img
    },
    {
        date: "Jan 1, 2024",

        title: "IELTS 2024 : Eligibility, Syllabus, Question Papers.",
        content: [
            "IELTS exam is an English language test that is required of international students who seek to study"
        ],
        image: img2
    }
];
export default DashBoardSlider;
