import { Box, Button, Container, Grid, TextField } from "@material-ui/core";
import { createTheme, useMediaQuery } from "@mui/material";
import { useRef } from "react";
import { default as background1 } from "../asset/homeBanner/homeBannermbbs.png";
import emailjs from "@emailjs/browser";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import "react-multi-carousel/lib/styles.css";
import bg from "../asset/homeBanner/homeBannerImmigration.png";
import bg2 from "../asset/homeBanner/homeBannerTestprep.png";
import bg4 from "../asset/homeBanner/homeBannerStudyAbroad.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
});

function StartJourney() {
  const navigate = useNavigate();
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xl: 1920,
      },
    },
  });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isXLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xl"));
  // const isDoubleXLargeScreen = useMediaQuery((theme) =>
  //   theme.breakpoints.down("xxl")
  // );

  const startJourney = [
    {
      id: 1,
      title: "Broadens your horizons and opens up a world of opportunities.",
      subtitle: "",
      image: bg4,
      imageSmall: bg4,
    },

    {
      id: 2,
      title: "Immigration builds nations through diversity.",
      subtitle: "",
      image: bg,
      imageSmall: bg,
    },
    {
      id: 3,
      title: "Effective MBBS guidance for navigating the complexities of medical admissions",
      subtitle: "",
      image: background1,
      imageSmall: background1,
    },
    {
      id: 4,
      title: "Success hinges on being prepared before opportunities arise.",
      subtitle: "",
      image: bg2,
      imageSmall: bg2,
    },

  ];

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // Your emailjs logic
      const serviceId = "service_i9rs2hj";
      const templateId = "template_ueblkrv";
      const publicKey = "ncDIHkoEOeITGgzJI";

      const templateParems = {
        from_name: values.name,
        from_email: values.email,
        to_name: "TIE",
        phone: values.phone,
      };

      //send the email using Email JS
      emailjs
        .send(serviceId, templateId, templateParems, publicKey)
        .then((res) => {
          toast.success("Email sent successfully!");

          // Log form data to the console
          console.log("Form Data:", values);

          // Reset form fields after successful submission
          resetForm();

          navigate("/thank-you");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    },
  });

  const carouselRef = useRef(null);
  const CustomDot = ({ ...rest }) => {
    const { active, index } = rest;
    return (
      <div
        onClick={() => carouselRef.current.goToSlide(index)}
        style={{
          border: active ? "3.5px solid #03999E" : "3.5px solid #8888",
          margin: 2,
          width: active ? "26px" : 0,
          borderRadius: 20,
          marginBottom: 16,
        }}
      />
    );
  };

  const settings = {
    fade: true,
    width: "100vw",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false, 
    customPaging: function (i) {
      return <CustomDot />;
    },
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Slider {...settings}>
        {startJourney.map((card) => (
          <div key={card.id} style={{ width: "1940px" }}>
            <div
              style={{
                backgroundImage: isSmallScreen
                  ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${card.imageSmall}")`
                  : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${card.image}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                flexShrink: 0,
                height: isSmallScreen
                  ? "60vh"
                  : isMediumScreen
                    ? "70vh"
                    : isLargeScreen
                      ? "70vh"
                      : isXLargeScreen
                        ? "75vh"
                        : "75vh",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Container maxWidth="2560px">
                <Grid
                  container
                  style={{
                    width: "100vw",
                  }}
                >
                  <Grid
                    item
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <Container
                      style={{
                        paddingLeft: {
                          xl: "100px",
                          md: "20px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: 33.048,
                            sm: 35.048,
                            md: 38.048,
                            lg: 42.048,
                          },
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: 1.3,
                          paddingTop: {
                            xs: "10px",
                            sm: "2px",
                            md: "15px",
                            lg: "20px",
                          },
                          paddingBottom: {
                            xs: "10px",
                            sm: "2px",
                            md: "15px",
                            lg: "20px",
                          },
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "10vh",
                            lg: "15vh",
                            xl: "20vh",
                            xxl: isXLargeScreen ? "30vh" : "15vh",
                          },
                          width: isSmallScreen
                            ? "100%"
                            : isMediumScreen
                              ? "100%"
                              : "90%",
                        }}
                      >
                        {card.title}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#eeeeee",
                          fontSize: { xs: 12, sm: 12, md: 20, lg: 20 },
                          fontWeight: 500,
                          lineHeight: 1.2,
                          width: "100%",
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "10vh",
                            lg: "15vh",
                            xl: "20vh",
                            xxl: "30vh",
                          },
                        }}
                      >
                        {card.subtitle}
                      </Typography>
                    </Container>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        ))}
      </Slider>

      <Container
        sx={{
          display: { md: "none", xs: "block", lg: "none" },
          marginLeft: { lg: "100px", md: "200px" },
        }}
      >
        <Card
          sx={{
            borderRadius: 2,
            position: isMediumScreen ? "none" : "absolute",
            right: { xs: "none", md: "50%", lg: "35%" },
            //  isSmallScreen
            //   ? "none"
            //   : isMediumScreen
            //     ? "50%"
            //     : isLargeScreen
            //       ? "30%"
            //       : isXLargeScreen
            //         ? "30%"
            //         : isDoubleXLargeScreen
            //           ? "30%"
            //           : "50%",
            top: isMediumScreen ? "none" : "50%",
            transform: isMediumScreen ? "none" : "translate(160%, -50%)",
            marginTop: isMediumScreen ? "10%" : "0",
            maxHeight: isSmallScreen
              ? "75vh"
              : isMediumScreen
                ? "75vh"
                : isLargeScreen
                  ? "80vh"
                  : "65vh",
          }}
        >
          <Stack>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "500",
                minWidth: "300px",
                backgroundColor: "#00999E",
                color: "white",
                p: isSmallScreen ? 0.8 : 1.5,
              }}
            >
              Unlock Your Dreams
            </Typography>
          </Stack>

          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: "20px",
              display: "grid",
              alignContent: "space-between",
            }}
          >
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              color="#eeeeee"
              value={formik.values.name}
              size={isSmallScreen ? "small" : "medium"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required
              marginBottom="10px"
            />
            <br />
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              type="text"
              variant="outlined"
              color="#eeeeee"
              value={formik.values.phone}
              size={isSmallScreen ? "small" : "medium"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              required
            />
            <br />
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              color="#eeeeee"
              type="email"
              value={formik.values.email}
              size={isSmallScreen ? "small" : "medium"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
            <br />

            <Box
              component="span"
              style={{
                color: "white",
                backgroundColor: "#00999e",
                marginBottom: "5px",
                padding: isSmallScreen ? "7px" : "10px",
              }}
              clone
            >
              <Button type="submit" style={{ fontSize: "16px" }}>
                Schedule A Meeting
              </Button>
            </Box>
          </form>
        </Card>
        <ToastContainer />
      </Container>
    </Box>
  );
}

export default StartJourney;
