import { Icon } from '@iconify/react'
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import FormComponent from '../form-component/Form'
import ButtonComponent from '../content-component/ButtonComponent'

const Cta = ({ title }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Box>
            <ButtonComponent
                fontSize="18px"
                width="auto"
                padding="10px 30px"
                text={title || "Register Now"}
                onClick={handleOpenDialog}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    Register Now
                    <IconButton aria-label="close" onClick={handleCloseDialog} sx={{ position: 'absolute', right: 8, top: 8, color: 'inherit' }}>
                        <Icon icon="bitcoin-icons:cross-outline" />
                    </IconButton>
                </DialogTitle>
                <FormComponent handleCloseDialog=
                    {handleCloseDialog} setOpenDialog={setOpenDialog} />
            </Dialog>

        </Box>
    )
}

export default Cta
