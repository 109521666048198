

const USAIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September Intake/ Fall Intake",
                "January  Intake/ Spring Intake",
                "May Intake/ Summer Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - May", "May - August"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
                "October - November"
            ]
        }
    ];
const UKIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September Intake/ Fall Intake",
                "January Intake/ Winter Intake",
                "May Intake/ Spring Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - April", "May - August"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
                "October - November"
            ]
        }
    ];
const IrelandIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September / Fall Intake",
                "January / Spring Intake",
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September", "January"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "June/July",
                "September/October",
            ]
        }
    ];
const CanadaIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September / Fall Intake",
                "January / Winter Intake",
                "May / Spring Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - April", "May - August"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
                "October - November"
            ]
        }
    ];
const GermanyIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [

                " Summer Intake",
                "Winter Intake",
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["March/April", "September - October"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "April - September",
                "October - March"
            ]
        }
    ];
const AustraliaIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: '#F59E0B',
            listitem: [
                "February Intake",
                "July Intake",
                "November Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["October - November (Previous year)", "April - May", "September"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "February or Early March to Late May or Early June",
                "Late July or Early August to November",
                "Late November to January"
            ]
        }
    ];
const SingaporeIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "Fall Intake",
                "Winter Intake",
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["August", "February"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
            ]
        }
    ];
const ItalyIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September / Fall Intake",
                "February / Winter Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - April"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "Varies",
                "Varies"
            ]
        }
    ];
const FranceIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September Intake",
                "January Intake",
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - April"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
            ]
        }
    ];
const DubaiIntake =
    [
        {
            id: 1,
            title: "Intake",
            topColor: "#F59E0B",
            listitem: [
                "September / Fall Intake",
                "January / Spring Intake",
                "May / Summer Intake"
            ]
        },
        {
            id: 2,
            title: "Duration",
            topColor: "#10B981",
            listitem: ["September - December", "January - April", "May - August"]
        },
        {
            id: 3,
            title: "Applications Open",
            topColor: "#9B1DFF",
            listitem: [
                "December - July",
                "September - November",
                "October - November"
            ]
        }
    ];




export { USAIntake, UKIntake, IrelandIntake, CanadaIntake, GermanyIntake, AustraliaIntake, SingaporeIntake, ItalyIntake, FranceIntake, DubaiIntake };