import React from "react";
// import pic from "../asset/Left.png";
import pic from "../asset/homeedge.jpg";
import MuiCard from "./Card";
import { useState, useEffect } from "react";
import { Box, Container, Grid, Stack, Typography, createTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./content-component/Header";

const ReadMoreAboutUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check for small screens
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // const isLgScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const aboutuscard = [
    {
      id: 1,
      heading: "Platinum Consultancy",
      subheading:
        "Exclusive, premium services with top-tier expertise, encompassing comprehensive guidance and elite academic transition assistance.",
    },
    {
      id: 2,
      heading: "Technology Integration",
      subheading:
        "Tailored web-based application for seamless tracking of end-to-end application processes, class scheduling, and real-time updates.",
    },
    {
      id: 3,
      heading: "Pinnacle Squad",
      subheading:
        "Our team's deep expertise in global education guides students in logical course and university selection, fostering informed decisions.",
    },
    {
      id: 4,
      heading: "Extensive Partnerships",
      subheading:
        "Established connections with esteemed global universities, language schools, and educational institutions, showcasing extensive partnerships.",
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#Effdff", width: '100%' }}>


      <Container
     
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={pic} width="100%"
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} sx={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: ".5rem",
              }}
            >
              <Header
                color="#00999e"
                textAlign="center"
                content="Taksheela Edge"
              />
              
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                marginRight: "6px",
              }}
            >
              <Box
                rowGap={isMediumScreen ? 1 : 3}
                columnGap={isMediumScreen ? 1 : 2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              //  padding={isLargeScreen ? '50px' : 'none'}
              >
                {aboutuscard.map((item, index) => (
                  <MuiCard heading={item.heading} subHeading={item.subheading} id={index} />
                ))}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ReadMoreAboutUs;
