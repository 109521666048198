

const EngineeringSnapshot = {
    heading: "Engineering by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers",
            "title": "In 2022, over 13 lakh Indian students studied in 79 countries."
        },
        {
            "count": "Salary Trends",
            "title": "An  average salary of ₹40,241,223 per year ."
        },

        {
            "count": "Job Opportunities",
            "title": "Access to diverse job opportunities in software development, aerospace, civil, mechanical, and electrical engineering."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Scholarships range from partial to full tuition coverage."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "Engineering education abroad offers a high ROI "
        }
    ]


};
const CSITSnapshot = {
    heading: "Computer Science and Information Technology by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers",
            "title": "13 lakh Indian students enroll annually."
        },
        {
            "count": "Salary Trends",
            "title": "Median salaries: $80,000 - $150,000/year."
        },
        {
            "count": "Job Opportunities",
            "title": "90% secure jobs within six months."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Scholarships range from partial to full coverage."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "High ROI, investment recouped within a few years."
        }
    ]

};
const HsmSnapshot = {
    heading: "Health Sciences and Medicine by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers of Indian Students",
            "title": "10,000 Indian students enrolled in health sciences and medicine annually."
        },
        {
            "count": "Salary Trends",
            "title": "Starting salary: ₹20,00,000/year; experienced professionals: ₹40,00,000/year."
        },
        {
            "count": "Job Opportunities",
            "title": "Global opportunities in hospitals, research, pharma, and healthcare consulting."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Scholarships cover tuition, living expenses, and research"
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "High ROI with average recovery in 3-5 years post-graduation."
        }
    ]

};
const BusinessSnapshot = {
    heading: "Business and Management by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers",
            "title": "Approx 50,000 Indian students study Business/Management abroad annually."
        },
        {
            "count": "Salary Trends",
            "title": "Average starting salary: ₹20-40 lakhs per annum."
        },
        {
            "count": "Job Opportunities",
            "title": "Diverse roles in consulting, finance, marketing, operations, and entrepreneurship."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Scholarships range from ₹5-50 lakhs per annum for tuition, living, and travel costs."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "Favorable ROI within 3-5 years, with education costs of ₹50 lakhs to ₹1 crore."
        }
    ]

};
const SocialSnapshot = {
    heading: "Social science  by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers of Indian Students",
            "title": "Enrollment in social science programs abroad has been steadily increasing"
        },
        {
            "count": "Salary Trends",
            "title": "Starting salaries: ₹15,00,000 - ₹30,00,000 per annum."
        },
        {
            "count": "Job Opportunities",
            "title": "Diverse roles in research, consulting, policy, development, and non-profits with rising salaries."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Scholarships cover ₹5,00,000 - ₹15,00,000/year for tuition, living expenses, and research funding."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "High ROI due to competitive salaries"
        }
    ]

};
const PlsSnapshot = {
    heading: "Physical and Life Sciences by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers of Indian Students",
            "title": "15,000 (Bachelor's), 10,000 (Master's), 2,000 (Doctorate)"
        },
        {
            "count": "Salary Trends",
            "title": "₹8,00,000 (Entry Level), ₹15,00,000 (Mid-Career), ₹25,00,000+ (Experienced)"
        },
        {
            "count": "Job Opportunities",
            "title": "Research Scientist, Data Analyst, Biomedical Engineer, Environmental Consultant"
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Full tuition scholarships available for top-performing students."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "5:1 (Bachelor's), 7:1 (Master's), 10:1 (Doctorate)"
        }
    ]

};
const FaaSnapshot = {
    heading: "Fine and Applied Art Abroad by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers",
            "title": "10,000 Indian students enrolled abroad annually"
        },
        {
            "count": "Salary Trends",
            "title": "Average starting salary of ₹8,00,000 per annum"
        },
        {
            "count": "Job Opportunities",
            "title": "Diverse opportunities in design studios, advertising agencies, fashion houses, etc."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Various scholarships available, covering up to 50% of tuition fees"
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "Average ROI within 3-5 years of graduation"
        }
    ]

};
const CJSnapshot = {
    heading: "Communication and Journalism  by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers",
            "title": "20,000 Indian students enrolled in 2021"
        },
        {
            "count": "Salary Trends",
            "title": "Average starting salary of ₹25,00,000 per annum"
        },
        {
            "count": "Job Opportunities",
            "title": "85% of graduates secure employment within 6 months"
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Various scholarships available, ranging from ₹1,00,000 to full tuition fees"
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "Average ROI of 300% over 5 years post-graduation"
        }
    ]

};
const DesigningSnapshot = {
    heading: "Designing by the Numbers: A Snapshot of Trends",
    item: [
        {
            "count": "Enrollment Numbers of Indian students",
            "title": "50,000 (approx) students enrolled annually in design programs abroad."
        },
        {
            "count": "Salary Trends",
            "title": "Median starting salary for design graduates: ₹6,00,000 per annum."
        },
        {
            "count": "Job Opportunities",
            "title": "80% of design graduates secure employment within 6 months of graduation."
        },
        {
            "count": "Scholarship Opportunities",
            "title": "Various scholarships available, ranging from ₹1,00,000 to ₹10,00,000 per year."
        },
        {
            "count": "Return on Investment (ROI)",
            "title": "Average ROI within 5 years of graduation: ₹30,00,000 - ₹50,00,000."
        }
    ]

};

export {
    EngineeringSnapshot,
    CSITSnapshot,
    HsmSnapshot,
    SocialSnapshot,
    BusinessSnapshot,
    PlsSnapshot,
    FaaSnapshot,
    CJSnapshot,
    DesigningSnapshot
};
