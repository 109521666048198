import { Box, Card, CardActions, CardMedia, Typography } from '@mui/material';
import React, { useState } from 'react';
import ButtonComponent from '../content-component/ButtonComponent';
import { Icon } from '@iconify/react';

const NewsCard = ({ data, imageheight, imagewidth }) => {
  const [expanded, setExpanded] = useState(false);

  const handleReadMoreClick = () => {
    if (data?.url) {
      window.open(data.url, '_blank');
    } else {
      setExpanded(true);
    }
  };

  const handleShowLessClick = () => {
    setExpanded(false);
  };

  return (
    <Card
      sx={{
        width: { xs: "320px", md: "360px" },
        padding: "0 !important",
        height: expanded ? "auto" : "400px",

        marginBottom: "25px",
      }}
    >
      <Box >
        <CardMedia
          component="img"
          width={imagewidth ?? "100%"}
          height={imageheight ?? "200px"}
          image={data.image}
          alt="news image"
        />
        <Box
          sx={{
            padding: "15px",
            marginTop: "7px",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "#00999E",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            {data.date}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {data.title}
          </Typography>
          {!expanded && (
            <ButtonComponent
              fontSize="14px"
              padding="10px 20px"
              onClick={handleReadMoreClick}
              icon={<Icon icon="fluent:arrow-right-28-regular" />}
              text="Read More"
            />
          )}
          {expanded && !data.url && (
            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                margin: "5px 0",
                textAlign: "justify",
              }}
            >
              {data.content}
            </Typography>
          )}
        </Box>
      </Box>

      <CardActions>
        <Box sx={{ padding: "10px" }}>
          {expanded && !data.url && (
            <ButtonComponent
              fontSize="14px"
              padding="0px 20px"
              onClick={handleShowLessClick}
              icon={<Icon icon="fluent:arrow-right-28-regular" />}
              text="Show Less"
            />
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default NewsCard;
