import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import "../../index.css";
import "./styles.css";
import CountUp from "react-countup";

const MilestonesCard = ({ id, icon, title, count, parameter }) => {
  const [countData, setCountData] = useState(0);
  useEffect(() => {
    const incrementAmount = count * 0.1;

    const interval = setInterval(() => {
      setCountData((prevCount) =>
        prevCount < count ? Math.floor(prevCount + incrementAmount) : count
      );
    }, 50);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div >
      <Box
        sx={{
          width: { xs: "100%", sm: 320, md: 230, lg: 250 },
          "&:hover": {
            backgroundColor: "#5cd2d6",
            "& .MuiIconButton-root, & .MuiTypography-root": {},
          },
          margin: "auto",
        }}
      >
        <CardActionArea>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <img
              src={icon}
              alt="heroImg1"
              loading="lazy"
              style={{
                width: "100%",
                height: "200px",
                transformOrigin: "0 0",
                borderRadius: 8,
              }}
            />
          </Box>

          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                color: "#000",
                fontSize: 40,
                fontWeight: "700",
                lineHeight: "30px",
                wordWrap: "break-word",
              }}
            >
              <CountUp start={0} end={countData} duration={5} />
              {parameter}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: "#000",
                fontSize: 18,
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "24px",
                wordWrap: "break-word",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Box>
    </div>
  );
};

export default MilestonesCard;
